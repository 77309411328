<template>
    <x-complex-input
        ref="input"
        :type="preparedInputType"
        :params="preparedParams"
        :value="value"
        :meta="meta"
        @input="inputHandler"
    />
</template>

<script>
import { XComplexInput } from '@/components/common/inputs/XComplexInput';

const TYPE_STRING = 'string';
const TYPE_INTEGER = 'integer';
const TYPE_DECIMAL = 'decimal';
const TYPE_NUMERIC = 'numeric';
const TYPE_DATE = 'date';
const TYPE_DATETIME = 'datetime';
// const TYPE_DATE_RANGE = 'date_range';
const TYPE_BOOLEAN = 'boolean';
const TYPE_SELECT = 'select';
const TYPE_SELECT_MULTIPLE = 'select_multiple';
const TYPE_RELATION = 'relation';
const TYPE_RELATION_MULTIPLE = 'relation_multiple';
const TYPE_PASSWORD = 'password';
const TYPE_FILE = 'file';
const TYPE_ADDON = 'add-on';
const TYPE_IP = 'ip';
const TYPE_IPV6 = 'ipv6';
// const TYPE_TEXTAREA = 'textarea';

// const INPUT_DATE_RANGE_PICKER = 'date_range';
// const INPUT_DATETIME_PICKER = 'datetime';
const INPUT_DATE_PICKER = 'date';
const INPUT_SELECT = 'select';
const INPUT_SELECT_RELATION = 'selectRelation';
const INPUT_MULTIPLE_SELECT = 'multipleSelect';
const INPUT_MULTIPLE_SELECT_RELATION = 'multipleSelectRelation';
// const INPUT_TEXT_AREA = 'textarea';
const INPUT_CHECKBOX = 'checkbox'; // Not realized
const INPUT_TEXT = 'text';
const INPUT_NUMBER = 'number';
// const INPUT_HIDDEN = 'hidden';
const INPUT_PASSWORD = 'password';
const INPUT_FILE = 'file';

const ADDON_BUTTON = 'addon_button';
const ADDON_TEXT = 'addon_text';
const ADDON_TEXT_AND_BUTTON = 'addon_text_and_button';

const ADDON_INPUT_TYPE_BUTTON = 'button';
const ADDON_INPUT_TYPE_TEXT = 'text';
const ADDON_INPUT_TYPE_TEXT_PLUS_BUTTON = 'text_plus_button';

export default {
    name: 'XAdditionalFieldInput',
    components: {
        XComplexInput,
    },
    props: {
        type: String,

        // This input should be rendered in a filter form?
        search: {
            type: Boolean,
            default: false,
        },
        params: Object,
        meta: {
            type: Object,
            default() {
                return {};
            },
        },
        value: {
            type: [String, Boolean, Array, Number, File, FileList, Object],
            default: '',
        },
    },
    data() {
        return {
            inputType: this.type,
        };
    },
    computed: {
        preparedParams() {
            let params = { ...this.params };

            if (params.readonly || params.disabled) {
                params.options.disabled = true;
            }

            switch (params.type) {
                case TYPE_DATETIME:
                    params.clearButton = true;
                    params.activeClearButton = !params.disabled;
                    break;
                case TYPE_BOOLEAN:
                    if (this.search) {
                        params.default = '';
                        params.options.options = {
                            '': t('common', 'All'),
                            no: t('common', 'No'),
                            yes: t('common', 'Yes'),
                        };
                        params.options['data-ignore-empty'] = '1';
                    }
                    break;
                case TYPE_INTEGER:
                    params.options['data-ignore-empty'] = '1';
                    break;
                case TYPE_IP:
                    if (this.search) {
                        params.options['data-ignore-empty'] = '1';
                        params.options.placeholder = t('networking', 'Comma separated IPs ');
                    }
                    break;
                case TYPE_SELECT:
                    this.prepareSelectParams(params);
                    break;
                case TYPE_RELATION:
                    this.prepareRelationParams(params, false);
                    break;
                case TYPE_RELATION_MULTIPLE:
                    this.prepareRelationParams(params, true);
                    break;
                case TYPE_IPV6:
                    if (this.search) {
                        params.options['data-ignore-empty'] = '1';
                        params.options.placeholder = t('networking', 'Comma separated IPs ');
                    }
                    break;
                case TYPE_DECIMAL:
                    params.default = '';
                    params.options['data-ignore-empty'] = '1';
                    params.options.class = 'decimal';
                    params.options.step = '0.000000001';
                    break;
                case TYPE_NUMERIC:
                    params.default = '';
                    params.options['data-ignore-empty'] = '1';
                    break;
                default:
                    break;
            }

            return params;
        },
        preparedInputType() {
            return this.inputType;
        },
    },
    created() {
        let typeChanges = {};

        typeChanges[TYPE_RELATION] = INPUT_SELECT_RELATION;
        typeChanges[TYPE_STRING] = INPUT_TEXT;
        typeChanges[TYPE_DATE] = INPUT_DATE_PICKER;
        typeChanges[TYPE_INTEGER] = INPUT_NUMBER;

        let params = { ...this.params };
        if (params.type === TYPE_RELATION_MULTIPLE) {
            if ((isset(params, 'ajax') && params.ajax)
                || params.options.options.length > 100
            ) {
                typeChanges[TYPE_RELATION_MULTIPLE] = INPUT_SELECT_RELATION;
            } else {
                typeChanges[TYPE_RELATION_MULTIPLE] = INPUT_MULTIPLE_SELECT_RELATION;
            }
        } else {
            typeChanges[TYPE_RELATION_MULTIPLE] = INPUT_MULTIPLE_SELECT_RELATION;
        }

        typeChanges[TYPE_SELECT_MULTIPLE] = INPUT_MULTIPLE_SELECT;
        typeChanges[TYPE_DECIMAL] = INPUT_NUMBER;
        typeChanges[TYPE_NUMERIC] = INPUT_TEXT;
        typeChanges[TYPE_BOOLEAN] = INPUT_CHECKBOX;
        typeChanges[TYPE_PASSWORD] = INPUT_PASSWORD;
        typeChanges[TYPE_FILE] = INPUT_FILE;

        if (this.type === 'add-on') {
            switch (this.params.addon_input_type) {
                case ADDON_INPUT_TYPE_BUTTON:
                    typeChanges['add-on'] = ADDON_BUTTON;
                    break;
                case ADDON_INPUT_TYPE_TEXT:
                    typeChanges['add-on'] = ADDON_TEXT;
                    break;
                case ADDON_INPUT_TYPE_TEXT_PLUS_BUTTON:
                    typeChanges['add-on'] = ADDON_TEXT_AND_BUTTON;
                    break;
                default:
                    typeChanges['add-on'] = ADDON_TEXT_AND_BUTTON;
                    break;
            }
        }

        // If search - we need change types
        if (this.search) {
            typeChanges[TYPE_BOOLEAN] = INPUT_SELECT;
            typeChanges[TYPE_RELATION_MULTIPLE] = INPUT_MULTIPLE_SELECT_RELATION;
            typeChanges[TYPE_FILE] = INPUT_TEXT;
            typeChanges[TYPE_IP] = INPUT_TEXT;
            typeChanges[TYPE_IPV6] = INPUT_TEXT;
        }

        if (isset(typeChanges, this.type)) {
            this.inputType = typeChanges[this.type];
        } else {
            this.inputType = this.type;
        }
    },
    methods: {
        isset(obj, path) {
            return isset(obj, path);
        },
        inputHandler(value) {
            this.$emit('input', value);
        },
        afIsRequired() {
            if (!this.search || !this.filter.is_required) {
                return false;
            }

            if (this.filter.type === TYPE_ADDON) {
                return this.filter.addon_input_type !== ADDON_INPUT_TYPE_BUTTON;
            }

            return this.filter.type !== TYPE_BOOLEAN;
        },
        prepareRelationParams(params, multiple) {
            params.options['data-ignore-empty'] = '1';

            if (!empty(params.value) && !params.disabled) {
                params.additionalBlock = true;
            }
            // I pass "params.value" in another variable,
            // because inside the "XSelect" component this value will be changed to an object for layout and the data will be distorted
            params.valueForAdditionalBlock = params.value;

            if (multiple) {
                params.options.multiple = 'multiple';
            }

            if ((isset(params, 'ajax') && params.ajax)
                || params.options.options.length > 100
                || this.search
            ) {
                params.isAsync = true;
                params.tags = true;
                params.fixResizeMultiple = true;

                // For ajax value should be in format {id: 1, text: 'name'}
                // But server return value: 1
                // So, fix value to true format
                if (!empty(params.value) && typeof params.value !== 'object' && isset(params, ['options', 'options'])) {
                    this.$emit('input', { id: params.value, text: params.options.options[params.value] });
                } else if (Array.isArray(params.value) && isset(params, ['options', 'options'])) {
                    let value = [];
                    params.value.forEach((id) => {
                        value.push({
                            id,
                            text: params.options.options[id],
                        });
                    });

                    this.$emit('input', value);
                }

                let shortName;

                if (this.search) {
                    let regexResult = params.name.match(/^additional_attributes\[(.+)\]$/);
                    shortName = regexResult === null ? params.name : regexResult[1];
                } else {
                    shortName = params.name;
                }

                let { module } = params;
                if (!empty(params.category) && params.category == 'lead') {
                    module = 'leads';
                }

                params.config = {
                    ajax: {
                        url: '/admin/relations--get-options',
                        delay: 250,
                        data(data) {
                            return {
                                module,
                                attribute: shortName,
                                query: data.term,
                            };
                        },
                        processResults(data) {
                            return {
                                results: data.data,
                            };
                        },
                        cache: true,
                    },
                    minimumInputLength: 1,
                    placeholder: this.t('common', 'Choose option:'),
                };
            } else if (!multiple && !params.is_required) {
                params.placeholder = this.t('common', 'Choose option:');
                params.allowClear = true;
            }
        },
        prepareSelectParams(params) {
            if (!params.is_required) {
                params.placeholder = this.t('common', 'Choose option:');
                params.allowClear = true;
            }
        },
    },
};
</script>
