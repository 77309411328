import { isset } from '@/utils/functions';

function mergeBreadcrumbs(routes, breadcrumbs = null) {
    for (let route of routes) {
        if (breadcrumbs !== null) {
            // If route has breadcrumbs we add parent for it
            if (isset(route, ['meta', 'breadcrumbs']) && route.meta.breadcrumbs.length > 0) {
                for (let i = breadcrumbs.length - 1; i >= 0; i--) {
                    route.meta.breadcrumbs.unshift(breadcrumbs[i]);
                }
            } else {
                // If rout has not breadcrumbs we should set parent for it
                if (!isset(route, ['meta', 'breadcrumbs'])) {
                    if (!isset(route, ['meta'])) {
                        route.meta = {};
                    }
                    route.meta.breadcrumbs = [];
                }
                route.meta.breadcrumbs = breadcrumbs;
            }
        }

        if (route.children && route.children.length > 0) {
            if (isset(route, ['meta', 'breadcrumbs'])) {
                mergeBreadcrumbs(route.children, route.meta.breadcrumbs);
            } else {
                mergeBreadcrumbs(route.children, null);
            }
        }
    }

    return routes;
}

export default mergeBreadcrumbs;
export { mergeBreadcrumbs };
