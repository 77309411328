window.showModal = function (name, data) {
    splynx_event_bus.emit(`show-modal-${name}`, data);
};

window.onShowModal = function (name, callback) {
    splynx_event_bus.off(`show-modal-${name}`);
    splynx_event_bus.on(`show-modal-${name}`, callback);
};

window.closeModal = function (name) {
    splynx_event_bus.emit('modal-dismiss', name);
};

window.onCloseModal = function (callback) {
    splynx_event_bus.reinitEvent('modal-dismiss', callback);
};
