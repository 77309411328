import Bus from './bus';
import XModalWrapper from './XModalWrapper';

const XModalWrapperPlugin = {};

XModalWrapperPlugin.install = (Vue) => {
    Vue.prototype.$modals = new Vue({
        name: 'XModalWrapper',

        created() {
            this.$on('new', (options) => {
                this.open(options);
            });

            this.$on('close', (data) => {
                this.close(data.name, data.data);
            });

            this.$on('dismiss', (index) => {
                this.dismiss(index || null);
            });

            this.$on('submit', (index, data) => {
                this.submit(index || null, data);
            });

            this.$on('remove', (name) => {
                this.remove(name);
            });
        },

        methods: {
            open(options = null) {
                Bus.emit('new', options);
            },

            close(name, data = null) {
                Bus.emit('close', { name, data });
            },

            dismiss(name) {
                Bus.emit('dismiss', name);
            },

            submit(name, data) {
                Bus.emit('submit', { name, data });
            },

            remove(name) {
                Bus.emit('remove', name);
            },
        },
    });

    Vue.mixin({
        created() {
            this.$on('modals:new', (options) => {
                Bus.emit('new', options);
            });

            this.$on('modals:close', (data) => {
                Bus.emit('close', data);
            });

            this.$on('modals:dismiss', (name) => {
                Bus.emit('dismiss', name);
            });

            this.$on('modals:submit', (name, data) => {
                Bus.emit('submit', { name, data });
            });

            this.$on('modals:remove', (name) => {
                Bus.emit('remove', name);
            });
        },
    });
};

if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(XModalWrapperPlugin);
}

export default XModalWrapperPlugin;

export {
    Bus,
    XModalWrapper,
    XModalWrapperPlugin,
};
