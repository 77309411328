import { SHOW_GDPR_NOTIFICATION, SHOW_GDPR_NOTIFICATION_PROFILE, GDPR_MESSAGE } from './mutation-types';

export default {
    [SHOW_GDPR_NOTIFICATION](state, boolean) {
        state.showNotification = boolean;
    },
    [SHOW_GDPR_NOTIFICATION_PROFILE](state, boolean) {
        state.showNotificationProfile = boolean;
    },
    [GDPR_MESSAGE](state, boolean) {
        state.gdprMessage = boolean;
    },
};
