/**
 * Init show modals handlers for showModal() calls
 * @param self
 */
function init(self) {
    onCloseModal((name) => {
        self.$modals.dismiss(name);
    });

    onShowModal('delete-logo', (params) => {
        self.$modals.open({
            name: 'delete-logo',
            props: {
                confirmMessage: params.confirmMessage,
                title: params.title,
            },
            component: () => import('@/apps/admin/views/admin/config/system/ModalConfirmDeleteLogo.vue'),
        });
    });
}

export default init;
