import { empty } from '@/utils/functions';

export default {
    props: {
        actionsAsObject: {
            type: Boolean,
            default: false,
        },
        customCellTypes: {
            type: Object,
            default() {
                return {
                    link: {
                        properties: ['text', 'href', 'action', 'target', 'bold'],
                        template(data) {
                            return `<a href="${data.href}" style="${data.bold ? 'font-weight: bold;' : ''}" onclick="${data.action}" target="${data.target}">
                                ${data.text}
                                ${data.in}
                            </a>`;
                        },
                    },
                    badge: {
                        properties: ['label', 'title', 'badge', 'inline'],
                        template(data) {
                            return `<span class="badge bg-${data.label || data.badge || 'dark'} ${data.inline ? 'ms-8' : ''}">${data.title}${data.in}</span>`;
                        },
                    },
                    coloredText: {
                        properties: ['color', 'title'],
                        template(data) {
                            return `<span class="color-${data.color}">${data.title}${data.in}</span>`;
                        },
                    },
                    textWithBadge: {
                        properties: ['color', 'title', 'text', 'bold', 'hideBadge', 'inline'],
                        template(data) {
                            let result = `${data.text}${data.in}`;
                            if (!data.hideBadge) {
                                let mtClass;
                                if (!data.inline) {
                                    result += '<br/>';
                                    mtClass = 'mt-4';
                                } else {
                                    mtClass = 'ms-8';
                                }
                                result += `<span class="${mtClass} badge bg-${data.color}">${data.title}</span>`;
                            }
                            if (data.bold) {
                                result = `<strong>${result}</strong>`;
                            }
                            return result.trim();
                        },
                    },
                    textWithBadgesList: {
                        properties: ['title'],
                        template(data) {
                            return `<span class="me-8">${data.title}</span>${data.in}`;
                        },
                    },
                    badgesList: {
                        properties: ['badges'],
                        template(data) {
                            let result = '';
                            for (let badge of data.badges) {
                                result += `<span class="me-8 badge bg-${badge.badge}">${badge.title}</span>`;
                            }
                            return result.trim();
                        },
                    },
                    table: {
                        properties: ['table'],
                        template: (data) => {
                            const cols = this.colsForInsideTable;
                            const { rows } = data;

                            const table = document.createElement('table');
                            const thead = document.createElement('thead');
                            const trHead = document.createElement('tr');

                            Object.keys(cols).forEach((key) => {
                                const th = document.createElement('th');
                                th.textContent = cols[key];
                                trHead.appendChild(th);
                            });
                            thead.appendChild(trHead);

                            const tbody = document.createElement('tbody');
                            if (rows !== null) {
                                rows.forEach((row) => {
                                    const trBody = document.createElement('tr');
                                    Object.keys(cols).forEach((colKey) => {
                                        const td = document.createElement('td');
                                        let text = '';

                                        if (colKey === 'current') {
                                            const icon = document.createElement('i');
                                            icon.className = row[colKey] === '1'
                                                ? 'icon-ic_fluent_checkmark_24_regular'
                                                : 'icon-ic_fluent_subtract_24_regular';
                                            td.appendChild(icon);
                                        } else {
                                            text = row[colKey];
                                            td.textContent = text;
                                        }

                                        trBody.appendChild(td);
                                    });
                                    tbody.appendChild(trBody);
                                });
                            }

                            table.appendChild(thead);
                            table.appendChild(tbody);

                            table.className = 'table tables-display insideTable';

                            return table.outerHTML;
                        },
                    },
                };
            },
        },
    },
    methods: {
        getLinkActions(action) {
            let onClickHandler = `(${window.linkHandler(action.action)})(event);`;
            let href = '';
            if (onClickHandler === '(function() {})(event);') {
                href = action.action;
            } else if (action.href) {
                href = action.href;
            }

            return `<a onclick="${onClickHandler}" target="${action.target}" href="${href}" title="${action.title}">
                        <i class="${action.icon}"></i>
                    </a>`;
        },
        getDropdownActions(action) {
            let dropdownItems = '';
            action.action.forEach((item) => {
                dropdownItems += `<li class="dropdown-item"><a onclick="(${window.linkHandler(item.action, true)})();">${item.title}</a></li>`;
            });
            if (empty(action.action)) {
                return '';
            }
            return `<a class="dropdown-toggle" data-bs-toggle="dropdown" title="${action.title}">
                        <i class="${action.icon}"></i>
                    </a><div class="dropdown-menu">${dropdownItems}</div>`;
        },
        getActionsTemplate(actions) {
            let result = '';
            actions?.forEach((action) => {
                switch (action.type) {
                    case 'link':
                        result += this.getLinkActions(action);
                        break;
                    case 'dropdown':
                        result += this.getDropdownActions(action);
                        break;
                    default:
                        result += this.getLinkActions(action);
                }
            });
            return `<div class="action-cell row-gap-16 column-gap-12">${result}</div>`;
        },
        baseCellTemplate(value) {
            if (value.in) {
                value.in = this.getCellFromObject([{
                    in: value.in,
                }]);
                value.in = value.in[0].in;
            }
            this.customCellTypes[value.type].properties.forEach((property) => {
                if (value[property] === null || value[property] === undefined) {
                    value[property] = '';
                }
                if (value.in === undefined || value.in === null) {
                    value.in = '';
                }
            });
            return value;
        },
        getCellFromObject(data) {
            data.forEach((row, rowIndex) => {
                for (const [key, value] of Object.entries(row)) {
                    if (typeof value === 'object') {
                        if (key === 'actions') {
                            data[rowIndex].actions_object = value;
                            data[rowIndex].actions = this.getActionsTemplate(value);

                            continue;
                        }
                        if (!value?.type || !Object.keys(this.customCellTypes).includes(value.type)) {
                            data[rowIndex][key] = '';
                            continue;
                        }
                        data[rowIndex][key] = this.customCellTypes[value.type].template(this.baseCellTemplate(value));
                    }
                }
            });
            return data;
        },
    },
};
