import { empty } from '@/utils/functions';
import {
    SET_MENU,
    SET_CUSTOMER,
    SET_LOGO,
    SET_PAGE_TITLE, REFRESH_ROUTE,
    SET_FOOTER, SET_ON_BOARDING_STEPS,
    SET_APP_CHILD_REFERENCES, SET_COMPANY_INFO,
    SET_SHOW_PROFILE_LINK,
    SET_TICKETS_ENABLED, SET_SHOW_ERROR_PAGE,
} from './mutation-types';

export default {
    [SET_MENU](state, menuStructure) {
        state.menu = menuStructure;
    },
    [SET_CUSTOMER](state, customer) {
        state.customer = customer;
    },
    [SET_LOGO](state, admin) {
        state.logo = admin;
    },
    [SET_FOOTER](state, footer) {
        state.footer = footer;
    },
    [SET_COMPANY_INFO](state, companyInfo) {
        state.companyInfo = companyInfo;
    },
    [SET_PAGE_TITLE](state, newTitle) {
        state.pageTitle = newTitle;

        if (!empty(newTitle)) {
            $.address.title(`${window.portal_config_portal_title}: ${newTitle.replace(/&amp;/g, '&')}`);
            $('.page-name').empty().text(newTitle.replace(/&amp;/g, '&'));
        }
    },
    /**
     * Call this mutation for refresh current route view (not all page, only route view).
     * For example call this after "show/hide column" modal.
     * refreshRouteKey used as :key in <router-view>, so when key is changed route will be refreshed.
     * @param state
     */
    [REFRESH_ROUTE](state) {
        state.refreshRouteKey = (new Date()).getTime();
    },
    [SET_ON_BOARDING_STEPS](state, onBoardingSteps) {
        state.onBoardingSteps = onBoardingSteps;
    },
    [SET_APP_CHILD_REFERENCES](state, referencesApp) {
        if (typeof state.referencesApp === 'undefined') {
            state.referencesApp = referencesApp;
        } else {
            state.referencesApp = Object.assign(state.referencesApp, referencesApp);
        }
    },
    [SET_SHOW_PROFILE_LINK](state, showProfileLink) {
        state.showProfileLink = showProfileLink;
    },
    [SET_TICKETS_ENABLED](state, ticketsEnabled) {
        state.ticketsEnabled = ticketsEnabled;
    },
    [SET_SHOW_ERROR_PAGE](state, data) {
        state.showPageError = data;
    },
};
