window.templateForDataRangePicker = `<div class="daterangepicker">
                                    <div class="drp-calendar left">
                                        <div class="daterangepicker_input">
                                            <input class="input-mini form-control daterangepicker_start" type="text" name="daterangepicker_start"
                                                   value=""/>
                                            <i class="fa fa-calendar"></i>
                                        </div>
                                        <div class="calendar-table"></div>
                                    </div>
                                    <div class="drp-calendar right">
                                        <div class="daterangepicker_input">
                                            <input class="input-mini form-control daterangepicker_end" type="text" name="daterangepicker_end"
                                                   value=""/>
                                            <i class="fa fa-calendar"></i>
                                        </div>
                                        <div class="calendar-table"></div>
                                    </div>
                                    <div class="ranges">
                                        <div class="range_inputs drp-buttons">
                                            <button class="cancelBtn btn btn-cancel" type="button"></button>
                                            <button class="applyBtn btn btn-outline-primary" disabled="disabled" type="button"></button>
                                        </div>
                                    </div>
                                </div>`;
window.templateForSingleDataRangePicker = `<div class="daterangepicker">
                                            <div class="drp-calendar left">
                                                <div class="calendar-table"></div>
                                            </div>
                                            <div class="drp-calendar right">
                                                <div class="calendar-table"></div>
                                            </div>
                                            <div class="ranges">
                                                <div class="range_inputs drp-buttons">
                                                    <button class="cancelBtn btn btn-cancel" type="button"></button>
                                                </div>
                                            </div>
                                        </div>`;

window.templateForSingleDateTimeDataRangePicker = `<div class="daterangepicker">
                                                    <div class="drp-calendar left">
                                                        <div class="calendar-time"><div></div></div>
                                                        <div class="calendar-table"></div>
                                                    </div>
                                                    <div class="drp-calendar right">
                                                        <div class="calendar-time"><div></div></div>
                                                        <div class="calendar-table"></div>
                                                    </div>
                                                    <div class="ranges">
                                                        <div class="range_inputs drp-buttons">
                                                            <button class="cancelBtn btn btn-cancel" type="button"></button>
                                                            <button class="applyBtn btn btn-outline-primary" disabled="disabled" type="button"></button>
                                                        </div>
                                                    </div>
                                                </div>`;

function initDaterangePicker() {
    let elForInitDateRangePicker = $('.input-date-range:not(.dataRangePickerInitialized)');
    let initedDateRangePicker = $('.daterangepicker');
    let ifDataRangeExist = initedDateRangePicker.length > 0;

    let handler = () => {
        elForInitDateRangePicker.each(function () {
            if ($(this).is(':focus') && window.matchMedia('(min-width: 768px)').matches) {
                $(this).data('daterangepicker').hide();
            }
        });
    };
    $(window).on('scroll', handler);
    $('.splynx-dialog-content').on('scroll', handler);
    $('.vue-dialog-body').on('scroll', handler);

    if (ifDataRangeExist) {
        $.each(elForInitDateRangePicker, function () {
            let el = $(this);
            el.addClass('dataRangePickerInitialized');
            let elData = el.data('daterangepicker');
            if (elData) {
                let jsDateFormat = elData.locale.format;
                let constructor = elData.constructor.prototype;
                let btnCancel = $('.cancelBtn');
                let btnApply = $('.applyBtn');
                btnApply.removeClass('btn-sm btn-primary');
                btnCancel.removeClass('btn-sm btn-default');

                if (!elData.singleDatePicker && !elData.timePicker) {
                    el.on('show.daterangepicker', (ev, picker) => {
                        let elDataContainer = elData.container;
                        let dateRangePickerStart = elDataContainer.find('.daterangepicker_start');
                        let dateRangePickerEnd = elDataContainer.find('.daterangepicker_end');
                        dateRangePickerStart.val(picker.startDate.format(jsDateFormat));
                        dateRangePickerEnd.val(picker.endDate.format(jsDateFormat));

                        let oldStartDate = picker.startDate ? picker.startDate.format(jsDateFormat) : null;
                        let oldEndDate = picker.endDate ? picker.endDate.format(jsDateFormat) : null;

                        elDataContainer.on('mouseenter.daterangepicker', 'td.available', (e) => {
                            oldStartDate = picker.startDate ? picker.startDate.format(jsDateFormat) : null;
                            oldEndDate = picker.endDate ? picker.endDate.format(jsDateFormat) : null;

                            if (!$(e.target).hasClass('available')) {
                                return;
                            }

                            let title = $(e.target).attr('data-title');
                            let row = title.substr(1, 1);
                            let col = title.substr(3, 1);
                            let calendar = $(e.target).parents('.drp-calendar');
                            let date = calendar.hasClass('left') ? elData.leftCalendar.calendar[row][col] : elData.rightCalendar.calendar[row][col];

                            if (elData.endDate || date.isBefore(elData.startDate, 'day')) { // picking start
                                dateRangePickerStart.val(date.format(jsDateFormat));
                            } else if (!elData.endDate && date.isBefore(elData.startDate)) {
                                dateRangePickerEnd.val(date.format(jsDateFormat));
                            } else { // picking end
                                dateRangePickerEnd.val(date.format(jsDateFormat));
                            }
                        }).on('mouseout', 'td.available', () => {
                            if ($('.start-date').length > 0 && oldStartDate != null) {
                                dateRangePickerStart.val(oldStartDate);
                            }
                            if ($('.end-date').length > 0 && oldEndDate != null) {
                                dateRangePickerEnd.val(oldEndDate);
                            }
                        });

                        elDataContainer.on('mouseenter.daterangepicker', '.ranges li', (e) => {
                            oldStartDate = picker.startDate ? picker.startDate.format(jsDateFormat) : null;
                            oldEndDate = picker.endDate ? picker.endDate.format(jsDateFormat) : null;

                            let label = e.target.getAttribute('data-range-key');
                            elData.chosenLabel = label;
                            if (label === elData.locale.customRangeLabel) {
                                elData.showCalendars();
                            } else {
                                let dates = elData.ranges[label];
                                dateRangePickerStart.val(dates[0].format(jsDateFormat));
                                dateRangePickerEnd.val(dates[1].format(jsDateFormat));
                            }
                        }).on('mouseout', '.ranges li', () => {
                            if ($('.start-date').length > 0 && oldStartDate != null) {
                                dateRangePickerStart.val(oldStartDate);
                            }
                            if ($('.end-date').length > 0 && oldEndDate != null) {
                                dateRangePickerEnd.val(oldEndDate);
                            }
                        });

                        dateRangePickerStart.on('input', function () {
                            let startValue = $(this).val();
                            if (moment(startValue, jsDateFormat, true).isValid()) {
                                elData.setStartDate($(this).val());
                                elData.updateView();
                            }
                        });

                        dateRangePickerEnd.on('input', function () {
                            let endValue = $(this).val();
                            if (moment(endValue, jsDateFormat, true).isValid()) {
                                elData.setEndDate($(this).val());
                                elData.updateView();
                            }
                        });
                    });
                }

                el.on('show.daterangepicker hide.daterangepicker', () => {
                    elData.container.toggleClass('show-container-daterangepicker');
                });

                // needs for responsive DateRangePicker
                constructor.move = function () {
                    let parentOffset = { top: 0, left: 0 };
                    let containerTop;
                    let { drops } = this;

                    let parentRightEdge = $(window).width();
                    if (!this.parentEl.is('body')) {
                        parentOffset = {
                            top: this.parentEl.offset().top - this.parentEl.scrollTop(),
                            left: this.parentEl.offset().left - this.parentEl.scrollLeft(),
                        };
                        parentRightEdge = this.parentEl[0].clientWidth + this.parentEl.offset().left;
                    }

                    switch (drops) {
                        case 'auto':
                            containerTop = this.element.offset().top + this.element.outerHeight() - parentOffset.top;
                            if (containerTop + this.container.outerHeight() >= this.parentEl[0].scrollHeight) {
                                containerTop = this.element.offset().top - this.container.outerHeight() - parentOffset.top;
                                drops = 'up';
                            }
                            break;
                        case 'up':
                            containerTop = this.element.offset().top - this.container.outerHeight() - parentOffset.top;
                            break;
                        default:
                            containerTop = this.element.offset().top + this.element.outerHeight() - parentOffset.top;
                            if (containerTop + this.container.outerHeight() >= this.parentEl[0].scrollHeight - parentOffset.top) {
                                containerTop = this.element.offset().top - this.container.outerHeight() - parentOffset.top;
                                drops = 'up';
                            }

                            break;
                    }

                    // Force the container to it's actual width
                    this.container.css({
                        top: 0,
                        left: 0,
                        right: 'auto',
                    });

                    this.container.toggleClass('drop-up', drops == 'up');

                    let needingPosition = this.opens;
                    let currentWindowScreen = $(window).width();
                    let outerWidth = this.element.outerWidth();
                    let offsetLeft = this.element.offset().left;
                    let offsetCenterLeft = offsetLeft + Math.ceil(outerWidth / 2);
                    let offsetCenterRight = currentWindowScreen - offsetCenterLeft;
                    let widthForCalculate = this.container.find('.ranges').is(':visible') ? 680 : 500;
                    let halfWidth = Math.ceil(widthForCalculate / 2);

                    let availableOpeningToLeft = false;
                    let availableOpeningToRight = false;
                    let availableOpeningToCenter = false;

                    if (offsetCenterRight >= halfWidth && offsetCenterRight >= halfWidth) {
                        availableOpeningToCenter = true;
                        needingPosition = 'center';
                    }

                    if (offsetLeft + outerWidth >= widthForCalculate) {
                        availableOpeningToLeft = true;
                        needingPosition = 'left';
                    }

                    if ($(window).width() - offsetLeft >= widthForCalculate) {
                        availableOpeningToRight = true;
                        needingPosition = 'right';
                    }

                    if (
                        !(availableOpeningToCenter || availableOpeningToRight || availableOpeningToLeft)
                        || (offsetCenterLeft + offsetCenterRight) < widthForCalculate
                    ) {
                        this.container.addClass('daterangepicker-is-small');
                    } else {
                        this.container.removeClass('daterangepicker-is-small');
                    }

                    $(this.container).removeClass('openscenter opensright opensleft').addClass(`opens${needingPosition}`);

                    if (needingPosition === 'left') {
                        this.container.css({
                            top: containerTop,
                            right: parentRightEdge - this.element.offset().left - this.element.outerWidth(),
                            left: 'auto',
                        });
                        if (this.container.offset().left < 0) {
                            this.container.css({
                                right: 'auto',
                                left: 9,
                            });
                        }
                    } else if (needingPosition === 'center') {
                        this.container.css({
                            top: containerTop,
                            left: this.element.offset().left - parentOffset.left + this.element.outerWidth() / 2
                                - this.container.outerWidth() / 2,
                            right: 'auto',
                        });
                        if (this.container.offset().left < 0) {
                            this.container.css({
                                right: 'auto',
                                left: 9,
                            });
                        }
                        if (this.container.offset().left > 115) {
                            this.container.css({
                                right: 9,
                                left: 'auto',
                            });
                        }
                    } else {
                        this.container.css({
                            top: containerTop,
                            left: this.element.offset().left - parentOffset.left,
                            right: 'auto',
                        });
                        if (this.container.offset().left + this.container.outerWidth() > $(window).width()) {
                            this.container.css({
                                left: 'auto',
                                right: 0,
                            });
                        }
                    }
                };

                $('.daterangepicker_start, .daterangepicker_end').on('input', function () {
                    $(this).val($(this).val().replace(/[A-Za-zА-Яа-яЁё]/, ''));
                });
            }
        });
    }
}
window.initDaterangePicker = initDaterangePicker;
initDaterangePicker();

$(() => {
    let oldXHR = window.XMLHttpRequest;

    function newXHR() {
        let realXHR = new oldXHR();
        realXHR.addEventListener('readystatechange', () => {
            if (realXHR.readyState == 4) {
                initDaterangePicker();
            }
        }, false);
        return realXHR;
    }

    window.XMLHttpRequest = newXHR;
});
