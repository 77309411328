import { promisifiedAjax } from '@/utils/functions';

export default class TaxesClass {
    static loadData() {
        let partApp = !empty(window.spl_config.customer) ? 'portal' : 'admin';
        return new Promise((resolve) => {
            promisifiedAjax({
                method: 'GET',
                url: `/${partApp}/load-data--get-all-taxes-list`,
            }).then((data) => {
                resolve(data);
            });
        });
    }

    static loadTaxDeleteModalData(url) {
        return new Promise((resolve) => {
            promisifiedAjax({
                method: 'GET',
                url,
            }).then((data) => {
                resolve(data);
            });
        });
    }
}
