import { isset } from '@/utils/functions';

let codeCache = {};
let pagesPromises = {};

/**
 * Check is this portal or admin site
 * @returns {boolean}
 */
function isPortal() {
    return window.location.pathname.slice(0, 7) === '/portal';
}

function getCodeUrl() {
    if (isPortal()) {
        return '/portal/add-on--get-code';
    }

    return '/admin/config/add-on--get-code';
}

export default function loadAddonCode(page, params) {
    let key = page;
    if (!empty(params)) {
        key += window.md5(JSON.stringify(params)); // add hash if params not empty
    }
    if (isset(pagesPromises, key)) {
        return pagesPromises[key];
    }

    pagesPromises[key] = new Promise((resolve, reject) => {
        if (isset(codeCache, key)) {
            delete pagesPromises[key];
            resolve(codeCache[key]);
            return;
        }

        let preparedPage = page.replace(/\\\\/g, '\\');
        let data = {
            before: {
                page: preparedPage,
                params,
                location: 'before_content',
            },
            after: {
                page: preparedPage,
                params,
                location: 'after_content',
            },
        };

        $.ajax({
            url: getCodeUrl(),
            method: 'POST',
            data,
            success: (response) => {
                if (isset(response, 'error')) {
                    delete pagesPromises[key];
                    reject(response);
                } else {
                    let codes = {
                        before: isset(response, 'before_code') ? response.before_code : '',
                        after: isset(response, 'after_code') ? response.after_code : '',
                    };
                    delete pagesPromises[key];
                    codeCache[key] = codes;
                    resolve(codes);
                }
            },
            error: (err) => {
                delete pagesPromises[key];
                reject(err);
            },
        });
    });

    return pagesPromises[key];
}
