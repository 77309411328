/*
    Based on https://github.com/dreamonkey/vue-routes-flattener
 */

function shouldFlatRoute(route) {
    return route.path && route.children && !route.component;
}

function applyPrefix(prefix, routes) {
    return routes.map((route) => {
        if (typeof route.delimiter !== 'undefined') {
            route.path = prefix + route.delimiter + route.path;
        } else {
            route.path = `${prefix}/${route.path}`;
        }
        return route;
    });
}

function flatRoutes(routes) {
    const flattenedRoutes = [];

    for (const route of routes) {
        if (!route.children || route.children.length === 0) {
            flattenedRoutes.push(route);
        } else if (shouldFlatRoute(route)) {
            flattenedRoutes.push(
                ...flatRoutes(applyPrefix(route.path, route.children)),
            );
        } else {
            route.children = flatRoutes(route.children);
            flattenedRoutes.push(route);
        }
    }

    return flattenedRoutes;
}

export default flatRoutes;
export { flatRoutes };
