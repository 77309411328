import { empty } from '@/utils/functions';

export default {
    props: {
        coloredRowsButton: {
            type: Boolean,
        },
    },
    methods: {
        setColoredButton() {
            this.getColoredStateLocalStorage(this.firstRun);
            let button = document.createElement('button');
            let icon = document.createElement('i');
            let buttonWrap = document.querySelector('.dt-buttons-wrap');
            let tableWrapper = document.querySelector('.dataTables_wrapper');
            let buttonGroup = document.querySelector('.dt-buttons');
            let buttonClassList = ['btn', 'btn-outline-secondary', 'btn-icon', 'me-8', 'active'];
            let table = document.getElementById(this.id);

            if (!this.localStateColoredButton) {
                buttonClassList.pop();
                tableWrapper.classList.add('table-not-colored');
                table.classList.remove('table-colored');
            } else {
                table.classList.add('table-colored');
            }

            button.setAttribute('type', 'button');
            button.setAttribute('id', 'toggle_color_button');
            button.setAttribute('title', this.$t('common', 'Toggle colored rows according to status'));
            button.classList.add(...buttonClassList);
            icon.classList.add('icon-ic_fluent_color_24_regular');
            button.appendChild(icon);
            buttonWrap.insertBefore(button, buttonGroup);

            button.addEventListener('click', (event) => {
                event.stopPropagation();
                this.toggleColoredClasses();
            }, true);
        },
        toggleColoredClasses() {
            let button = document.getElementById('toggle_color_button');
            let tableWrapper = button.closest('.dataTables_wrapper');
            let table = document.getElementById(this.id);

            button.classList.toggle('active');
            tableWrapper.classList.toggle('table-not-colored');
            table.classList.toggle('table-colored');
            this.localStateColoredButton = !this.localStateColoredButton;
            this.saveColoredStateLocalStorage();
        },
        saveColoredStateLocalStorage() {
            if (!supports_html5_storage()) {
                return;
            }
            localStorage.setItem(`coloredState${this.id}DataTable`, this.localStateColoredButton);
        },
        getColoredStateLocalStorage(firstRun) {
            if (!supports_html5_storage() || !firstRun) {
                return;
            }
            let localStorageValue = localStorage.getItem(`coloredState${this.id}DataTable`);
            if (empty(localStorageValue)) {
                return;
            }

            this.localStateColoredButton = localStorageValue === 'true';
        },
    },
};
