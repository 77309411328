// Catalan
jQuery.timeago.settings.strings = {
  prefixAgo: "fa",
  prefixFromNow: "d'aqui a",
  suffixAgo: null,
  suffixFromNow: null,
  seconds: "menys d'1 minut",
  minute: "1 minut",
  minutes: "uns %d minuts",
  hour: "1 hora",
  hours: "unes %d hores",
  day: "1 dia",
  days: "%d dies",
  month: "aproximadament un mes",
  months: "%d mesos",
  year: "aproximadament un any",
  years: "%d anys"
};