export default {
    data() {
        return {
            backgroundUpdates: {},
            backgroundFunctions: {},
        };
    },
    activated() {
        this._makeUpdate();
    },
    methods: {
        setUpdateFunction(name, callback) {
            this.backgroundFunctions[name] = callback;
        },
        registerUpdate(name, params) {
            if (!this.backgroundUpdates[name]) {
                this.backgroundUpdates[name] = [];
            }
            this.backgroundUpdates[name].push(params);
        },
        _makeUpdate() {
            Object.keys(this.backgroundUpdates).forEach((name) => {
                this.backgroundUpdates[name].forEach((params, index) => {
                    this.backgroundUpdates[name].splice(index, 1);
                    this.backgroundFunctions[name](params);
                });
            });
        },
    },
};
