import {
    CHANGE_NOTIFICATIONS_LIST,
    ADD_NOTIFICATION_TO_LIST,
} from '@/apps/admin/store/mutation-types';

const state = {
    notificationsList: [],
};

const actions = {
    changeNotificationList({ commit }, notificationsList) {
        commit(CHANGE_NOTIFICATIONS_LIST, notificationsList);
    },
    addNotificationToList({ commit }, notification) {
        commit(ADD_NOTIFICATION_TO_LIST, notification);
    },
};

const getters = {
    notificationsList(state) {
        return state.notificationsList;
    },
};

const mutations = {
    [CHANGE_NOTIFICATIONS_LIST](state, notificationsList) {
        state.notificationsList = notificationsList;
    },
    [ADD_NOTIFICATION_TO_LIST](state, notification) {
        state.notificationsList.push(notification);
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
