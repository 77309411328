<template>
    <div class="splynx-wrapper empty-page">
        <slot />
        <x-global-notifications />
        <x-modal-wrapper ref="modalWrapper" />

        <!-- vue-js-modal tooltip component -->
        <tooltip
            :width="230"
            class="vue-js-modal-tooltip"
        />

        <x-tooltip
            :name="'helpTooltip'"
            :width="230"
            class="vue-js-modal-tooltip x-tooltip-content"
        />
    </div>
</template>

<script>
import { XTooltip } from '@/components/base/XTooltip';
import XGlobalNotifications from '@/components/base/XGlobalNotification';
import { XModalWrapper } from '@/plugins/XModalWrapper';

export default {
    name: 'EmptyLayout',
    components: {
        XGlobalNotifications,
        XModalWrapper,
        XTooltip,
    },
};
</script>
