function formatTaxesForSelect(taxes) {
    if (!taxes.taxes?.length && !taxes.taxGroups?.length) {
        return {};
    }

    const archivedText = window.xApp.$splang.t('finance', 'Archived');
    const result = {};

    if (taxes.taxes?.length) {
        result.taxes = [];
        taxes.taxes.forEach((tax) => {
            result.taxes.push(formatTaxForSelect(tax, archivedText));
        });
    }

    if (taxes.taxGroups?.length) {
        result.taxGroups = [];
        taxes.taxGroups.forEach((tax) => {
            result.taxGroups.push(formatTaxForSelect(tax, archivedText));
        });
    }

    return result;
}

function formatTaxForSelect(tax, archivedText) {
    let result = {
        id: tax.id,
        text: `${tax.rate_formatted} (${tax.name})`,
    };

    if (tax.archived) {
        result.badge = {
            text: archivedText,
            background: 'bg-secondary',
        };
    }

    return result;
}

export {
    formatTaxesForSelect,
};

export default formatTaxesForSelect;
