let timeoutTabsRefresh = null;
(function () {
    // Session variables names
    const SESSION_TABS_REFRESH = 'tabs_refresh';
    const SESSION_TABS_STATE = 'tabs_state';

    let setItem = function (key, value) {
        localStorage.setItem(key, value);
    };

    let getItem = function (key) {
        return localStorage.getItem(key);
    };

    /**
     * If your tabs element can be have different ids, you can use data-tabs-name for set one name for all tabs,
     * so all tabs have the same state of opened tab
     * @param tabsEl
     * @returns {*|jQuery|undefined}
     */
    let getTabsUniqueName = function (tabsEl) {
        let tabsUniqueName = $(tabsEl).attr('id');
        if ($(tabsEl).attr('data-tabs-name')) {
            tabsUniqueName = $(tabsEl).attr('data-tabs-name');
        }

        return tabsUniqueName;
    };

    let clearTabsRefresh = function () {
        if (timeoutTabsRefresh !== null) {
            clearTimeout(timeoutTabsRefresh);
        }
        timeoutTabsRefresh = setTimeout(() => {
            setItem(SESSION_TABS_REFRESH, '0');
        }, 5000);
    };

    // Before refresh page
    window.addEventListener('beforeunload', () => {
        if (timeoutTabsRefresh !== null) {
            clearTimeout(timeoutTabsRefresh);
        }
        setItem(SESSION_TABS_REFRESH, '1');
    });

    /**
     * Activate tab callback decorator
     * Save current tab id to session
     * @param f
     * @param element
     * @returns {Function}
     */
    let activateTabsDecorator = function (f, element) {
        return function () {
            let tabsUniqueName = getTabsUniqueName(element);
            let activeTabIndex = $(element).tabs('option', 'active');
            let tabsState = getItem(SESSION_TABS_STATE);

            tabsState = tabsState === null || tabsState === undefined ? {} : JSON.parse(tabsState);
            tabsState[tabsUniqueName] = activeTabIndex;

            setItem(SESSION_TABS_STATE, JSON.stringify(tabsState));

            return f.apply(this, arguments);
        };
    };

    let beforeActivateTabsDecorator = function (f) {
    // Do not replace var with let!
        let isConfirmed = false;

        return function (event, ui) {
            if (!isConfirmed && isHaveUnsavedData(ui.oldPanel)) {
                window.showUnsavedDataDialog(ui.oldPanel, () => {
                    isConfirmed = true;
                    forgetUnsavedData(ui.oldPanel);
                    let targetIndex = $(ui.newTab.get(0)).index();
                    $(event.target).tabs('option', 'active', targetIndex);
                }, null, t('common', 'Are you sure that you want to leave tab? All unsaved data will be lost!'));

                event.preventDefault();
            }

            if (isConfirmed) {
                isConfirmed = false;
            }

            return f.apply(this, arguments);
        };
    };

    /**
     * Adds preloader
     * @param f
     * @returns {function(*, *): *}
     */
    let beforeLoadTabsDecorator = function (f) {
        return function (event, ui) {
            ui.panel.html(`<div class="main-tab-holder ps-20 pe-20">${t('common', 'Loading...')}</div>`);
            ui.jqXHR.fail(() => {
                ui.panel.html(`<div class="main-tab-holder ps-20 pe-20">${t('common', 'Couldn\'t load!')}</div>`);
            });

            return f.apply(this, arguments);
        };
    };

    /**
     * Get initial tab index
     * @param element
     * @returns {number}
     */
    let getInitialTabIndex = function (element) {
        let tabsState = getItem(SESSION_TABS_STATE);
        let defaultTabIndex = 0;

        if (tabsState !== null && tabsState !== undefined) {
            let tabsUniqueName = getTabsUniqueName(element);
            tabsState = JSON.parse(tabsState);

            let isRefresh = getItem(SESSION_TABS_REFRESH);
            isRefresh = isRefresh === '1';

            let persistentState = $(element).is('[data-persistent-state]');

            // Restore state only after refresh or always for persistent tabs (with data-persistent-state attribute)
            if (isRefresh || persistentState) {
                if (tabsState[tabsUniqueName] !== undefined) {
                    return tabsState[tabsUniqueName];
                }
            } else {
                tabsState[tabsUniqueName] = defaultTabIndex;
                setItem(SESSION_TABS_STATE, JSON.stringify(tabsState));
            }
            clearTabsRefresh();
        }

        return defaultTabIndex;
    };

    /**
     * Jquery tabs decorator
     * Used for saving current active tab to session and auto open that tab after refreshing
     * @param f
     * @returns {Function}
     */
    let tabsDecorator = function (f) {
        return function () {
            let args = Array.from(arguments);

            if (args.length === 0) {
                args[0] = {};
            }

            if (args[0] instanceof Object) {
                if (typeof args[0].active === 'undefined') {
                    args[0].active = getInitialTabIndex(this);
                }

                if (args[0].activate === undefined) {
                    args[0].activate = activateTabsDecorator(() => {}, this);
                } else {
                    args[0].activate = activateTabsDecorator(args[0].activate, this);
                }

                if (args[0].beforeActivate === undefined) {
                    args[0].beforeActivate = beforeActivateTabsDecorator(() => {}, this);
                } else {
                    args[0].beforeActivate = beforeActivateTabsDecorator(args[0].beforeActivate, this);
                }

                if (args[0].beforeLoad === undefined) {
                    args[0].beforeLoad = beforeLoadTabsDecorator(() => {}, this);
                } else {
                    args[0].beforeLoad = beforeLoadTabsDecorator(args[0].beforeLoad, this);
                }
            }

            return f.apply(this, args);
        };
    };

    $.fn.tabs = tabsDecorator($.fn.tabs);
}());
