export default {
    collapseState(state) {
        return state.collapseState;
    },
    mobileState(state) {
        return state.mobileState;
    },
    mobileWidth(state) {
        return state.mobileWidth;
    },
};
