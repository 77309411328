import {
    debounce, empty, emptyObject, isset,
} from '@/utils/functions';
import moment from 'moment/moment';
import { mapGetters } from 'vuex';

export default {
    props: {
        headers: {
            type: Array,
        },
        title: {
            type: String,
        },
        buttonWrapperOptions: {
            type: Object,
            default: () => ({
                class: 'mass-action-buttons',
                style: 'display: inline-block;',
            }),
        },
        enableCheckActions: {
            type: Boolean,
            default: false,
        },
        ajaxUrl: {
            type: [String, Object],
            default: '',
        },
        stateSave: {
            type: Boolean,
            default: true,
        },
        rememberSearch: {
            type: Boolean,
            default: false,
        },
        actionColumn: {
            type: Boolean,
            default: true,
        },
        searchByDefault: {
            type: String,
            default: '',
        },
        checkbox: {
            type: Boolean,
            default: false,
        },
        targetCheckbox: {
            type: Number,
            default: 0,
        },
        checkboxAlert: {
            type: Boolean,
            default: false,
        },
        tableOptions: {
            type: Object,
            default() {
                return {
                    class: 'display supertable table table-striped',
                };
            },
        },
        dataTableOptions: {
            type: Object,
            default() {
                return {};
            },
        },
        columnDefs: {
            type: [Object, Array],
            default() {
                return {};
            },
        },
        columnsConfig: {
            type: [Object, Array],
            default() {
                return [];
            },
        },
        checkboxOptions: {
            type: Object,
        },
        initByEvent: {
            type: Boolean,
            default: false,
        },
        actionColumnTitle: {
            type: String,
            default: '',
        },
        modelName: {
            type: String,
            default: '',
        },

        // Callbacks
        drawCallback: {
            type: Function,
        },
        createdRow: {
            type: Function,
        },
        fnInitComplete: {
            type: Function,
        },
        stateLoadParams: {
            type: Function,
        },
        massAction: {
            type: [Object, Boolean],
            default: false,
        },
        fileNameForExport: {
            type: String,
            default: '*',
        },
        showDataTableButtons: {
            type: Boolean,
            default: true,
        },
        chunksTable: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.localTableTagOptions = { ...this.tableOptions };
        this.debounceFn = null;
        window.addEventListener('resize', this.handleResize);
        splynx_event_bus.on('after_switch_page', this._handleSwitchPage);
    },
    activated() {
        window.activeDT = $(`#${this.id}`).DataTable();
    },
    destroyed() {
        if (this.dt) {
            this.dt.destroy();
        }
        window.removeEventListener('resize', this.handleResize);
        splynx_event_bus.off('after_switch_page', this._handleSwitchPage);
    },
    data() {
        return {
            firstRun: true,
            showMassProgress: false,
            localStateColoredButton: true,
            localTableTagOptions: {},
            colsForInsideTable: {},
        };
    },
    methods: {
        init() {
            this._initTable();
            this.underHeadSlotElement = $(`#${this.id}_wrapper`).siblings('#dt-under-header').detach(true);
            this.underHeadSlot();
        },
        _initTable(manualInit = false) {
            this._setUpEventHandlers(this.firstRun);
            window[this.jsVar] = $(`#${this.id}`).DataTable(this._getDtOptions(this.firstRun));
            window.activeDT = $(`#${this.id}`).DataTable();
            if (manualInit) {
                let handleDrawDt = () => {
                    $(`#${this.id}`).trigger('manual-init-finished');
                    window[this.jsVar].off('draw.dt', handleDrawDt);
                };
                window[this.jsVar].on('draw.dt', handleDrawDt);
            }

            $(`#${this.computedWrapperId} .table_title`).html(this.title);

            if (this.searchByDefault !== '') {
                this.dt.search(this.searchByDefault);
            }

            window[this.jsVar].on('init.dt', () => {
                const element = $(`[name="${this.id}_length"`);
                if (element.data('select2')) {
                    $(element).data('select2').$results.addClass('select2-no-scrollbar');
                }
            });

            if (!this.chunksTable) {
                this._initHandlingMassButtonsEvents();
                this._initMassActionsLabels();
            }
            window[this.searchDelayVar] = null;
            window[this.lastSearchValVar] = '';
            window[this.wrapperVar] = $(`#${this.id}`).closest('.dataTables_wrapper');

            if (typeof enableSearchInputProcessing == 'undefined') {
                window.enableSearchInputProcessing = true;
            }

            $(`${this.wrapperIdSelector} .dataTables_filter input`).off('keyup.DT search.DT input.DT paste.DT cut.DT');

            $('body').on('input.DT paste.DT cut.DT', 'div.dataTables_filter input', () => {
                if (window.enableSearchInputProcessing) {
                    let searchInputValue = $(`${this.wrapperIdSelector} .dataTables_filter input`).val();
                    clearTimeout(window[this.searchDelayVar]);
                    this.dt.search(searchInputValue);
                    this.dt.state.save();

                    let searchDelay = this.serverSideConfig?.search_delay || 0;
                    window[this.searchDelayVar] = setTimeout(() => {
                        if (window[this.lastSearchValVar] !== searchInputValue) {
                            window[this.lastSearchValVar] = searchInputValue;
                            if (!$(`${this.wrapperIdSelector} .dataTables_filter input`).is('[readonly]')) {
                                $(this.$refs.table).dataTable().api().search(searchInputValue);
                                $(this.$refs.table).dataTable().api().draw();
                                $(this.$refs.table).dataTable().api().state.save();
                            }
                        }
                    }, searchDelay);
                }
            });

            this.wrapper.find('div.dataTables_filter input').trigger('input.DT');
            this.wrapper.find('.dataTables_length select').select2({
                minimumResultsForSearch: -1,
            });
            this.wrapper.find('.dataTables_length select').on('select2:open', () => {
                $('.select2-results__options').addClass('hide-scrollbar');
            });

            if (this.coloredRowsButton) this.setColoredButton();
            this.handleResize();

            this.firstRun = false;
        },
        underHeadSlot(async = true) {
            const handler = () => {
                if (this.underHeadSlotElement) {
                    this.underHeadSlotElement.insertBefore($(`#${this.id}_wrapper`).find('.dt-under-header'));
                }
            };
            if (!async) {
                handler();
                return false;
            }
            setTimeout(() => {
                handler();
            }, 50);
        },
        _handleSwitchPage() {
            if ($(`#${this.id}`).is(':visible')) {
                this.handleResize();
            }
        },
        _drawMenuLengthForDataTable(id, spp, current) {
            let serverSideLength = {
                10: 10,
                25: 25,
                50: 50,
                75: 75,
                100: 100,
                '-1': 'All',
            };
            let normalLength = {
                10: 10,
                25: 25,
                50: 50,
                75: 75,
                100: 100,
            };

            let forContent = {};

            if (!empty(this.dataTableOptions.lengthMenu) && !empty(this.dataTableOptions.lengthMenu[0]) && !empty(this.dataTableOptions.lengthMenu[1])) {
                let { lengthMenu } = this.dataTableOptions;
                lengthMenu[0].forEach((value, key) => {
                    forContent[value] = lengthMenu[1][key];
                });
            } else {
                forContent = spp ? serverSideLength : normalLength;
            }

            $(`#${id}_length`).find('option').remove();

            if (!isset(forContent, [current])) {
                current = 100;
            }

            for (let key in forContent) {
                if (Object.prototype.hasOwnProperty.call(forContent, key)) {
                    $(`select[name=${id}_length]`).append($('<option/>', {
                        value: key,
                        text: forContent[key],
                    }));
                }
            }

            $(`select[name=${id}_length]`).val(current);
        },
        getDataTableButtons() {
            let buttons = this.getDefaultButtons();
            if (typeof this.modelName === 'string' && this.modelName !== '') {
                let model = this.modelName;
                buttons.unshift({
                    container: '#colv',
                    className: 'btn-outline-secondary btn-icon',
                    text: '<i class="icon-ic_fluent_more_horizontal_24_regular"></i>',
                    action: this.actionForButtonShowHideColumns(model),
                    titleAttr: 'Show/hide columns',
                });
            }

            return buttons;
        },
        getDefaultButtons() {
            let XDatatable = this;
            let buttons = default_buttons.slice();
            let [firstKey] = buttons.keys();

            buttons[firstKey].buttons.forEach((item) => {
                if ('filename' in item) {
                    item.filename = function () {
                        return XDatatable.getFileNameForExport();
                    };
                }
            });

            return buttons;
        },
        getFileNameForExport() {
            return this.fileNameForExport.replaceAll('{date}', moment().format(datetime_format.replaceAll(':', ' ')));
        },
        actionForButtonShowHideColumns(model) {
            return function () {
                open_dialog_new(`profile--show-hide-columns?name=${model}`);
            };
        },
        handleResize() {
            if (typeof this.debounceFn !== 'function') {
                this.debounceFn = debounce(() => {
                    $(this.$refs.table).DataTable().columns.adjust().responsive.recalc();
                }, 500);
            }
            this.debounceFn();
        },
        tableAjaxReload() {
            $(this.$refs.table).DataTable().ajax.reload();
        },
        setAlertHtml(innerHtml) {
            this.getAlertElement().html(innerHtml);
        },
    },
    computed: {
        ...mapGetters(['serverSideConfig']),
        titleOfActionColumn() {
            if (this.actionColumnTitle) {
                return this.actionColumnTitle;
            }
            return this.$t('support', 'Actions');
        },
        buttonsBehavior() {
            let behavior = {};
            for (let key in this.buttons) {
                if (Object.prototype.hasOwnProperty.call(this.buttons, key)) {
                    let button = this.buttons[key];

                    if (isset(button, 'hide_button')) {
                        behavior[key] = button.hide_button == true;
                        continue;
                    }
                }

                behavior[key] = false;
            }

            return behavior;
        },
        massActionInfo() {
            let info = {
                progress: 0,
                message: 0,
            };

            if (this.massAction === false || this.massAction === null) {
                return info;
            }

            if (isset(this.massAction, 'percent')) {
                info.progress = parseInt(this.massAction.percent);
            }
            if (isset(this.massAction, 'message')) {
                info.message = this.massAction.message;
            }

            return info;
        },
        computedColumnDefs() {
            let { columnDefs } = this;
            columnDefs.forEach((currentValue, index) => {
                if (currentValue.render !== undefined && typeof currentValue.render !== 'function') {
                    columnDefs[index].render = new Function(`return ${currentValue.render}`)();
                }
                if (currentValue.type !== undefined) {
                    columnDefs[index].type = currentValue.type.replace(/"/g, '');
                }
            });

            if (this.chunksTable && !emptyObject(this.massActions)) {
                columnDefs.push({ orderable: false, targets: 0 });
            }

            return columnDefs;
        },
    },
    watch: {
        title(newTitle) {
            $(`#${this.computedWrapperId} .table_title`).html(newTitle);
        },
        tableOptions(newOptions) {
            this.localTableTagOptions = { ...newOptions };
        },
    },
};
