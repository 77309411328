import uniqid from 'uniqid';
import { empty } from '@/utils/functions';
import {
    PUSH_NOTIFICATION, REMOVE_INTERCEPTOR, REMOVE_NOTIFICATION, REMOVE_NOTIFICATIONS, SET_INTERCEPTOR,
} from './mutation-types';

const getNotifyPart = (state, id, limit) => {
    let index = state.notifications.findIndex((item) => item.id === id);
    if (index < limit) {
        return 1;
    }
    let part = 1;
    let item = 0;
    while (item < state.notifications.length) {
        item += limit;
        if (index < item) {
            return part;
        }
        part++;
    }
};

export default {
    intercept({ commit }, parameters) {
        commit(SET_INTERCEPTOR, parameters);
    },
    removeInterceptor({ commit }, type) {
        commit(REMOVE_INTERCEPTOR, type);
    },
    cleanNotifications({ commit }) {
        commit(REMOVE_NOTIFICATIONS);
    },
    showNotify({ commit, state }, data) {
        if (data.id === undefined) {
            data.id = uniqid('id');
        }

        // Type Check
        const MESSAGE_TYPES = ['info', 'success', 'warning', 'error', 'text', 'message_and_action'];
        if (!MESSAGE_TYPES.includes(data.type)) {
            throw new Error(`Failed to show notification. This type (${data.type}) of notification does not exist.`);
        }

        if (data.message.search('</a>') > 0) {
            let html = new DOMParser().parseFromString(data.message, 'text/html');
            let link = html.querySelector('a');
            data.message = html.querySelector('body').innerHTML;
            if (!link?.getAttribute('onclick') && !!link?.getAttribute('href')) {
                data.action_button = {
                    ...data.action_button,
                    action: link?.getAttribute('href'),
                };
            } else {
                data.action_button = {
                    ...data.action_button,
                    action: new Function([], link?.getAttribute('onclick')),
                };
            }

            if (typeof data.to_manager === 'undefined') {
                data.to_manager = true;
            }
        }

        commit(PUSH_NOTIFICATION, data);
        if (!empty(data.to_manager)) {
            if (data.action_button?.redirect_url && !data?.action_button?.action) {
                data.action_button.action = new Function([], `switch_page("${data.action_button.redirect_url}")`);
            }
            window.xApp.$store.dispatch('notifications/addNotificationToList', {
                type: data.type,
                message: data.message,
                action: data?.action_button?.action,
            });
        }
        if (!empty(data.timeout)) {
            setTimeout(() => {
                commit(REMOVE_NOTIFICATION, data.id);
            }, (data.timeout * 1000) * getNotifyPart(state, data.id, 5));
        }
    },
};
