<template>
    <input
        v-if="empty(error)"
        type="text"
        class="form-control input-sm"
        v-bind="options"
        :value="value"
        @focusout="handleFocusout"
        @input="handleInput"
    >
    <span
        v-else
        class="label label-danger"
    >{{ error }}</span>
</template>

<script>
import { empty } from '@/utils/functions';

export default {
    name: 'XAddonText',
    props: {
        attribute: String,
        options: Object,
        value: [String, Number],
        error: String,
        is_af: Boolean,
    },
    mounted() {
    // Set value from addon modal
        splynx_event_bus.on(`set-af-from-addon-modal-${this.attribute}`, this.handleSetInputValue);
    },
    methods: {
        empty,
        handleInput(event) {
            this.$emit('input', event.target.value);
        },
        handleFocusout(event) {
            this.$emit('focusout', event);
        },
        handleSetInputValue(data) {
            if (data.is_af == this.is_af) {
                this.$emit('input', data.value);
            }
        },
    },
};
</script>
