export default {
    beforeCreate() {
        const options = this.$options;
        if (options.splang && typeof options.splang === 'object') {
            if (options.splang instanceof window.SPLANG_CLASS) {
                this._splang = options.splang;
                this._splangWatcher = this._splang.watchSplangData();
            }
        } else if (this.$root && this.$root.$splang && this.$root.$splang instanceof window.SPLANG_CLASS) {
            this._splang = this.$root.$splang;
        } else if (options.parent && options.parent.splang && options.parent.splang instanceof window.SPLANG_CLASS) {
            this._splang = options.parent.splang;
        }
    },

    beforeMount() {
        const options = this.$options;
        options.splang = options.splang || (options.splang ? {} : null);

        if (options.splang) {
            if (options.splang instanceof window.SPLANG_CLASS) {
                this._splang._subscribeDataChanging(this);
                this._subscribing = true;
            }
        } else if (this.$root && this.$root.$splang && this.$root.$splang instanceof window.SPLANG_CLASS) {
            this._splang._subscribeDataChanging(this);
            this._subscribing = true;
        } else if (options.parent && options.parent.splang && options.parent.splang instanceof window.SPLANG_CLASS) {
            this._splang._subscribeDataChanging(this);
            this._subscribing = true;
        }
    },

    beforeDestroy() {
        if (!this._splang) {
            return;
        }

        const self = this;
        this.$nextTick(() => {
            if (self._subscribing) {
                self._splang._unsubscribeDataChanging(self);
                delete self._subscribing;
            }

            if (self._splangWatcher) {
                self._splangWatcher();
                self._splang.destroyVM();
                delete self._splangWatcher;
            }
        });
    },
};
