export default function extend(Vue) {
    if (!Object.prototype.hasOwnProperty.call(Vue.prototype, '$splang')) {
        Object.defineProperty(Vue.prototype, '$splang', {
            get() { return this._splang; },
        });
    }

    Vue.prototype.$t = function (category, phrase, params) {
        return this.$splang._t(category, phrase, params);
    };

    Vue.prototype.t = function (category, phrase, params) {
        return this.$splang._t(category, phrase, params);
    };
}
