<template>
    <div
        class="d-flex flex-wrap mt-16"
    >
        <div
            v-if="filePreview"
            class="col-md-12 flex-wrap d-flex justify-content-between"
        >
            <img
                :src="fileUrl"
                alt="partner logo"
                class="img-responsive"
            >
            <button
                v-if="showDeleteButton"
                type="button"
                class="btn btn-danger btn-icon-text mt-12 ms-auto"
                @click.prevent="action"
            >
                <i class="icon-ic_fluent_delete_24_regular" />
                <span>{{ $t('config', 'Delete') }}</span>
            </button>
        </div>
    </div>
</template>

<script>
import init from '@/components/common/inputs/XInputFile/on-show-modals';
import { empty } from '@/utils/functions';

export default {
    name: 'XConfigItemFileView',
    props: {
        meta: {
            type: Object,
            default() {
                return {};
            },
        },
        fileData: {
            type: String,
            default: '',
        },
        showDeleteButton: {
            type: Boolean,
            default: true,
        },
        inputValue: {
            type: [File, String, Number],
        },
    },
    data() {
        return {
            fileUrl: '',
        };
    },
    mounted() {
        init(this);
        this.$nextTick(() => {
            this.getFileUrl();
        });
    },
    methods: {
        deleteFile(modalName) {
            showModal(modalName, this.getDeletePartnerLogoParams);
        },
        action() {
            return this.deleteFile(this.meta.action);
        },
        getFileUrl() {
            if (Object.prototype.toString.call(this.inputValue) === '[object File]' && this.fileData !== '') {
                this.fileUrl = this.fileData;
                return false;
            }
            let { id } = this.meta;

            if (this.existImage) {
                id = this.inputValue;
            }
            this.fileUrl = `${this.meta.url}${id}`;
        },
    },
    computed: {
        getDeletePartnerLogoParams() {
            let message = this.partnerId !== ''
                ? this.$t('config', 'Are you sure you want to delete the logo?')
                : this.$t('config', 'Are you sure you want to delete the default logo? The logo will be deleted for all partners that use it.');

            return {
                confirmMessage: message,
                title: this.$t('config', 'Delete'),
            };
        },
        filePreview() {
            return this.firstDownload || this.existImage || this.imageUploaded || this.canceledUploading;
        },
        firstDownload() {
            return this.inputValue !== '' && !empty(this.fileData);
        },
        existImage() {
            return this.inputValue !== '' && typeof this.inputValue === 'number';
        },
        imageUploaded() {
            return Object.prototype.toString.call(this.inputValue) === '[object File]' && this.firstDownload;
        },
        canceledUploading() {
            return !empty(this.meta.id) && this.inputValue === '';
        },
    },
    watch: {
        inputValue() {
            this.getFileUrl();
        },
        fileData() {
            this.getFileUrl();
        },
    },
};
</script>
