export default {
    props: {
        id: {
            type: String,
            default: '',
        },
        wrapperId: {
            type: String,
            default: '',
        },
    },
    methods: {
        getAlertElement() {
            return $(this.wrapperIdSelector).find('.dt-under-header');
        },
    },
    computed: {
        dt() {
            return $(this.$refs.table).DataTable();
        },
        wrapper() {
            return $(`#${this.id}`).closest('.dataTables_wrapper');
        },
        jsVar() {
            return `${this.id}_dt`;
        },
        searchDelayVar() {
            return `searchDelay${this.jsVar}`;
        },
        lastSearchValVar() {
            return `lastSearchVal${this.jsVar}`;
        },
        wrapperVar() {
            return `dataTableWrapper${this.jsVar}`;
        },
        searchInputValueVar() {
            return `searchInputValue${this.jsVar}`;
        },
        computedWrapperId() {
            return this.wrapperId !== '' ? this.wrapperId : `${this.id}_wrapper`;
        },
        wrapperIdSelector() {
            return `#${this.computedWrapperId}`;
        },
    },
};
