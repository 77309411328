export default {
    methods: {
        addClass(classes, newClasses) {
            let classesArray = classes.split(/\s+/);

            if (Array.isArray(newClasses)) {
                newClasses.forEach((newClass) => {
                    if (!classesArray.includes(newClass) && newClass !== '') {
                        classesArray.push(newClass);
                    }
                });
            } else if (!classesArray.includes(newClasses)) {
                classesArray.push(newClasses);
            }

            classesArray = classesArray.filter((className) => className !== '');

            return classesArray.join(' ');
        },
    },
};
