<template>
    <div :class="wrapperClass">
        <img
            v-if="!initialized"
            style="display: block; margin: 0 auto;"
            src="/images/loading.gif"
            :alt="$t('support', 'Please wait')"
        >
        <textarea
            :id="id"
            ref="input"
            rows="10"
            :style="styles"
            :value="value"
            :class="classes"
            :name="name"
            :required="required"
            :data-test-selector="textareaTestSelector"
            :disabled="disabled"
        />
    </div>
</template>

<script>
import initCodeSnippetPlugin from './custom-plugins/code_snippet';

const DEFAULT_FROALA_BUTTONS = [
    'bold', 'italic', 'underline',
    '|', 'formatOL', 'formatUL', 'insertLink',
    '|', 'color', 'insertTable', '|',
    'insertImage', 'clearFormatting', 'html',
    'codeSnippet',
];

export default {
    name: 'XFroala',
    props: {
        value: {
            type: String,
        },
        licenseKey: {
            type: String,
            default: 'bTYPASIBGMWC1YLMP==',
        },
        name: {
            type: String,
        },
        id: {
            type: String,
        },
        required: {
            type: Boolean,
        },
        classes: {
            type: String,
            default: 'form-control input-sm',
        },
        styles: {
            type: String,
            default: 'width: 100%; display: none;',
        },
        iframe: {
            type: Boolean,
            default: false,
        },
        language: {
            type: String,
            default: 'en',
        },
        enter: {
            type: Number,
            default: () => window.$.FroalaEditor.ENTER_DIV,
        },
        useClasses: {
            type: Boolean,
            default: true,
        },
        initOnClick: {
            type: Boolean,
            default: false,
        },
        placeholderText: {
            type: String,
            default() {
                return this.$t('common', 'Start typing something...');
            },
        },
        zIndex: {
            type: Number,
            default: 19,
        },
        theme: {
            type: String,
            default: 'gray',
        },
        charCounterMax: {
            type: Number,
            default: 0,
        },
        pastePlain: {
            type: Boolean,
            default: false,
        },
        heightMin: {
            type: Number,
            default: 150,
        },
        heightMax: {
            type: Number,
            default: 1000,
        },
        htmlExecuteScripts: {
            type: Boolean,
            default: false,
        },
        toolbarButtons: {
            type: Array,
            default() {
                return DEFAULT_FROALA_BUTTONS;
            },
        },
        toolbarButtonsMD: {
            type: Array,
            default() {
                return [];
            },
        },
        toolbarButtonsSM: {
            type: Array,
            default() {
                return [];
            },
        },
        toolbarButtonsXS: {
            type: Array,
            default() {
                return [];
            },
        },
        toolbarSticky: {
            type: Boolean,
            default: false,
        },
        toolbarStickyOffset: {
            type: Number,
            default: 0,
        },
        enableSupportImages: {
            type: Boolean,
            default: false,
        },
        prefixUploadUrl: {
            type: String,
            default: 'admin',
        },
        csrfToken: {
            type: String,
            default() {
                return $('head').find('meta[name="csrf-token"]').attr('content');
            },
        },
        mentions: {
            type: Boolean,
            default: false,
        },
        cannedResponsesType: {
            type: String,
            default: 'all',
        },
        mentionsList: {
            type: Array,
            default() {
                return this.mentionsListDefault;
            },
        },
        textareaTestSelector: {
            type: String,
            default: 'froala_textarea',
        },
        toolbarInline: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            initialized: false,
            initialValue: '',
            handleChangingValue: true,
            mentionsListDefault: [],
            valueAttribute: '',
        };
    },
    mounted() {
        window[`${this.variableFromId}_froala_input`] = this;
        let toLoad = [];
        let themeStyles = `/css/development/froala/themes/${this.theme}.css`;
        if ($(`link[href="${themeStyles}"]`).length === 0) {
            toLoad.push(themeStyles);
        }

        if (this.language !== 'en') {
            let langJs = `/js/${process.env.NODE_ENV}/froala/languages/${this.language}.js`;
            if ($(`script[src="${langJs}"]`).length === 0) {
                toLoad.push(langJs);
            }
        }

        let handleFinishLoading = () => {
            this.initFroala();
            this.initialized = true;

            if (!empty(this.initialValue)) {
                this.setValueToFroala(this.value);
                this.initialValue = '';
            }

            this.$emit('initialized');
        };

        if (toLoad.length > 0) {
            $.getScript('/js/development/head.min.js', () => {
                head.load(toLoad, handleFinishLoading);
            });
        } else {
            handleFinishLoading();
        }
    },
    methods: {
        initCustomPlugins() {
            initCodeSnippetPlugin(this);
        },
        initFluentIconsTemplate() {
            Object.assign($.FroalaEditor.ICON_TEMPLATES, {
                united: '<i class="fa fa-[NAME] [NAME]"></i>',
            });
            window.setFroalaIcons('united');
        },
        initFroala() {
            this.initFluentIconsTemplate();

            let froalaInput = $(this.$refs.input);
            if (froalaInput.data('froala.editor')) {
                froalaInput.froalaEditor('destroy');
            }

            this.initCustomPlugins();

            froalaInput.on('froalaEditor.initialized', (e, editor) => {
                window.globalEditor = editor;
                setTimeout(() => {
                    this.$nextTick(() => {
                        if (this.iframe) {
                            $(this.$refs.input)
                                .froalaEditor('size.syncIframe');
                            $(this.$refs.input)
                                .froalaEditor('size.refresh');
                        }
                    });

                    if (this.disabled) {
                        editor.edit.off();
                    } else if (this.mentions) {
                        window.initMentions(editor.$el, this.mentionsList, editor.events);
                    }
                }, 250);
            }).on('froalaEditor.click', () => {
                window.closeSelects();
            })
                .on('froalaEditor.html.get', (event, editor, html) => {
                    if (window.checkFroalaEmptyContent(html) && editor.$oel.attr('required')) {
                        return '';
                    }
                    return html;
                })
                .on('froalaEditor.input', () => {
                    this.handleChangingValue = false;
                    this.$emit('input', froalaInput.froalaEditor('html.get'));
                })
                .on('froalaEditor.contentChanged', () => {
                    this.handleChangingValue = false;
                    this.$emit('input', froalaInput.froalaEditor('html.get'));
                })
                .on('froalaEditor.image.beforeUpload', (e) => {
                    froalaInput.show();
                    $('.fr-image-progress-bar-layer').parent('.fr-popup').css({ left: $(e.currentTarget).offset().left });
                    froalaInput.hide();
                })
                .on('froalaEditor.image.uploaded', () => {
                    $('.fr-image-overlay + .fr-popup [data-cmd="imageLink"]').on('click', () => {
                        $('.fr-link-insert-layer').parent('.fr-popup').removeClass('fr-hidden');
                    });
                })
                .on('froalaEditor.clearFormatting', () => {
                    this.handleChangingValue = false;
                    this.$emit('input', froalaInput.froalaEditor('html.get'));
                })
                .on('froalaEditor.focus', (e, editor) => {
                    if (window.activeEditorSnippetPlugin && window.activeEditorSnippetPlugin.id !== editor.$oel.attr('id')) {
                        window.activeEditorSnippetPlugin.id = editor.$oel.attr('id');
                    }
                })
                .on('froalaEditor.paste.after', () => {
                    this.handleChangingValue = false;
                    this.$emit('input', froalaInput.froalaEditor('html.get'));
                });
            froalaInput.froalaEditor(this.getFroalaConfig());
        },
        getFroalaConfig() {
            let toolbarButtons = this.getToolbarButtons();
            let conf = {
                cannedResponsesType: this.cannedResponsesType,
                enter: this.enter,
                useClasses: this.useClasses,
                initOnClick: this.initOnClick,
                placeholderText: this.placeholderText,
                zIndex: this.zIndex,
                iframe: this.iframe,
                language: this.language,
                theme: this.theme,
                pastePlain: this.pastePlain,
                key: this.licenseKey,
                heightMin: this.heightMin,
                heightMax: this.heightMax,
                htmlExecuteScripts: this.htmlExecuteScripts,
                htmlRemoveTags: ['input', 'textarea', 'select', 'option', 'optgroup'],
                htmlUntouched: true,
                pasteAllowedStyleProps: [],
                htmlAllowedTags: ['.*'],
                entities: '',
                htmlAllowedAttrs: ['.*'],
                htmlAllowedEmptyTags: [],
                codeBeautifierOptions: {
                    end_with_newline: true,
                    indent_inner_html: true,
                    extra_liners: "['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'pre', 'ul', 'ol', 'table', 'dl']",
                    brace_style: 'expand',
                    indent_char: ' ',
                    indent_size: 4,
                    wrap_line_length: 0,
                },
                customPlugins: ['codeSnippet'],
                allowStyle: true,
                toolbarButtons: toolbarButtons.lg,
                toolbarButtonsMD: toolbarButtons.md,
                toolbarButtonsSM: toolbarButtons.sm,
                toolbarButtonsXS: toolbarButtons.xs,
                toolbarSticky: this.toolbarSticky,
                toolbarStickyOffset: this.toolbarStickyOffset,
                toolbarInline: this.toolbarInline,
            };

            if (this.charCounterMax > 0) {
                conf.charCounterMax = this.charCounterMax;
            }

            if (this.enableSupportImages) {
                conf.imageEditButtons = ['imageReplace', 'imageAlign', 'imageCaption', 'imageRemove', '|',
                    'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageAlt', 'imageSize'];
                conf.imageInsertButtons = ['imageUpload'];
                conf.imageDefaultAlign = 'left';
                conf.imageUploadURL = `/${this.prefixUploadUrl}/images--upload?csrfToken=${this.csrfToken}`;
                conf.imageUploadParam = 'FroalaImage[file]';
                conf.imageUploadParams = {
                    id: 'FroalaImage[file]',
                    _csrf_token: this.csrfToken,
                };
                conf.imageManagerLoadURL = `/${this.prefixUploadUrl}/images--list?csrfToken=${this.csrfToken}`;
                conf.imageManagerDeleteURL = `/${this.prefixUploadUrl}/images--delete?csrfToken=${this.csrfToken}`;
                conf.imageManagerDeleteMethod = 'POST';
                conf.imageManagerDeleteParams = {
                    id: 'file',
                    _csrf_token: this.csrfToken,
                };
            } else {
                conf.imagePaste = false;
                conf.imageUpload = false;
            }

            return conf;
        },
        setValueToFroala(value) {
            $(this.$refs.input).froalaEditor('html.set', value);
            $(this.$refs.input).trigger('froalaEditor.contentChanged', value);
        },
        getFroala() {
            return this.$refs.input;
        },
        getToolbarButtons() {
            let buttons = {
                lg: this.toolbarButtons,
                md: [],
                sm: [],
                xs: [],
            };

            buttons.md = empty(this.toolbarButtonsMD) ? this.toolbarButtons : this.toolbarButtonsMD;
            buttons.sm = empty(this.toolbarButtonsSM) ? buttons.md : this.toolbarButtonsSM;
            buttons.xs = empty(this.toolbarButtonsXS) ? buttons.sm : this.toolbarButtonsXS;

            return buttons;
        },
    },
    computed: {
        wrapperClass() {
            return ['splynx-froala', 'position-relative', 'x-froala', `froala-${this.id}`];
        },
        variableFromId() {
            return this.id ? this.id.replaceAll('-', '_') : '_';
        },
    },
    watch: {
        value(newValue) {
            // Prevent setting value to Froala widget when value changed by @input event
            if (newValue === '' || window.stripTagsFromString(newValue).trim() === '') {
                $(this.$refs.input).html('');
                this.$nextTick(() => {
                    if (this.required) {
                        this.$refs.input.setCustomValidity(this.$t('common', 'Please fill in this field'));
                    }
                });
            } else {
                this.$refs.input.setCustomValidity('');
            }
            if (!this.handleChangingValue) {
                this.handleChangingValue = true;
                return;
            }

            if (!this.initialized) {
                this.initialValue = newValue;
            } else {
                this.setValueToFroala(newValue);
            }
        },
    },
};
</script>
