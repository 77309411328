import { warn } from '@/splang/utils';
import extend from './extend';
import mixin from './mixin';
import XTranslate from './XTranslate';

export default function install(_Vue) {
    install.installed = true;
    let vue = _Vue;

    const version = (vue.version && Number(vue.version.split('.')[0])) || -1;
    if (version < 2) {
        warn(`Splang (${install.version}) need to use Vue 2.0 or later (Vue: ${vue.version})`);
        return;
    }

    extend(vue);
    vue.mixin(mixin);

    // Register global component <x-translate>
    vue.component(XTranslate.name, XTranslate);

    const strats = vue.config.optionMergeStrategies;
    strats.splang = function (parentVal, childVal) {
        return childVal === undefined ? parentVal : childVal;
    };

    return vue;
}
