<template>
    <div class="input-group">
        <div class="input-wrap">
            <select
                ref="input"
                v-bind="attributes"
            >
                <template v-if="withGroups">
                    <optgroup
                        v-for="(group, index) in selectData"
                        :key="index"
                        :label="group.label"
                    >
                        <option
                            v-for="(option, optionIndex) in group.children"
                            :key="optionIndex"
                            :value="option.value"
                            v-html="option.text"
                        />
                    </optgroup>
                </template>
                <template v-else>
                    <option
                        v-for="(option, index) in selectData"
                        :key="index"
                        :value="option.value"
                        v-html="option.text"
                    />
                </template>
            </select>
        </div>

        <x-relation-info
            v-if="additionalBlock"
            :module="module"
            :category="category"
            :name="name"
            :value="value"
            @input="$emit('input', $event)"
        />
    </div>
</template>

<script>
import XRelationInfo from '@/components/common/inputs/XRelationInfo/XRelationInfo';
import XMultipleSelect from '@/components/common/inputs/XMultipleSelect';

export default {
    name: 'XMultipleSelectRelation',
    components: { XRelationInfo },
    extends: XMultipleSelect,
    inheritAttrs: false,
    props: {
        additionalBlock: {
            type: Boolean,
            default: false,
        },
        module: String,
        name: String,
        category: String,
    },
};
</script>
