function applyIcon(icon, color, routes) {
    for (let child of routes) {
        if (Object.prototype.hasOwnProperty.call(child, 'meta')) {
            child.meta = { ...child.meta, icon, color };
        } else {
            child.meta = { icon, color };
        }
        if (child.children && child.children.length > 0) {
            applyIcon(icon, color, child.children);
        }
    }
}

function makeIcons(routes) {
    let routesWithIcons = [];
    for (const route of routes) {
        if (!route.children || route.children.length === 0) {
            routesWithIcons.push(route);
        } else {
            if (Object.prototype.hasOwnProperty.call(route, 'meta')) {
                let parentIcon = route.meta.icon ?? false;
                let parentColor = route.meta.color ?? false;
                applyIcon(parentIcon, parentColor, route.children);
            }
            routesWithIcons.push(route);
        }
    }

    return routesWithIcons;
}

export default makeIcons;
export { makeIcons };
