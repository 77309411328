<template>
    <div class="splynx-header">
        <a
            class="nav-toggler"
            @click="isMobileWidth"
        >
            <i :class="iconClassName" />
        </a>
        <div
            class="splynx-logo"
            v-html="headerLogo"
        />

        <slot />

        <div class="mobile-head">
            <div class="page-name" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { empty } from '@/utils/functions';

export default {
    name: 'XHeader',
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        ...mapActions('sidebar', [
            'showSidebar',
        ]),
        ...mapActions('menu_store', [
            'toggleMenu',
            'showMobileMenu',
            'handleResize',
            'isMobileWidth',
        ]),
    },
    computed: {
        isShowHelp() {
            return this.admin.role_name === 'super-administrator';
        },
        isShowTimeout() {
            return parseInt(this.admin.timeout) > 0;
        },
        isHaveAdmin() {
            return !empty(this.admin);
        },
        ...mapGetters(['admin', 'logo']),
        ...mapGetters('search', [
            'searchEntities',
        ]),
        ...mapGetters('menu_store', [
            'mobileState',
            'mobileWidth',
            'collapseState',
        ]),
        iconClassName() {
            return this.mobileWidth ? this.iconMobileClassName : this.iconDesktopClassName;
        },
        iconDesktopClassName() {
            return this.collapseState ? 'icon-ic_fluent_chevron_right_24_regular' : 'icon-ic_fluent_chevron_left_24_regular';
        },
        iconMobileClassName() {
            return this.mobileState ? 'icon-ic_fluent_dismiss_24_regular' : 'icon-ic_fluent_navigation_24_regular';
        },
        headerLogo() {
            if (this.logo === '' || this.logo === null) {
                return '<a class="navbar-brand" href="/">Splynx</a>';
            }

            return this.logo;
        },
    },
};
</script>
