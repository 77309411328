const TinyEmitter = require('tiny-emitter');

class Emitter extends TinyEmitter {
    reinitEvent(eventName, callback) {
        if (typeof eventName !== 'string') {
            console.error('Event name is not a string');
            return;
        }

        if (typeof callback !== 'function') {
            console.error(`Callback function for ${eventName} is not a function`);
            return;
        }

        this.off(eventName);
        this.on(eventName, callback);
    }
}

export default Emitter;
