import { empty, emptyObject, isset } from '@/utils/functions';

const RELOAD_DATA_ATTRIBUTE = 'data-table-reload';
export default {
    methods: {
        _setUpEventHandlers(firstRun) {
            if (!this.chunksTable) {
                this.setMassActions();
            }

            $(this.$refs.table).on('click', 'tr.dt-hasChild', (e) => {
                const container = $(e.target).parents('.dt-hasChild').next();
                let items = container.find('.dropdown-item');
                let menu = container.find('.dropdown-menu');
                items.each(function () {
                    menu.append($(this));
                });
            });

            if (!this.chunksTable) {
                this.tableXR();
            }
            this.underHeadSlot(false);
            if (this.initByEvent && firstRun) {
                // initEventCode
                $(`#${this.id}`).off('manual-init');
                $(`#${this.id}`).on('manual-init', () => {
                    this.dt.destroy();
                    this._initTable(true);
                });
            }

            if (this.chunksTable) {
                this.$refs.massActions._setUpMassActions();
            }
        },
        setMassActions() {
            $(`#${this.id}`).on('draw.dt', () => {
                // Mass action handler, mass actions here
                let massButtonsHtml = this.getMassButtonsHtml();
                if (massButtonsHtml !== '') {
                    $(this.wrapperIdSelector).find('.mass-action-buttons').remove();
                    $(this.wrapperIdSelector).find('.dataTables_length').prepend(massButtonsHtml);

                    let tableJsVarName = this.jsVar;
                    let length = 0;
                    if (tableJsVarName !== undefined) {
                        length = $(this.$refs.table).DataTable().columns().checkboxes.selected()[this.targetCheckbox]?.length;
                    }

                    if (length == 0) {
                        let buttonsSelector = $(`#${this.computedWrapperId}`).find('.dataTables_length').find('input, button');
                        buttonsSelector.each(function () {
                            $(this).prop('disabled', true);
                        });
                    }
                } else {
                    $(`#${this.id}`).find('.dt-checkboxes-cell').remove();
                }

                $(this.wrapperIdSelector).find(`[${RELOAD_DATA_ATTRIBUTE}]`).click(() => {
                    let dt = $(`#${this.id}`).DataTable();
                    createReloadDataTableEvent(dt);
                });

                this.$emit('draw-dt', this.dt);
            });
        },
        tableXR() {
            $(this.$refs.table).on('prerXhr.dt', (e, settings) => {
                this.$emit('pre-xhr-dt', {
                    dt: this.dt,
                    e,
                    settings,
                });
            });
            $(this.$refs.table).on('xhr.dt', (e, settings, json) => {
                if (json?.cols) {
                    this.colsForInsideTable = json.cols;
                }

                if (!empty(json?.data) && this.actionsAsObject) {
                    json.data = this.getCellFromObject(json.data);
                }

                if (json?.totals && !this.chunksTable) {
                    this.$emit('totals-data', json.totals);
                }

                let serverSideEnabled;
                if (json == undefined || json.serverSideEnabled === undefined) {
                    serverSideEnabled = false;
                } else {
                    serverSideEnabled = json.serverSideEnabled;
                }

                let realPageLength = empty(this.dataTableOptions.pageLength) ? $(`select[name=${this.id}_length]`).val() : this.dataTableOptions.pageLength;
                if (empty(realPageLength)) {
                    realPageLength = 10;
                }

                if (serverSideEnabled) {
                    this.dt.settings()[0].oFeatures.bServerSide = true;
                } else {
                    $(this.$refs.table).DataTable().column(0).checkboxes.deselectAll();
                    this.dt.settings()[0].oFeatures.bServerSide = false;
                    this.dt.page.len(realPageLength).draw('page');
                }
                this._drawMenuLengthForDataTable(this.id, serverSideEnabled, realPageLength);

                window.activeDT = $(`#${this.id}`).DataTable();

                this.$emit('xhr-dt', {
                    dt: this.dt,
                    e,
                    settings,
                    json,
                });
            });
        },
        _getDtOptions(firstRun) {
            let primary = {
                stateSave: this.stateSave,
                columnDefs: !this.chunksTable ? this.getColumnDefsOptions() : this.computedColumnDefs,
                buttons: this.showDataTableButtons ? this.getDataTableButtons() : [],
            };

            if (!this.initByEvent || !firstRun) {
                primary.ajax = this.ajaxUrl;
            }

            if (!empty(this.columnsConfig) || !emptyObject(this.columnsConfig)) {
                primary.columns = [];
                for (let key in this.columnsConfig) {
                    let value = this.columnsConfig[key];
                    primary.columns.push({ data: value });
                }
            }

            if (typeof this.stateLoadParams === 'function') {
                primary.stateLoadParams = this.stateLoadParams;
            } else {
                // Reset table search
                primary.stateLoadParams = function (settings, data) {
                    if (!this.rememberSearch) {
                        data.search.search = '';
                    }
                };
            }

            if (this.checkbox && !this.chunksTable) {
                primary.order = [[this.targetCheckbox + 1, 'asc']];
                primary.dom = "\"<'row'<'col-md-12'<'table_title table-title'>>><'row'<'col-md-12'<'dt-actions has-mass-actions'<'dt-show-items'l><'dt-search'f><'dt-buttons-wrap'B>r>>>";
                primary.dom += "<'row'<'col-md-12 dt-under-header'>>t<'row justify-content-end under-table'<'info' i>p>\"";
                if (Object.keys(this.buttons).length === 0) {
                    primary.columnDefs.push({
                        targets: this.targetCheckbox,
                        visible: false,
                        searchable: false,
                    });
                }
            }

            let tableOptions = {};
            if (!empty(this.dataTableOptions)) {
                tableOptions = { ...this.dataTableOptions };
            }

            if (tableOptions
                && isset(tableOptions, 'stateSaveParams')
                && typeof tableOptions.stateSaveParams === 'function'
            ) {
                let fn = tableOptions.stateSaveParams;
                tableOptions.stateSaveParams = function (settings, data) {
                    delete data.columns;
                    fn(settings, data);
                };
            } else {
                tableOptions.stateSaveParams = function (settings, data) {
                    delete data.columns;
                };
            }

            for (let k in tableOptions) {
                primary[k] = tableOptions[k];
            }

            let ssrConfig = this.serverSideConfig;
            let ssrEnable = ssrConfig?.enabled_processing || false;

            if (isset(primary, 'serverSide') && !ssrEnable) {
                primary.serverSide = false;
            }

            if (this.initByEvent && firstRun) {
                delete primary.serverSide;
            }

            this.localTableTagOptions['data-server-side'] = isset(primary, 'serverSide') && primary.serverSide && ssrEnable;

            if (!this.chunksTable) {
                if (!empty(this.drawCallback) && typeof this.drawCallback === 'function') {
                    primary.drawCallback = this.drawCallback;
                }
            } else {
                primary.drawCallback = this.drawCallBackPrimary();
            }
            if (!empty(this.createdRow) && typeof this.createdRow === 'function') {
                primary.createdRow = this.createdRow;
            }
            if (!empty(this.fnInitComplete) && typeof this.fnInitComplete === 'function') {
                primary.fnInitComplete = this.fnInitComplete;
            }

            return primary;
        },
    },
};
