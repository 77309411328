import { stateToGetters } from '@/apps/admin/store/helpers';
import { emptyObject, toCamelCase } from '@/utils/functions';
import {
    RESET_CONFIG_FINANCE_TAX_ARCHIVE_MODAL_DATA,
    RESET_CONFIG_FINANCE_TAX_DELETE_MODAL_DATA,
    SET_CONFIG_FINANCE_TAX_ARCHIVE_MODAL_DATA,
    SET_CONFIG_FINANCE_TAX_DELETE_MODAL_DATA,
    SET_CONFIG_FINANCE_TAX_GROUP_ADD_MODAL_DATA,
    SET_CONFIG_FINANCE_TAX_MODAL_DATA,
    SET_CONFIG_FINANCE_TAXES_DATA,
    SET_TAXES,
} from '@/apps/admin/store/mutation-types';
import TaxesService from '@/apps/admin/services/config/finance/taxes';
import formatTaxesForSelect from '@/utils/taxes';

const state = {
    dataTableHeaders: [],
    dtColumnDefs: [],
    columns: {},
    modelName: '',
    statuses: {},
    taxModal: {},
    taxArchiveModalData: {
        error: false, relations: [], modules: [], usedInConfig: false, relatedGroups: [],
    },
    taxDeleteModalData: {
        error: false, relations: [], modules: [], usedInConfig: false, relatedGroups: [],
    },
    taxGroupModalData: {},
    taxes: {},
};

const TYPE_SINGLE = 'single';
const TYPE_GROUP = 'group';

const getters = {
    ...stateToGetters(state),
    getRawTaxes(state) {
        return state.taxes;
    },
    getActiveTaxes(state) {
        const { taxes } = state;
        const taxesArray = Object.keys(taxes).map((key) => taxes[key]);

        let result = {};

        // Filter all active single taxes
        let singleTaxes = taxesArray.filter((tax) => !tax.archived && tax.type === TYPE_SINGLE);
        if (singleTaxes.length) {
            result.taxes = singleTaxes;
        }

        // Filter all active tax groups
        let taxGroups = taxesArray.filter((tax) => !tax.archived && tax.type === TYPE_GROUP);
        if (taxGroups.length) {
            result.taxGroups = taxGroups;
        }

        return result;
    },
    getActiveTaxesWithCurrentTax: (state, getters) => (id) => {
        id = parseInt(id);
        if (id === '') {
            return formatTaxesForSelect(getters.getActiveTaxes);
        }

        let taxes = getters.getRawTaxes;

        if (!isset(taxes, [id])) {
            show_error(window.xApp.$splang.t('finance', 'Tax or Group with ID {tax_id} not found', { tax_id: id }), 4);
            return formatTaxesForSelect(getters.getActiveTaxes);
        }

        let currentTax = taxes[id];

        if (!currentTax.archived) {
            return formatTaxesForSelect(getters.getActiveTaxes);
        }

        // Check archived tax type and decide where it will be placed
        const activeTaxes = getters.getActiveTaxes;

        if (currentTax.type === TYPE_SINGLE) {
            let resultWithCurrent = {};
            if (Object.prototype.hasOwnProperty.call(activeTaxes, 'taxes')) {
                resultWithCurrent = [currentTax, ...activeTaxes.taxes];
            } else {
                resultWithCurrent = [currentTax];
            }
            return formatTaxesForSelect({ ...activeTaxes, taxes: resultWithCurrent });
        }

        if (Object.prototype.hasOwnProperty.call(activeTaxes, 'taxGroups')) {
            return formatTaxesForSelect({
                ...activeTaxes, taxGroups: [currentTax, ...activeTaxes.taxGroups],
            });
        }

        return formatTaxesForSelect({
            ...activeTaxes, taxGroups: [currentTax],
        });
    },
    getActiveTaxWithoutCurrent: (state, getters) => (id) => {
        id = parseInt(id);

        let activeTaxes = getters.getActiveTaxes;

        let taxes = {};
        // Remove tax from list
        if (Object.prototype.hasOwnProperty.call(activeTaxes, 'taxes')) {
            taxes.taxes = activeTaxes.taxes.filter((tax) => tax.id !== id);
        }
        if (Object.prototype.hasOwnProperty.call(activeTaxes, 'taxGroups')) {
            taxes.taxGroups = activeTaxes.taxGroups.filter((group) => group.id !== id);
        }

        return formatTaxesForSelect(taxes);
    },
};

const actions = {
    loadPageTaxesData({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/admin/config/finance/taxes--get-page-data',
                success: (response) => {
                    commit(SET_CONFIG_FINANCE_TAXES_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    loadTaxModalData({ commit }, data) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: data.url,
                success: (response) => {
                    commit(SET_CONFIG_FINANCE_TAX_MODAL_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    loadTaxArchiveModalData({ commit }, data) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: data.url,
                success: (response) => {
                    commit(SET_CONFIG_FINANCE_TAX_ARCHIVE_MODAL_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    async loadTaxDeleteModalData({ commit }, data) {
        const response = await TaxesService.loadTaxDeleteModalData(data.url);
        commit(SET_CONFIG_FINANCE_TAX_DELETE_MODAL_DATA, response);
        return true;
    },
    resetTaxArchiveModalData({ commit }) {
        commit(RESET_CONFIG_FINANCE_TAX_ARCHIVE_MODAL_DATA);
    },
    resetTaxDeleteModalData({ commit }) {
        commit(RESET_CONFIG_FINANCE_TAX_DELETE_MODAL_DATA);
    },
    loadTaxGroupModalData({ commit }, data) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: data.url,
                success: (response) => {
                    commit(SET_CONFIG_FINANCE_TAX_GROUP_ADD_MODAL_DATA, response);
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    async loadTaxes({ state, commit }) {
        if (!emptyObject(state.taxes)) {
            return;
        }
        const response = await TaxesService.loadData();
        commit(SET_TAXES, response);
    },
    async reloadTaxes({ commit, dispatch }) {
        commit(SET_TAXES, {});
        dispatch('loadTaxes');
    },
};

const mutations = {
    [SET_CONFIG_FINANCE_TAXES_DATA](state, data) {
        for (let k in data) {
            state[toCamelCase(k)] = data[k];
        }
    },
    [SET_CONFIG_FINANCE_TAX_MODAL_DATA](state, data) {
        for (let k in data) {
            state.taxModal[toCamelCase(k)] = data[k];
        }
    },
    [SET_CONFIG_FINANCE_TAX_ARCHIVE_MODAL_DATA](state, data) {
        for (let k in data) {
            state.taxArchiveModalData[toCamelCase(k)] = data[k];
        }
    },
    [SET_CONFIG_FINANCE_TAX_DELETE_MODAL_DATA](state, data) {
        for (let k in data) {
            state.taxDeleteModalData[toCamelCase(k)] = data[k];
        }
    },
    [RESET_CONFIG_FINANCE_TAX_ARCHIVE_MODAL_DATA](state) {
        state.taxArchiveModalData = {
            error: false, hasRelations: false,
        };
    },
    [RESET_CONFIG_FINANCE_TAX_DELETE_MODAL_DATA](state) {
        state.taxDeleteModalData = {};
    },
    [SET_CONFIG_FINANCE_TAX_GROUP_ADD_MODAL_DATA](state, data) {
        for (let k in data) {
            state.taxGroupModalData[toCamelCase(k)] = data[k];
        }
    },
    [SET_TAXES](state, data) {
        state.taxes = data;
    },
};

export default {
    namespaced: true, state, actions, mutations, getters,
};
