<template>
    <div class="form-check">
        <div>
            <input
                :id="id"
                type="radio"
                v-bind="attributes"
                :checked="isChecked"
                :value="value"
                :name="name"
                @input="inputHandler"
            >
        </div>
        <label
            v-if="title !== ''"
            class="form-check-label"
            :for="id"
        >{{ title }}</label>
    </div>
</template>

<script>
import { isset } from '@/utils/functions';
import SplInputMixin from '../input-mixin';

export default {
    name: 'XInputRadio',
    mixins: [SplInputMixin],
    model: {
        prop: 'modelValue',
        event: 'change',
    },
    props: {
        options: {
            type: Object,
            default: () => ({}),
        },
        id: String,
        value: [String, Number],
        title: {
            type: String,
            default: '',
        },
        modelValue: {
            type: [String, Boolean, Number],
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
    },
    computed: {
        attributes() {
            let attributes = this.options;
            if (!isset(attributes, ['class'])) {
                attributes.class = '';
            }

            attributes.class = this.addClass(attributes.class, ['form-check-input']);

            return attributes;
        },
        isChecked() {
            return this.modelValue === this.value;
        },
    },
    methods: {
        inputHandler(event) {
            this.$emit('change', event.target.value);
        },
    },
};
</script>
