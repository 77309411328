let formats = [];

if (process.env.NODE_ENV !== 'test') {
    formats = [
        /* eslint-disable global-require */
        { format: 'woff2', url: require('@/fonts/Inter-Password.woff2') },
        { format: 'woff', url: require('@/fonts/Inter-Password.woff') },
        { format: 'truetype', url: require('@/fonts/Inter-Password.ttf') },
    ];
}

class MaskedInput {
    static MASKED = 'masked';

    static fontPromises = [];

    static FORMATS = formats;

    constructor(
        input,
        mask = false,
        onChange = () => {},
        onMaskChange = () => {},
        identify = true,
    ) {
        this.input = $(input);
        // Set default password mask
        this.input.attr('type', 'password');
        this.onChangeHandler = () => onChange(this.input.val(), this);
        this.onChangeMaskState = () => onMaskChange(this.mask, this);
        if (identify) {
            this.identify();
        }
        this.setMaskState(mask);
        this.input.on('input', () => {
            this.onChangeHandler();
        });
        this.input.on('copy', (event) => {
            if (this.mask) {
                event.preventDefault();
            }
        });
        this.input.on('cut', (event) => {
            if (this.mask) {
                event.preventDefault();
                const startPos = event.target.selectionStart;
                const endPos = event.target.selectionEnd;
                event.target.value = event.target.value.substring(0, startPos) + event.target.value.substring(endPos);
                event.target.setSelectionRange(startPos, startPos);
            }
        });
        this.checkFont();
        Promise.all(MaskedInput.fontPromises).then(() => {
            this.checkFont();
        }).catch(() => {});
    }

    checkFont() {
        if (document.fonts.check('1em "Password"')) {
            this.input.attr('type', 'text');
        } else {
            return false;
        }
    }

    static initAsterisksFont() {
        MaskedInput.fontPromises = MaskedInput.FORMATS.map((fontFormat) => {
            const fontFace = new FontFace('Password', `url(${fontFormat.url})`, {
                style: 'normal',
                weight: '400',
                format: fontFormat.format,
            });
            document.fonts.add(fontFace);
            return fontFace.load();
        });
    }

    identify() {
        const input = $('<div>').uniqueId();
        if (!window.maskedInputs) {
            window.maskedInputs = {};
        }
        window.maskedInputs[input.attr('id')] = this;
        this.input.attr('data-input-id', input.attr('id'));
    }

    setValue(value) {
        this.input.val(value).change();
        this.onChangeHandler();
    }

    toggleMaskState() {
        this.setMaskState(!this.mask);
    }

    setMaskState(isMaskValue) {
        this.mask = isMaskValue;
        if (isMaskValue) {
            this.input.addClass(this.constructor.MASKED);
        } else {
            this.input.removeClass(this.constructor.MASKED);
        }
        this.onChangeMaskState();
    }
}

if (process.env.NODE_ENV === 'test') {
    MaskedInput.prototype.checkFont = () => {};
    MaskedInput.FORMATS = [];
}

MaskedInput.initAsterisksFont();

export default MaskedInput;
