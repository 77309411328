$.fn.customColorPicker = function (configs) {
    let self = $(this).parent();

    self.colorpicker(configs).on('changeColor', function () {
        $(this).parent().find('.input-group-addon').css('background-color', $(this).colorpicker('getValue', '#ffffff'));
    });

    self.find('input').click(() => {
        self.find('.input-group-addon').click();
    });

    return self;
};
