import { supports_html5_storage, empty } from '@/utils/functions';
import { ADMIN_PART, PORTAL_PART } from '@/constants';

const applicationPart = !empty(window.spl_config?.admin) ? ADMIN_PART : PORTAL_PART;
const localStorage = supports_html5_storage() ? window.localStorage.getItem(`${applicationPart}_menu_state`) : 'big';

export default {
    collapseState: false,
    mobileState: false,
    mobileWidth: false,
    window: {
        width: 0,
    },
    applicationPart,
    localStorage,
};
