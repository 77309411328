<template>
    <div class="input-wrap">
        <select
            ref="input"
            v-bind="attributes"
        />
    </div>
</template>

<script>
import XSelect from '@/components/common/inputs/XSelect';
import { isset } from '@/utils/functions';

export default {
    name: 'XSelectMultipleTags',
    extends: XSelect,
    inheritAttrs: false,
    props: {
        arrow: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        attributes() {
            let op = { ...this.$attrs, ...this.options };
            delete op.options;
            if (isset(op, 'class')) {
                op.class += ' select2';
            } else {
                op.class = 'select2';
            }
            op.multiple = 'multiple';
            return op;
        },
    },
    methods: {
        reInit() {
            let element = $(this.$refs.input);
            let select = null;

            if (element.data('select2')) {
                element.select2('destroy');
                element.find('option,optgroup').remove();
            }

            let config = {};

            if (this.isAsync) {
                let defaultConfig = this.getDefaultAsyncConfig();
                $.extend(true, config, defaultConfig, this.config);
                if (typeof config.ajax.data === 'string') {
                    config.ajax.data = Function('params', config.ajax.data);
                }
                if (typeof config.ajax.processResults === 'string') {
                    config.ajax.processResults = Function('data', config.ajax.processResults);
                }
            } else {
                config = {
                    data: this.getPreparedOptions(),
                    tags: this.tags,
                    allowClear: this.allowClear,
                    placeholder: !this.options.disabled ? this.options.placeholder : '',
                    arrow: this.arrow,
                };
                $.extend(true, config, this.config);
            }
            // render multiple tags
            let labelsForRender = null;
            if (isset(this, ['options', 'tagsForRender'])) {
                labelsForRender = this.options.tagsForRender;
            }
            const formatStateResult = function (state) {
                if (!state) {
                    return;
                }
                if (!state.id) {
                    return state.text;
                }
                let color = null;
                let textColor = null;
                if (labelsForRender && labelsForRender[state.id]) {
                    color = labelsForRender[state.id].color;
                    textColor = labelsForRender[state.id].textColor;
                }
                let el = $(`<label>${state.text}</label>`);
                el.addClass('badge');
                if (color) {
                    el.css('background-color', color);
                    el.css('color', textColor);
                } else {
                    el.addClass('badge-success');
                }
                return el;
            };

            const formatStateSelection = function (state, tag) {
                if (!state) {
                    return;
                }
                if (!state.id) {
                    return state.text;
                }
                let color = null;
                let textColor = null;
                if (labelsForRender && labelsForRender[state.id]) {
                    color = labelsForRender[state.id].color;
                    textColor = labelsForRender[state.id].textColor;
                }
                if (color) {
                    $(tag).css('background-color', color);
                    $(tag).css('color', textColor);
                    $(tag).css('border', 'none');
                }

                return state.text;
            };

            config.templateResult = formatStateResult;
            config.templateSelection = formatStateSelection;
            select = element.select2(config);
            element.data('select2').$container.addClass('multiple-tags');
            element.data('select2').$dropdown.addClass('multiple-tags-dropdown');
            select.val(this.value).trigger('change');

            select.change(this.inputHandler);

            if (this.fixResizeMultiple) {
                select.data('select2').on('results:message', function () {
                    this.dropdown._resizeDropdown();
                    this.dropdown._positionDropdown();
                });
            }

            // @see https://github.com/select2/select2/issues/5993
            select.on('select2:open', this.onOpenSelect);

            if (this.options.disabledSelect) {
                element.data('select2').selection.$selection.addClass('disabled-select');
                select.on('select2:opening', (e) => {
                    element.data('select2').selection.$search.prop('disabled', 1);
                    e.preventDefault();
                });
            }
        },
    },
};
</script>
