/*
This script will be prepare ajaxForm send data and remove from it not changed values
 */
(function () {
    let values = [];
    let tempValues = [];
    let form = null;
    let multipleValues = [];
    let allClassNames = [];

    /**
     * Remove item from array for send
     * @param i - index of data item
     */
    let removeFromSending = function (i) {
        let index = values.indexOf(tempValues[i]);
        if (index !== -1) {
            values.splice(index, 1);
        }
    };

    /**
     * Fixed boolean items with double input
     * @param items - data items
     */
    let fixedBooleanValues = function (items) {
        let forRemove = [];
        for (let i = 0; i < items.length; i++) {
            if (items[i].type == 'hidden' && items[i + 1] !== undefined && items[i + 1].type == 'checkbox' && items[i].name == items[i + 1].name) {
                forRemove.push(items[i]);
            }
        }

        forRemove.forEach((item) => {
            removeFromSending(values.indexOf(item));
            let removeIndex = tempValues.indexOf(item);
            if (removeIndex !== -1) {
                tempValues.splice(removeIndex, 1);
            }
        });
    };

    /**
     * Get all used class names
     * @param items - data items
     */
    let getClassNames = function (items) {
        let result = items.map((item) => {
            // selectItem<ModelName> is not used
            if (item.name.search(/selectItem\w+\[/) !== -1 || item.name.search(/selectAll\w+\[/) !== -1) {
                return '';
            }

            let search = item.name.match(/^\w+\[/);
            if (search == null) {
                return '';
            }

            // Trick for fix bug with save only changed file in form
            if (item.type === 'file') {
                return '';
            }

            // Cut last [ symbol
            search = search[0];
            return search.substring(0, search.length - 1);
        });

        // Remove not uniue and empty values
        return result.filter((value, index, arr) => {
            if (value === '') {
                return false;
            }

            return arr.indexOf(value) === index;
        });
    };

    /**
     * Grouped multiple values to array
     * @returns {Array}
     */
    let getMultipleValues = function () {
        let result = [];

        tempValues.forEach((item) => {
            if (item.name.match(/\[\]$/) !== null) {
                if (result[item.name] == undefined) {
                    result[item.name] = [item.value];
                } else {
                    result[item.name].push(item.value);
                }
            }
        });

        return result;
    };

    /**
     * Remove not changed values from data items
     */
    let removeNotChangedValues = function () {
        for (let i = 0; i < tempValues.length; i++) {
            if (tempValues[i].name == '_csrf_token') {
                continue;
            }

            if (tempValues[i].name.search(/selectItem\w+\[/) !== -1 || tempValues[i].name.search(/selectAll\w+\[/) !== -1) {
                removeFromSending(i);
                continue;
            }

            let htmlElement = $(form).find(`[name="${tempValues[i].name}"]`);
            let elementNumber = 0;

            if (htmlElement.length == 0) {
                continue;
            } else if (htmlElement.length > 1) {
                elementNumber = 1;
            }

            htmlElement = htmlElement[elementNumber];

            // After save file input that input is cleared (new empty value != saved value). So we need miss this input from sending
            // See https://jira.splynx.com/browse/SPL-1872
            if ($(htmlElement).attr('type') == 'file' && $(htmlElement).val() == '') {
                removeFromSending(i);
                continue;
            }

            if ($(htmlElement).attr('force-send') == undefined) {
                let selectName = `${tempValues[i].name}[]`;
                let selectItem = $(htmlElement).next(`select[name="${selectName}"]`);

                // For empty not changed and not forced multiple select
                if (selectItem.length !== 0
                    && multipleValues[selectName] === undefined
                    && ($(selectItem).val() == null || ($(selectItem).val() instanceof Array && $(selectItem).val().length == 0))
                    && $(selectItem).attr('original-value') == ''
                    && $(selectItem).attr('force-send') != '1') {
                    removeFromSending(i);
                }
                continue;
            }

            if ($(htmlElement).attr('force-send') == '1') {
                continue;
            }

            let isRemoveBaseValue = false;

            if ($(htmlElement).val() instanceof Array) {
                if ($(htmlElement).attr('original-value') == multipleValues[tempValues[i].name].join(',')) {
                    removeFromSending(i);

                    if (!isRemoveBaseValue) {
                        for (let j = 0; j < tempValues.length; j++) {
                            if (tempValues[j].name == tempValues[i].name.substring(0, tempValues[i].name.length - 2)) {
                                removeFromSending(j);
                                isRemoveBaseValue = true;
                                break;
                            }
                        }
                    }

                    continue;
                }
            } else {
                let rawOriginalValue = $(htmlElement).attr('original-value');
                let rawNewValue = tempValues[i].value;

                if ($.isNumeric(rawOriginalValue) && $.isNumeric(rawNewValue)) {
                    // It is a number
                    if (rawOriginalValue === rawNewValue) {
                        removeFromSending(i);
                        continue;
                    }
                } else {
                    // It is not a number
                    // Decode value
                    rawOriginalValue = window.decodeHtml(rawOriginalValue);
                    if (rawOriginalValue == rawNewValue) {
                        removeFromSending(i);
                        continue;
                    }
                }
            }
        }
    };

    /**
     * Add empty items with class names to sending data
     * @param existingClassNames
     */
    let addClassNamesToSending = function (existingClassNames) {
    // Add empty values only for not sengind classes (array_diff)
        let needAdded = $(allClassNames).not(existingClassNames).get();

        // Add empty class value for successful passing validation in server,
        // When we not have elements for sending
        needAdded.forEach((item) => {
            values.push({
                name: `${item}[]`,
                value: '',
                type: 'hidden',
                required: false,
            });
        });
    };

    /**
     * Prepare sending values
     * @param valuesForChange
     * @param formElement
     */
    let processSendingValues = function (valuesForChange, formElement) {
        values = valuesForChange;
        form = formElement;
        tempValues = values.slice();

        fixedBooleanValues(tempValues);

        allClassNames = getClassNames(tempValues);

        multipleValues = getMultipleValues();

        removeNotChangedValues();

        addClassNamesToSending(getClassNames(values));
    };

    /**
     * Run custom handler before call beforeSubmit function
     * @param f
     * @returns {Function}
     */
    let beforeSubmitDecorator = function (f) {
        return function () {
            processSendingValues(arguments[0], arguments[1]);
            return f.apply(this, arguments);
        };
    };

    /**
     * If model successfully saved then we need update original values
     * @param response
     */
    let processUpdatedValues = function (response, status, form) {
        if (response.result == true) {
            let multipleCollectValues = {};

            for (let i = 0; i < values.length; i++) {
                if (values[i].name == '_csrf_token') {
                    // Csrf token will be send in any times
                    continue;
                }

                // Find element in current form
                let element = $(form).find(`[name="${values[i].name}"]`);

                if (element.length == 0) {
                    continue;
                } else if (element.length == 1) {
                    // For multiple values
                    element = element[0];
                    if ($(element).next('.input-wrap').find('select').attr('multiple') == 'multiple') {
                        // Multiple widget have hidden input, but value will be save in select input
                        // For more information see html structure of multiple widget
                        $(element).next('.input-wrap').find('select').attr('original-value', values[i].value);
                        continue;
                    }
                } else {
                    // Select main element for boolean widgets
                    element = element[1];
                }

                if ($(element).attr('multiple') === 'multiple') {
                    // For `multiple` widgets we need collect values together because these values splitted in `values` array
                    if (multipleCollectValues[values[i].name] == undefined) {
                        multipleCollectValues[values[i].name] = [];
                    }

                    multipleCollectValues[values[i].name].push(values[i].value);
                } else {
                    $(element).attr('original-value', values[i].value);
                }
            }

            // Apply updated original values for `multiple` widgets
            for (let multipleItem in multipleCollectValues) {
                $(form).find(`[name="${multipleItem}"]`).attr('original-value', multipleCollectValues[multipleItem].join(','));
            }
        }
    };

    /**
     * Run custom handler before call success function
     * @param f
     * @param form
     * @returns {Function}
     */
    let successDecorator = function (f, form) {
        return function () {
            processUpdatedValues(arguments[0], arguments[1], form);
            return f.apply(this, arguments);
        };
    };

    /**
     * Run custom function before call ajaxForm function
     * @param f
     * @returns {Function}
     */
    let ajaxFormDecorator = function (f) {
        return function () {
            if (!arguments[0].forceSend) {
                if (arguments[0].beforeSubmit == undefined) {
                    // If beforeSubmit is not set
                    arguments[0].beforeSubmit = beforeSubmitDecorator(() => {
                    });
                } else {
                    arguments[0].beforeSubmit = beforeSubmitDecorator(arguments[0].beforeSubmit);
                }

                if (arguments[0].success == undefined) {
                    arguments[0].success = successDecorator(() => {
                    }, this);
                } else {
                    arguments[0].success = successDecorator(arguments[0].success, this);
                }
            }

            return f.apply(this, arguments);
        };
    };

    // Create custom decorator
    $.fn.ajaxForm = ajaxFormDecorator($.fn.ajaxForm);
}());
