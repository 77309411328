import { emptyObject, interceptError } from '@/utils/functions';

const XErrorPlugin = {};

XErrorPlugin.install = (Vue) => {
    Vue.prototype.setErrorState = function (newState) {
        const state = newState ?? false;
        const handle = () => {
            window.closeAllVueDialogs();
            window.closeAllDialogs();
            this.$store.dispatch('setErrorState', newState);
        };
        if (state && state.show_error_page_view) {
            handle();
        }
        if (state && state.html && state.html.show_error_page_view) {
            handle();
        }
    };
    Vue.prototype.initAjaxInterceptors = function () {
        if (window.error_information && !emptyObject(window.error_information)) {
            this.$store.dispatch('setErrorState', window.error_information);
            return false;
        }
        // AJAX errors global processing
        $(document).ajaxError((event, xhr) => {
            interceptError(
                xhr,
                (response) => {
                    this.setErrorState(response);
                },
            );

            if (xhr.status === 401) {
                window.address_reload(false, true);
                return;
            }

            if (xhr.status === 403) {
                this.setErrorState({ code: 403, error: xhr.responseJSON.error, show_error_page_view: true });
                return;
            }

            if (xhr.status === 0) {
                return;
            }

            if (xhr.status === 200) {
                return;
            }

            if (!window.showAjaxErrors) {
                return;
            }

            let result = xhr.responseJSON;
            const showPage = result && result.html ? result.html.show_error_page_view : false;
            if (result && result.error && xhr.status !== 404 && !showPage) {
                let message = `Server error: ${result.error}`;
                show_error(message, 5);
            }
        });

        $(document).ready(() => {
            $.ajaxSetup({
                beforeSend(jqXHR) {
                    jqXHR.setRequestHeader('Accept', 'application/json');
                },
            });
        });

        if (process.env.NODE_ENV === 'test') {
            Vue.prototype.initAjaxInterceptors = jest.fn();
        }
    };
};

export default XErrorPlugin;
