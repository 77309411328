<template>
    <div class="toasters-wrap">
        <transition-group name="fade">
            <div
                v-for="(item, index) in limitedNotifications"
                :key="`key-${index}`"
                class="toaster"
                data-test-selector="message"
            >
                <div
                    class="toaster-content"
                    @click="remove(item.id)"
                >
                    <span
                        v-if="item.type !== 'text'"
                        :class="{
                            'icon-wrap': true,
                            'color-danger': item.type === 'error',
                            'color-warning': item.type === 'warning',
                            'color-success': item.type === 'success',
                            'color-info': item.type === 'info',
                        }"
                    >
                        <i
                            :class="{
                                'icon-ic_fluent_error_circle_24_regular': item.type === 'error',
                                'icon-ic_fluent_warning_24_regular': item.type === 'warning',
                                'icon-ic_fluent_checkmark_circle_24_regular': item.type === 'success',
                                'icon-ic_fluent_info_12_regular': item.type === 'info',
                            }"
                        />
                    </span>
                    <div v-html="item.message" />
                </div>
                <div
                    v-if="item.action_button"
                    class="toaster-buttons"
                >
                    <a
                        href="javascript:void(0)"
                        class="toaster-button"
                        @click="remove(item.id)"
                        v-text="item.close_button ? item.close_button.name : t('common', 'Skip')"
                    />
                    <a
                        href="javascript:void(0)"
                        class="toaster-button-primary"
                        @click="runAction(item)"
                        v-text="item.action_button.name ? item.action_button.name : t('common', 'View')"
                    />
                </div>
                <div
                    v-else
                    class="toaster-buttons"
                >
                    <a
                        href="javascript:void(0)"
                        class="toaster-button-icon"
                        @click="remove(item.id)"
                    >
                        <span class="icon-wrap"><i class="icon-ic_fluent_dismiss_24_regular" /></span>
                    </a>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { PUSH_NOTIFICATION, REMOVE_NOTIFICATION } from '@/components/base/XGlobalNotification/store/mutation-types';

const GLOBAL_NOTIFICATION_SOCKET_EVENT = 'spl_global_notification_add_event';

export default {
    name: 'XGlobalNotifications',
    mounted() {
        if (typeof remove_socket_listener === 'function') {
            remove_socket_listener(GLOBAL_NOTIFICATION_SOCKET_EVENT, this.add);
        }
        if (typeof add_socket_listener === 'function') {
            add_socket_listener(GLOBAL_NOTIFICATION_SOCKET_EVENT, this.add);
        }
    },
    computed: {
        ...mapGetters('global_notifications_store', [
            'getNotifications',
            'interceptors',
        ]),
        limitedNotifications() {
            return this.getNotifications.filter((noty, index) => index < 5);
        },
    },
    methods: {
        ...mapMutations('global_notifications_store', [PUSH_NOTIFICATION, REMOVE_NOTIFICATION]),
        ...mapActions('global_notifications_store', ['showNotify']),
        ...mapActions('tabs_store', ['switchTab', 'clearTab']),
        add(data) {
            const deny = this.interceptors[data.type] && !this.interceptors[data.type].interceptor(data);
            if (!deny) {
                this.showNotify(data);
            }
        },
        remove(id) {
            this.REMOVE_NOTIFICATION(id);
        },
        runAction(item) {
            if (typeof item.action_button.action === 'function') {
                item.action_button.action();
            } else if (typeof item.action_button.action === 'string') {
                switch_page(item.action_button.action);
            } else {
                switch_page(item.action_button.redirect_url, false, true);
                this.getHash();
            }
            this.remove(item.id);
        },
        getHash() {
            if (this.$route.hash) {
                let { hash } = this.$route;
                this.switchTab(hash.substring(1));

                this.$nextTick(() => {
                    this.clearTab();
                });
            }
        },
    },
};
</script>
