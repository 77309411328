import { isset } from '@/utils/functions';

export function warn(msg, err) {
    if (typeof console !== 'undefined') {
        console.warn(`[splang] ${msg}`);
    }

    if (err) {
        console.warn(err.stack);
    }
}

export function isFunction(el) {
    return typeof el === 'function';
}

export { isset };
