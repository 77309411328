<script>
import SplInputMixin from '../input-mixin';
import XTextField from '../XTextField';

export default {
    name: 'XEmailInput',
    components: {
        XTextField,
    },
    mixins: [SplInputMixin],
    props: {
    // For v-model
        value: [String, Number],
        // This is custom attributes for input, like class or style etc.
        options: Object,
        type: {
            type: String,
            default: 'email', // or phone
        },
        linkAddon: {
            type: String,
            default: '',
        },
    },
    computed: {
        valueString() {
            if (this.value == null) {
                return '';
            }

            let values = $.map(this.value.split(','), $.trim);
            let links = [];
            let self = this;
            $.each(values, (i, value) => {
                if (value === '') {
                    return;
                }

                let { linkAddon } = self;
                if (linkAddon) {
                    linkAddon = $(linkAddon).attr('data-value', value).addClass('link-addon').get(0).outerHTML;
                }

                let link = $('<a/>').attr('href', `${self.prefixForHref}:${value}`).attr('title', self.linkTitle).text(value)
                    .get(0).outerHTML;
                let linkWrapper = $('<span />').append(linkAddon).append(link).get(0).outerHTML;
                links.push($('<li/>', { class: 'popover-dropdown-item' }).append(linkWrapper).get(0).outerHTML);
            });

            let valuesString = `<ul class="popover-dropdown">${links.join('')}</ul>`;
            return valuesString;
        },
        prefixForHref() {
            return this.isEmail() ? 'mailto' : 'tel';
        },
        linkTitle() {
            return this.isEmail() ? window.xApp.$splang.t('common', 'Open in mail app') : '';
        },
        popoverTitle() {
            return this.isEmail() ? window.xApp.$splang.t('common', 'Emails') : window.xApp.$splang.t('common', 'Phones');
        },
        iconClass() {
            return this.isEmail() ? 'icon-ic_fluent_mail_24_regular' : 'icon-ic_fluent_call_24_regular';
        },
        popoverButtonId() {
            return `${this.options.name.replace('[', '_').replace(']', '_')}popover`;
        },
    },
    watch: {
        value() {
            this.initPopover();
        },
    },
    mounted() {
        $(document).on('switch_page', function () {
            $(`#${this.popoverButtonId}`).webuiPopover('hide');
        });
        this.initPopover();
    },
    methods: {
        initPopover() {
            let popover = $(`#${this.popoverButtonId}`);

            popover.webuiPopover('destroy');
            if (this.valueString) {
                let container = popover.parent().parent().get(0);
                popover.attr('style', 'cursor: pointer;')
                    .attr('title', this.popoverTitle)
                    .webuiPopover({
                        title: this.popoverTitle,
                        container,
                        content: this.valueString,
                        placement: 'left',
                    });
            } else {
                popover.attr('style', 'cursor: unset;').attr('title', '');
            }
        },
        isEmail() {
            return this.type === 'email' || this.type === 'text';
        },
    },
    render(createElement) {
        let self = this;

        let button = {
            id: this.popoverButtonId,
            class: this.iconClass,
            options: {
                class: 'btn btn-outline-secondary btn-icon',
                style: 'cursor: unset;',
            },
        };

        return createElement('XTextField', {
            props: {
                value: this.value,
                options: this.options,
                button,
            },
            ref: 'input',
            on: {
                input: (value) => {
                    self.$emit('input', value);
                },
            },
        });
    },
};
</script>
