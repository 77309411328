<script>
import { empty, isset } from '@/utils/functions';
import XInputDateRange from '../XInputDateRange';
import XInputDatetime from '../XInputDatetime';
import XInputDate from '../XInputDate';
import XInputHidden from '../XInputHidden';
import XMultipleSelect from '../XMultipleSelect';
import XMultipleSelectRelation from '../XMultipleSelectRelation';
import XInputNumber from '../XInputNumber';
import XInputPassword from '../XInputPassword';
import XPasswordWindow from '../XPasswordWindow';
import XSelect from '../XSelect';
import XSelectRelation from '../XSelectRelation';
import XSelectMultipleTags from '../XSelectMultipleTags';
import XTextField from '../XTextField';
import XInputFile from '../XInputFile';
import XTextarea from '../XTextarea';
import XInputCheckbox from '../XInputCheckbox';
import XInputRadio from '../XInputRadio';
import XAddonButton from '../XAddonButton';
import XAddonText from '../XAddonText';
import XAddonTextAndButton from '../XAddonTextAndButton';
import XInputIp from '../XInputIp';
import XInputIpV6 from '../XInputIpV6';
import XEmailPhoneInput from '../XEmailPhoneInput';
import XFroala from '../XFroala';
import XInputEncrypt from '../XInputEncrypt/XInputEncrypt';
import XSelectSearch from '../XSelectSearch';

const INPUT_DATE_RANGE_PICKER = 'date_range';
const INPUT_DATE_RANGE_PICKER_ALIAS1 = 'date-range';
const INPUT_DATE_RANGE_PICKER_ALIAS2 = 'daterange';
const INPUT_DATETIME_PICKER = 'datetime';
const INPUT_DATE_PICKER = 'date';
const INPUT_SELECT = 'select';
const INPUT_SELECT_SEARCH = 'select-search';
const INPUT_MULTIPLE_TAGS = 'multipleTags';
const INPUT_SELECT_RELATION = 'selectRelation';
const INPUT_SELECT_RELATION_ALIAS = 'select-relation';
const INPUT_MULTIPLE_SELECT_ALIAS = 'multiple-select';
const INPUT_MULTIPLE_SELECT = 'multipleSelect';
const INPUT_MULTIPLE_SELECT_RELATION = 'multipleSelectRelation';
const INPUT_MULTIPLE_SELECT_RELATION_ALIAS = 'multiple-select-relation';
const INPUT_TEXT_AREA = 'textarea';
const INPUT_FROALA = 'froala';
const INPUT_BOOLEAN = 'boolean';
const INPUT_CHECKBOX = 'checkbox';
const INPUT_RADIO = 'radio';
const INPUT_TEXT = 'text';
const INPUT_DECIMAL = 'decimal';
const INPUT_STRING = 'string';
const INPUT_NUMBER = 'number';
const INPUT_FLOAT = 'float';
const INPUT_INTEGER = 'integer';
const INPUT_HIDDEN = 'hidden';
const INPUT_ENCRYPT = 'encrypt';
const INPUT_PASSWORD = 'password';
const INPUT_PASSWORD_WINDOW = 'password_window';
const INPUT_FILE = 'file';

const ADDON_BUTTON = 'addon_button';
const ADDON_TEXT = 'addon_text';
const ADDON_TEXT_AND_BUTTON = 'addon_text_and_button';
const INPUT_IP = 'ip';
const INPUT_IP_V6 = 'ipv6';
const INPUT_EMAIL = 'email';
const INPUT_PHONE = 'phone';

const XComplexInput = {
    name: 'XComplexInput',
    components: {
        XInputDateRange,
        XInputDatetime,
        XInputDate,
        XInputHidden,
        XMultipleSelect,
        XMultipleSelectRelation,
        XInputNumber,
        XInputPassword,
        XPasswordWindow,
        XInputEncrypt,
        XSelect,
        XSelectRelation,
        XSelectMultipleTags,
        XSelectSearch,
        XTextField,
        XTextarea,
        XFroala,
        XInputCheckbox,
        XInputRadio,
        XInputFile,
        XAddonButton,
        XAddonText,
        XAddonTextAndButton,
        XInputIp,
        XInputIpV6,
        XEmailPhoneInput,
    },
    props: {
        type: String,
        params: Object,
        nativeEventHandlers: {
            type: Object,
            default() {
                return {};
            },
        },
        eventHandlers: {
            type: Object,
            default() {
                return {};
            },
        },
        value: {
            type: [String, Number, Array, Object, Boolean, FileList, File],
            default: '',
        },
        meta: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            show: true,
        };
    },
    computed: {
        componentName() {
            let typesToComponents = {};
            typesToComponents[INPUT_TEXT] = 'XTextField';
            typesToComponents[INPUT_STRING] = 'XTextField';
            typesToComponents[INPUT_NUMBER] = 'XInputNumber';
            typesToComponents[INPUT_FLOAT] = 'XInputNumber';
            typesToComponents[INPUT_INTEGER] = 'XInputNumber';
            typesToComponents[INPUT_DECIMAL] = 'XInputNumber';
            typesToComponents[INPUT_HIDDEN] = 'XInputHidden';
            typesToComponents[INPUT_PASSWORD] = 'XInputPassword';
            typesToComponents[INPUT_PASSWORD_WINDOW] = 'XPasswordWindow';
            typesToComponents[INPUT_ENCRYPT] = 'XInputEncrypt';
            typesToComponents[INPUT_TEXT_AREA] = 'XTextarea';
            typesToComponents[INPUT_FROALA] = 'XFroala';
            typesToComponents[INPUT_MULTIPLE_SELECT] = 'XMultipleSelect';
            typesToComponents[INPUT_MULTIPLE_SELECT_ALIAS] = 'XMultipleSelect';
            typesToComponents[INPUT_MULTIPLE_SELECT_RELATION] = 'XMultipleSelectRelation';
            typesToComponents[INPUT_MULTIPLE_SELECT_RELATION_ALIAS] = 'XMultipleSelectRelation';
            typesToComponents[INPUT_SELECT] = 'XSelect';
            typesToComponents[INPUT_SELECT_SEARCH] = 'XSelectSearch';
            typesToComponents[INPUT_MULTIPLE_TAGS] = 'XSelectMultipleTags';
            typesToComponents[INPUT_SELECT_RELATION] = 'XSelectRelation';
            typesToComponents[INPUT_SELECT_RELATION_ALIAS] = 'XSelectRelation';
            typesToComponents[INPUT_DATETIME_PICKER] = 'XInputDatetime';
            typesToComponents[INPUT_DATE_RANGE_PICKER] = 'XInputDateRange';
            typesToComponents[INPUT_DATE_RANGE_PICKER_ALIAS1] = 'XInputDateRange';
            typesToComponents[INPUT_DATE_RANGE_PICKER_ALIAS2] = 'XInputDateRange';
            typesToComponents[INPUT_DATE_PICKER] = 'XInputDate';
            typesToComponents[INPUT_FILE] = 'XInputFile';
            typesToComponents[INPUT_BOOLEAN] = 'XInputCheckbox';
            typesToComponents[INPUT_CHECKBOX] = 'XInputCheckbox';
            typesToComponents[INPUT_RADIO] = 'XInputRadio';
            typesToComponents[ADDON_BUTTON] = 'XAddonButton';
            typesToComponents[ADDON_TEXT] = 'XAddonText';
            typesToComponents[ADDON_TEXT_AND_BUTTON] = 'XAddonTextAndButton';
            typesToComponents[INPUT_IP] = 'XInputIp';
            typesToComponents[INPUT_IP_V6] = 'XInputIpV6';
            typesToComponents[INPUT_EMAIL] = 'XEmailPhoneInput';
            typesToComponents[INPUT_PHONE] = 'XEmailPhoneInput';

            if (!isset(typesToComponents, this.type)) {
                console.error(`Unknown input type: ${this.type}`);
                return 'XTextField';
            }

            return typesToComponents[this.type];
        },
        preparedParams() {
            let preparedParams = $.extend(true, {}, this.params);
            preparedParams.value = this.value;

            if (Object.keys(this.meta).length > 0) {
                if (empty(preparedParams.options)) {
                    preparedParams.options = {};
                }

                if (isset(this.meta, ['rule', 'required']) && this.meta.rule.required) {
                    preparedParams.options.required = 'required';
                }

                if (isset(this.meta, ['rule', 'min'])) {
                    preparedParams.options.min = this.meta.rule.min;
                    if (this.type === INPUT_INTEGER || this.type === INPUT_FLOAT) {
                        preparedParams.minimum = this.meta.rule.min;
                    }
                }
                if (isset(this.meta, ['rule', 'max'])) {
                    preparedParams.options.max = this.meta.rule.max;
                    if (this.type === INPUT_INTEGER || this.type === INPUT_FLOAT) {
                        preparedParams.maximum = this.meta.rule.max;
                    }
                }
                if (isset(this.meta, ['rule', 'decimals'])) {
                    preparedParams.float = this.meta.rule.decimals;
                }
                if (this.type === INPUT_FLOAT) {
                    preparedParams.numberType = 'decimal';
                }
            }

            if (this.type === INPUT_EMAIL) {
                preparedParams.options.type = INPUT_TEXT;
            }

            if (this.type === INPUT_INTEGER) {
                preparedParams.numberType = INPUT_INTEGER;
            }

            if (this.type === INPUT_DECIMAL) {
                preparedParams.numberType = INPUT_DECIMAL;
            }

            if (!isset(preparedParams, ['options', 'id']) && isset(preparedParams, ['options', 'name'])) {
                preparedParams.options.id = this.getIdFromName(preparedParams.options.name);
            }

            if (this.type === INPUT_PHONE) {
                preparedParams.type = this.type;
            }

            if ((this.type === INPUT_CHECKBOX || this.type === INPUT_BOOLEAN) && empty(preparedParams.value)) {
                preparedParams.value = false;
            }

            if (preparedParams.is_required) {
                preparedParams.options.required = 'required';
            }

            if (preparedParams.max_length) {
                preparedParams.maximum = preparedParams.max_length;
            }

            if (preparedParams.min_length) {
                preparedParams.minimum = preparedParams.min_length;
            }

            if (preparedParams.type === INPUT_DECIMAL && preparedParams.decimals) {
                preparedParams.float = preparedParams.decimals;
            }

            return preparedParams;
        },
    },
    methods: {
        getIdFromName(name) {
            return name.replace('[', '-').replace(']', '').toLowerCase();
        },
        recreate() {
            this.show = false;
            this.$nextTick(() => {
                this.show = true;
            });
        },
    },
    render(createElement) {
        let self = this;

        if (!this.show) {
            return '';
        }

        return createElement(this.componentName, {
            props: this.preparedParams,
            ref: 'input',
            on: {
                close: () => {
                    self.$emit('close');
                },
                open: () => {
                    self.$emit('open');
                },
                input: (value) => {
                    self.$emit('input', value);
                },
                ...this.eventHandlers,
            },
            nativeOn: {
                focusout: (value) => {
                    self.$emit('focusout', value);
                },
                ...this.nativeEventHandlers,
            },
        });
    },
};

export {
    INPUT_IP,
    INPUT_IP_V6,
    INPUT_DECIMAL,
    INPUT_INTEGER,
    INPUT_DATE_RANGE_PICKER_ALIAS1,
    INPUT_DATE_RANGE_PICKER_ALIAS2,
    INPUT_SELECT_RELATION_ALIAS,
    INPUT_MULTIPLE_SELECT_RELATION_ALIAS,
    INPUT_BOOLEAN,
    INPUT_MULTIPLE_SELECT_ALIAS,
    INPUT_DATE_RANGE_PICKER,
    INPUT_DATETIME_PICKER,
    INPUT_DATE_PICKER,
    INPUT_SELECT,
    INPUT_SELECT_SEARCH,
    INPUT_MULTIPLE_TAGS,
    INPUT_SELECT_RELATION,
    INPUT_MULTIPLE_SELECT,
    INPUT_MULTIPLE_SELECT_RELATION,
    INPUT_TEXT_AREA,
    INPUT_FROALA,
    INPUT_CHECKBOX,
    INPUT_RADIO,
    INPUT_TEXT,
    INPUT_STRING,
    INPUT_FLOAT,
    INPUT_NUMBER,
    INPUT_HIDDEN,
    INPUT_PASSWORD,
    INPUT_PASSWORD_WINDOW,
    INPUT_ENCRYPT,
    INPUT_FILE,
    ADDON_BUTTON,
    ADDON_TEXT,
    ADDON_TEXT_AND_BUTTON,
    INPUT_EMAIL,
    INPUT_PHONE,
    XComplexInput,
};

export default XComplexInput;

</script>
