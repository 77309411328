(function () {
    var web_events_handlers = {};

    function EventHandlersContainer(eventName) {
        this.eventName = eventName;
        this.named_callbacks = {};
        this.anonymous_callbacks = [];
    }

    EventHandlersContainer.prototype.add = function (callback) {
        if (callback.name) {
            this.named_callbacks[callback.name] = callback;
        } else {
            this.anonymous_callbacks.push(callback);
        }
    };

    EventHandlersContainer.prototype.remove = function (callback) {
        if (callback.name) {
            delete this.named_callbacks[callback.name];
        } else {
            this.named_callbacks = {};
            this.anonymous_callbacks = [];
        }
    };

    EventHandlersContainer.prototype.emit = function (data) {
        if (this.anonymous_callbacks.length > 0) {
            this.anonymous_callbacks.forEach(function (handler) {
                handler(data);
            });
        }

        for (let k in this.named_callbacks) {
            this.named_callbacks[k](data);
        }
    };

    EventHandlersContainer.prototype.handlersCount = function () {
        return Object.keys(this.named_callbacks).length + this.anonymous_callbacks.length;
    };

    window.init_web_events = function () {
        var socket = io.connect('//' + location.host + '/web_events');

        socket.on('connect', function () {
            socket.emit('join');

            // remove listeners before creating of new
            socket.removeAllListeners('event_message');

            socket.on('event_message', function (data) {
                if (typeof web_events_handlers[data['event']] !== 'undefined') {
                    web_events_handlers[data['event']].emit(data['data']);
                }
            });
        });
    };

    window.add_socket_listener = function (event, callback) {
        if (typeof callback !== 'function') {
            throw new TypeError('Param callback must be callable!');
        }

        if (typeof web_events_handlers[event] === 'undefined') {
            web_events_handlers[event] = new EventHandlersContainer(event);
        }

        web_events_handlers[event].add(callback);
    };

    window.remove_socket_listener = function (event, callback) {
        if (typeof web_events_handlers[event] === 'undefined') {
            return;
        }

        if (typeof callback === 'undefined' || !callback.name) {
            delete web_events_handlers[event];
            return;
        }

        web_events_handlers[event].remove(callback);
        if (web_events_handlers[event].handlersCount() === 0) {
            delete web_events_handlers[event];
        }
    };

    window.load_io = function () {
        var socket = io.connect('//' + location.host + '/notification');
        socket.on('notification', function (data) {
            if (data.params[1] == 'usage:::') {
                var ds_check = $('.dashboard-top').is(":visible");
                if (ds_check) {
                    splynx_event_bus.emit('dashboard-server-info-updated', {
                        cpuUsage: data.params[2],
                        iowait: data.params[3],
                    });
                }
            } else {
                var message = data.params[1];
                var time = data.params[2] || 4;
                var type = data.params[3] || 'message';
                var to_manager = data.params[4] == 1 ? true : false;
                var ago = data.params[5] || undefined;
                switch (type) {
                    case 'message':
                        show_message(message, time, to_manager, ago);
                        break;
                    case 'success':
                        show_success(message, time, to_manager, ago);
                        break;
                    case 'warning':
                        show_warning(message, time, to_manager, ago);
                        break;
                    case 'information':
                        show_information(message, time, to_manager, ago);
                        break;
                    case 'error':
                        show_error(message, time, to_manager, ago);
                        break;
                }
            }
        });
    };
})();
