<script>
export default {
    name: 'XTab',
    inject: ['tabs'],
    props: {
        title: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        id: String,
        className: String,
        route: {
            type: [String, Object],
        },
        // Pass component directly as prop to render it in tab.
        // Should be used when need lazy loading of tab component.
        // Component code will be loaded after activating tab.
        // This prop not required, you can put your tab code instantly
        // to tab body.
        component: {
            type: [Object, Function],
            default: null,
        },
        // Pass some props to lazy load component
        componentProps: {
            type: Object,
            default() {
                return {};
            },
        },
        canOpen: {
            type: Boolean,
            default: true,
        },
        counter: {
            type: Object,
        },
    },
    data() {
        return {
            cacheTab: false,
            active: false,
        };
    },
    computed: {
        tabId() {
            return this.id ? this.id : this.title;
        },
    },
    created() {
        if (this.tabs.cacheTabs) {
            this.cacheTab = true;
        }
    },
    mounted() {
        this.tabs.addTab(this);
    },
    destroyed() {
        if (this.$el && this.$el.parentNode) {
            this.$el.parentNode.removeChild(this.$el);
        }

        this.tabs.removeTab(this);
    },
    methods: {
        refresh() {
            this.active = false;
            this.$nextTick(() => {
                this.active = true;
            });
        },
    },
    render(h) {
        if (!this.active && !this.cacheTab) {
            return '';
        }

        if (this.component !== null) {
            return h(this.component, {
                props: this.componentProps,
            });
        }
        return this.$slots.default;
    },
};
</script>
