<template>
    <div v-if="empty(error)">
        <input
            v-if="hiddenInput"
            type="hidden"
            v-bind="options"
            :value="value"
        >
        <button
            class="btn btn-primary"
            @click.prevent="handleClick"
        >
            {{ $t('config', 'View / Set') }}
        </button>
    </div>
    <span
        v-else
        class="label label-danger"
    >{{ error }}</span>
</template>

<script>
import { empty } from '@/utils/functions';

export default {
    name: 'XAddonButton',
    props: {
        value: String,
        options: Object,
        buttonUrl: String,
        hiddenInput: Boolean,
        error: String,
    },
    methods: {
        empty,
        handleClick() {
            if (!empty(this.buttonUrl)) {
                window.open_addon_dialog(this.buttonUrl);
            }
        },
    },
};
</script>

<style scoped>
.addon-button {
    cursor: pointer;
}
</style>
