<template>
    <div
        ref="buttonActions"
        class="mass-action-buttons"
    >
        <div
            class="dropdown"
        >
            <button
                type="button"
                class="btn btn-outline-dark dropdown-toggle me-4"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :disabled="checkedIds.length === 0"
            >
                {{ $t('common', 'Actions') }}
            </button>
            <div class="dropdown-menu">
                <li
                    v-for="(action, index) in actionsList"
                    :key="index"
                    :class="['dropdown-item', {'disabled': action.disabled}]"
                >
                    <a
                        :disabled="action.disabled"
                        :data-test-selector="`selector-${index}`"
                        @click="clickHandler(action)"
                    >{{ action.text }}
                        <span :class="getClassNames(action.ids)">{{ action.ids.length }}</span>
                    </a>
                </li>
            </div>
        </div>
    </div>
</template>

<script>
import { isset } from '@/utils/functions';
import WrapperSelectors from '@/components/common/XDatatable/mixins/WrapperSelectors';

export default {
    name: 'XMassActions',
    mixins: [WrapperSelectors],
    props: {
        massActions: {
            type: [Object, Boolean],
            default: null,
        },
        checkedIds: {
            type: Array,
            default: () => [],
        },
        dataForMassActions: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            massActionsCounts: {},
            actionsList: {},
        };
    },
    methods: {
        _initMassActionsLabels() {
            this.actionsList = this.massActions;
            Object.keys(this.actionsList).forEach((key) => {
                let ids = this.checkedIds.filter((id) => this.dataForMassActions[id][key]);
                this.actionsList[key].ids = ids;
                this.actionsList[key].disabled = ids.length === 0;
            });
        },
        _setUpMassActions() {
            $(`#${this.id}`).on('draw.dt', () => {
                // Mass action handler, mass actions here
                let massButtonsHtml = this.$refs.buttonActions;
                $(this.wrapperIdSelector).find('.mass-action-buttons').remove();
                $(this.wrapperIdSelector).find('.dataTables_length').prepend(massButtonsHtml);
            });
        },
        getClassNames(ids) {
            let className = '';
            let counter = ids.length;
            if (counter === 0) {
                className = 'danger';
            } else if (this.checkedIds && counter === this.checkedIds.length) {
                className = 'success';
            } else {
                className = 'warning';
            }

            return `badge ms-auto me-0 bg-${className}`;
        },
        clickHandler(action) {
            if (isset(action, 'handler')) {
                action.handler(action.ids);
            } else {
                open_dialog_new(action.url, { ids: action.ids });
            }
        },
    },
    watch: {
        checkedIds: {
            immediate: true,
            handler() {
                this._initMassActionsLabels();
            },
        },
    },
};
</script>
