<template>
    <textarea
        v-bind="attributes"
        ref="input"
        :value="value"
        @input="inputHandler"
    />
</template>

<script>
import SplInputMixin from '@/components/common/inputs/input-mixin';
import { isset } from '@/utils/functions';

export default {
    name: 'XTextarea',
    mixins: [SplInputMixin],
    props: {
    // default: String,
        options: Object,
        value: String,
    },
    data() {
        return {};
    },
    computed: {
        attributes() {
            let attributes = this.options;
            if (typeof attributes === 'undefined') {
                attributes = {};
            }

            if (!isset(attributes, 'class')) {
                attributes.class = '';
            }

            attributes.class = this.addClass(attributes.class, ['form-control']);

            return attributes;
        },
    },
    created() {
    },
    mounted() {
        window.autosize($('textarea'));
    // let self = this;
    // $(this.$el).val(this.value).trigger('change').on('change', function () {
    //     self.$emit('input', this.value);
    // });
    },
    methods: {
    // setValue(value) {
    // this.value = value;
    // $(this.$el).val(value).trigger('change');
    // this.$emit('change', value);
    // },
        inputHandler(event) {
            this.$emit('input', event.target.value);
        },
    },
};
</script>
