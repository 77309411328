import state from '@/components/common/XGdpr/store/state';
import actions from '@/components/common/XGdpr/store/actions';
import getters from '@/components/common/XGdpr/store/getters';
import mutations from '@/components/common/XGdpr/store/mutations';

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
