function stateToGetters(data) {
    let gettersArr = {};
    for (let attribute in data) {
        gettersArr[attribute] = function (state) { return state[attribute]; };
    }

    return gettersArr;
}

export default stateToGetters;
