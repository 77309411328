export default {
    name: 'XTranslate',
    functional: true,
    props: {
        tag: {
            type: [String, Boolean],
            default: false,
        },
        message: {
            type: String,
        },
        category: {
            type: String,
            default: 'common',
        },
    },
    render(h, {
        data, parent, props, slots, _v,
    }) {
        const { $splang } = parent;
        if (!$splang) {
            console.warn('Cannot find Splang instance!');
            return;
        }

        const { category, message } = props;
        const params = slots();
        const translate = $splang.t(category, message);
        const children = interpolate(translate, params, h, _v);

        if (props.tag === false || props.tag === 'false') {
            return children;
        }

        const tag = props.tag === 'true' ? 'span' : props.tag;
        return h(tag, data, children);
    },
};

function interpolate(translate, params, h, _v) {
    let result = [];
    let i = 0;
    let findVariants = Object.keys(params);
    let textNodeStart = 0;

    while (i < translate.length) {
        let foundOccurrence = false;

        for (let k = 0; k < findVariants.length; k++) {
            let variantWithBrackets = `{${findVariants[k]}}`;
            if (translate.substring(i, i + variantWithBrackets.length) === variantWithBrackets) {
                foundOccurrence = true;

                if (i > 0) {
                    // Insert text node
                    result.push(_v(translate.substring(textNodeStart, i)));
                }

                // insert param nodes
                params[findVariants[k]].forEach((el) => {
                    result.push(el);
                });

                textNodeStart = i + variantWithBrackets.length;
                i += variantWithBrackets.length;
                break;
            }
        }

        if (!foundOccurrence) {
            i++;
        }
    }

    // Add last text node
    if (textNodeStart !== translate.length) {
        result.push(_v(translate.substring(textNodeStart, translate.length)));
    }

    return result;
}
