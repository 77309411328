export default {
    methods: {
        updateRows({ rows }) {
            const selector = `#${this.tableId}`;
            const event = this.eventName;

            if (!$(selector).length) {
                this.registerUpdate(event, { rows });
                return false;
            }

            const table = $(selector).DataTable();
            const ids = rows.map((row) => row.id);
            const tRows = table.rows((idx, data) => ids.includes(data.id));
            const tRowsIds = [];
            const getActionsWrap = function (actions) {
                return `<div class="action-cell row-gap-16 column-gap-12">${actions.join('')}</div>`;
            };
                // eslint-disable-next-line array-callback-return
            tRows.every(function () {
                const data = this.data();

                tRowsIds.push(data.id);
                const newData = rows.find((row) => row.id === data.id);

                newData.actions = getActionsWrap(newData.actions);
                this.data({ ...data, ...newData });
            });

            const newRowsId = ids.filter((x) => !tRowsIds.includes(x));
            if (newRowsId.length) {
                newRowsId.forEach((id) => {
                    const newRow = rows.find((newRow) => newRow.id === id);
                    if (!newRow.actions) {
                        return false;
                    }
                    const actions = getActionsWrap(newRow.actions);
                    table.row.add({
                        ...newRow,
                        actions,
                    });
                });
                table.draw().sort();
            }
        },
    },
};
