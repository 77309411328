<template>
    <div
        v-if="sidebarState"
        ref="sidebar-wrapper"
        class="sidebar-wrapper"
        role="group"
        @click="hideOpenedSidebar"
    >
        <transition name="slide-fade">
            <div
                class="sidebar"
                role="dialog"
            >
                <button
                    type="button"
                    class="btn-close btn-icon-sm"
                    @click="hideSidebar(hideCallback)"
                />
                <div class="sidebar-header">
                    <h2 v-html="sidebarOptions.title" />
                    <p v-html="sidebarOptions.subtitle" />
                </div>

                <div class="sidebar-content">
                    <component
                        :is="sidebarOptions.component"
                        v-bind="sidebarOptions.props"
                        @set-hide-callback="onHide"
                    />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

/**
     * @see See [Documentation](https://docs.dev.spl.co.ua/en/fecodewiki/base-components/xsidebar)
     */
export default {
    name: 'XSidebar',
    data() {
        return {
            hideCallback: '',
            pointerUpTarget: null,
            pointerDownTarget: null,
        };
    },
    mounted() {
        window.addEventListener('keydown', this.processKeyboard);
    },
    destroyed() {
        window.removeEventListener('keydown', this.processKeyboard);
    },
    methods: {
        ...mapActions('sidebar', [
            'hideSidebar',
        ]),
        onHide({ callback, context }) {
            this.hideCallback = callback.bind(context);
        },
        hideOpenedSidebar(event) {
            let selectedText = document.getSelection();
            if (selectedText.type === 'Range') {
                event?.stopPropagation();
                event?.preventDefault();
                return false;
            }
            if (event.target === this.$refs['sidebar-wrapper']) {
                this.hideSidebar(this.hideCallback);
            }
        },
        processKeyboard(e) {
            if (e.keyCode === 27) {
                this.hideSidebar(this.hideCallback);
            }
        },
    },
    computed: {
        ...mapGetters('sidebar', [
            'sidebarState',
            'sidebarOptions',
        ]),
    },
};
</script>
