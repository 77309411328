export default {
    showNotification(state) {
        return state.showNotification;
    },
    showNotificationProfile(state) {
        return state.showNotificationProfile;
    },
    gdprMessage(state) {
        return state.gdprMessage;
    },
};
