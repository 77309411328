import menu_store from '@/components/base/XMenu/store';
import global_notifications_store from '@/components/base/XGlobalNotification/store';
import gdpr from '@/components/common/XGdpr/store';
import taxes from '@/common-store/taxes';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import entry_points from './modules/entry_points';
import sidebar from './modules/base/sidebar';
import services from './modules/services/services';
import customer_statements from './modules/finance/customer_statements';
import customer_payments from './modules/finance/customer_payments';
import notifications from './modules/base/notifications';
import on_boarding from './modules/onboarding/onboarding';

export default {
    state: () => ({
        menuStructure: [],
        customer: {},
        logo: '',
        footer: '',
        pageTitle: '',
        companyInfo: {},
        refreshRouteKey: (new Date().getTime()),
        showProfileLink: false,
        ticketsEnabled: false,
        showPageError: {
            error: null,
            comment: null,
            code: 0,
            file: null,
            line: null,
            html: null,
        },
    }),
    mutations,
    actions,
    getters,
    modules: {
        entry_points,
        sidebar,
        notifications,
        menu_store,
        global_notifications_store,
        services,
        customer_statements,
        customer_payments,
        gdpr,
        on_boarding,
        taxes,
    },
};
