<template>
    <div
        v-if="!item.hideFromMenu"
        :class="[!isFirstLevel ? caseActiveColor : '']"
        :data-test-selector="item.title"
    >
        <span
            v-if="!item.hideFromMenu && showMenuTitle"
            :class="['menu-block-title', caseActiveColor]"
            v-text="caseTitleBlock"
        />

        <div
            v-if="!item.hideFromMenu"
            :class="[itemLinkClass, isFirstLevel ? caseActiveColor : 'color-inherit']"
            class="menu-item"
            @mouseout="mouseLeaveEvent"
            v-on="isCollapsed ? { mouseover: mouseEnterEvent } : { click: mouseEnterEvent }"
        >
            <a
                :href="item.url"
                :class="[isFirstLevel ? caseActiveColor : 'color-inherit']"
                @click.prevent="clickHandler($event, item, level)"
            >
                <span
                    v-if="isFirstLevel"
                    class="btn-icon-sm main-icon"
                    data-test-selector="icon-item"
                >
                    <i :class="item.icon" />
                </span>
                <template
                    v-if="shouldDisplayItem"
                >
                    <span class="item-title">{{ item.title }}</span>
                    <template v-if="item.counter && item.counterValue > 0">
                        <span
                            :id="appPart + '_' + item.source.toLowerCase().replace(/\\/g,'_') + '_after_menu_item_title_label'"
                            class="badge bg-dark rounded-pill"
                            data-test-selector="counter-value"
                        >{{ item.counterValue }}</span>
                    </template>
                    <template v-if="item.items && item.items.length > 0 && !isMobileItem">
                        <span
                            class="btn-icon-sm button-close"
                            data-test-selector="arrow-down"
                        >
                            <i class="icon-ic_fluent_chevron_down_24_regular" />
                        </span>
                    </template>
                </template>
            </a>
            <template
                v-if="shouldDisplayItem"
            >
                <transition
                    enter-active-class="enter-active"
                    leave-active-class="leave-active"
                    @after-enter="afterEnter"
                    @after-leave="afterLeave"
                    @before-enter="beforeEnter"
                    @before-leave="beforeLeave"
                    @enter="enter"
                    @leave="leave"
                >
                    <div
                        v-if="item.items && item.items.length > 0 && isOpen"
                        class="menu-list"
                    >
                        <x-menu-item
                            v-for="(subitem, index) in item.items"
                            :key="index"
                            :active-item="activeItem"
                            :is-collapsed="isCollapsed"
                            :item="subitem"
                            :level="level+1"
                            :opened-item="openedItem"
                            :opened-sub-item="openedSubItem"
                            :switch-page="switchPage"
                        />
                    </div>
                </transition>
            </template>
        </div>
    </div>
</template>

<script>
import { empty } from '@/utils/functions';

export default {
    name: 'XMenuItem',
    props: {
        item: {
            type: Object,
            required: true,
        },
        activeItem: {
            type: Object,
        },
        isCollapsed: {
            type: Boolean,
        },
        isMobileItem: {
            type: Boolean,
            default: false,
        },
        mobileItem: {
            type: Object,
            default: null,
        },
        openedItem: {
            type: Object,
            default: null,
        },
        openedSubItem: {
            type: Object,
            default: null,
        },
        level: {
            type: Number,
            default: 1,
        },
        switchPage: {
            type: String,
            default: '',
        },
        activeColor: {
            type: String,
            default: '',
        },
        activeBlocks: {
            type: Array,
        },
        firstInGroup: {
            type: Array,
        },
        appPart: String,
    },
    data() {
        return {
            itemHover: false,
            isOpen: false,
            showTitle: true,
        };
    },
    computed: {
        showMenuTitle() {
            return this.isFirstLevel && !this.isMobileItem ? this.firstInGroup.some((item) => item === this.item.title) : false;
        },
        caseTitleBlock() {
            switch (this.item.menuGroup) {
                case 'group_crm':
                    return t('common', 'CRM');
                case 'group_company':
                    return t('common', 'Company');
                case 'group_system':
                    return t('common', 'System');
                default:
                    return '';
            }
        },
        caseActiveColor() {
            let group = this.isFirstLevel ? this.item.menuGroup : this.activeColor;
            switch (group) {
                case 'group_portal':
                case 'group_crm':
                    return 'color-purple';
                case 'group_company':
                    return 'color-success';
                case 'group_main':
                    return 'color-secondary';
                case 'group_system':
                    return 'color-secondary';
                default:
                    return 'color-inherit';
            }
        },
        isFirstLevel() {
            return this.level === 1;
        },
        itemLinkClass() {
            return [
                this.item.items && this.item.items.length > 0 ? 'has-dropdown' : '',
                !this.isMobileItem ? `menu-item-level-${this.level}` : '',
                { 'menu-item-mobile-item': this.isMobileItem },
                { 'menu-item-mobile-item-open': this.isOpen && this.isCollapsed },
                { 'parent-active-item': this.isChildActive(this.item.items) },
                { 'active-item': this.isActiveItem() },
                { 'menu-open': (this.isOpen && !this.isCollapsed) || (this.isOpen && (!this.isCollapsed && !this.isFirstLevel)) },
            ];
        },
        shouldDisplayItem() {
            return (this.isCollapsed && !this.isFirstLevel) || !this.isCollapsed || this.isMobileItem || (!this.isMobileItem && !this.isFirstLevel);
        },
    },
    watch: {
        switchPage(url) {
            this.activatePageSwitch(url);
            this.setOpenItem();
        },
        openedItem() {
            this.checkOpenItem();
        },
        openedSubItem() {
            this.checkOpenItem();
        },
    },
    created() {
        this.$on('click-item', (item, level, event) => {
            this.$parent.$emit('click-item', item, level, event);
        });
        this.$on('expand-item', (item, level) => {
            this.$parent.$emit('expand-item', item, level);
        });
        this.$on('opened-item', (e) => {
            this.$parent.$emit('opened-item', e);
        });
        this.$on('activate-item', (e) => {
            this.$parent.$emit('activate-item', e);
        });
        this.$on('activate-item-page-switch', (e) => {
            this.$parent.$emit('activate-item-page-switch', e);
        });
        this.setOpenItem();
    },
    methods: {
        isEmptyLink(url) {
            return url === '#' || url === '';
        },
        clickHandler(event, item, level) {
            let pathname = window.location.pathname.replace(/\/+$/, '');
            let url = pathname + window.location.search;
            if (url.toString().indexOf('?id=') !== -1) {
                // Check if there is an ID in the url and remove it
                // user on page admin/customers/view?id=3, clicks on menu item /admin/customers/view - forbidden
                // user on page admin/customers/view/?id=3, clicks on menu item /admin/customers/view - forbidden
                // user on page admin/tickets/opened--view?id=18, clicks on menu item /admin/tickets/opened - allowed
                // @see https://jira.splynx.com/browse/SPL-8253
                let params = new URLSearchParams(window.location.search);
                params.delete('id');
                url = pathname;
                if (params.toString() !== '') {
                    url += `?${params.toString()}`;
                }
            }
            if (url.toString() === item.url.toString() && !item.itemsAmount) {
                // Here you need a complete comparison of urls, because when a user on the "admin/config/main/localization" page
                // and clicks on the "admin/config" menu item, you need to allow the transition
                return false;
            }
            this.$emit('click-item', item, level, event);
            if (this.itemCanBeOpened(item)) {
                this.isOpen = !this.isOpen;
            }
        },
        itemCanBeOpened(item) {
            return !empty(item.items);
        },
        isActiveItem() {
            return this.activeItem === this.item;
        },
        activatePageSwitch(url) {
            if (url.indexOf(this.item.url) !== -1 || (!empty(this.item.urlAlternative) && url.indexOf(this.item.urlAlternative) !== -1)) {
                this.$emit('activate-item-page-switch', this.item, this.level);
            }
        },
        isCurrentUrl(item) {
            let url = window.location.pathname + window.location.search;
            if (url.toString().indexOf(item.url) !== -1) {
                return true;
            } if (!empty(item.urlAliases)) {
                // Also check urls in url aliases
                for (let key in item.urlAliases) {
                    if (url.indexOf(item.urlAliases[key]) !== -1) {
                        return true;
                    }
                }
            }
            return false;
        },
        isLinkActive(item) {
            return this.isChildActive(item.items) || this.isCurrentUrl(item);
        },
        isChildActive(child) {
            if (!child) return false;
            return child.some((item) => this.isLinkActive(item));
        },
        checkOpenItem() {
            this.isOpen = (this.item === this.openedItem || this.item === this.openedSubItem);
        },
        setOpenItem() {
            if (this.isChildActive(this.item.items)) {
                this.$emit('expand-item', this.item, this.level);
            }
        },
        // for collapsed Items
        mouseEnterEvent(event) {
            event.stopPropagation();
            this.itemHover = true;
            if (this.isCollapsed && this.isFirstLevel && !this.isMobileItem) {
                this.$emit('set-mobile-item', { item: this.item, itemEl: event.currentTarget });
            }
        },
        mouseLeaveEvent(event) {
            event.stopPropagation();
            this.itemHover = false;
        },
        hover() {
            if (this.isCollapsed && this.isFirstLevel) {
                return this.item === this.mobileItem;
            }
            return this.itemHover;
        },
        // Animation
        beforeEnter(element) {
            requestAnimationFrame(() => {
                if (!element.style.height) {
                    element.style.height = '0px';
                }

                element.style.display = null;
            });
        },
        enter(element) {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    element.style.height = `${element.scrollHeight}px`;
                });
            });
        },
        afterEnter(element) {
            element.style.height = null;
        },
        beforeLeave(element) {
            requestAnimationFrame(() => {
                if (!element.style.height) {
                    element.style.height = `${element.offsetHeight}px`;
                }
            });
        },
        leave(element) {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    element.style.height = '0px';
                });
            });
        },
        afterLeave(element) {
            element.style.height = null;
        },
    },
};
</script>
