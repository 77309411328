<template>
    <empty-layout>
        <div
            v-if="isLogin"
            class="not-found-404-error-page"
        >
            <div class="not-found-404-error-page-wrapper">
                <div class="not-found-404-img-box">
                    <img
                        v-if="error.code === 404"
                        src="/images/404.svg"
                        alt="404"
                    >
                    <b
                        v-else
                    >{{ error.code || error.html.code || 500 }}</b>
                </div>
                <div class="error-details">
                    <div class="error-details-title">
                        {{ errorTitle }}
                    </div>
                    <div class="error-details-description">
                        {{ errorComment }}
                    </div>
                    <div
                        v-if="isDev"
                        class="error-details-dev"
                    >
                        <template v-if="error.code === 404">
                            <div>Route <i>{{ $route.path }}</i> not found.</div>
                            Maybe you forgot to add this route to the App.
                        </template>
                        <template v-else>
                            {{ error.file }}:{{ error.line }}
                        </template>
                    </div>
                </div>
                <div class="not-found-404-btn">
                    <button
                        class="btn btn-primary"
                        @click="goToDashboard"
                    >
                        {{ $t('site', 'Back to main dashboard') }}
                    </button>
                </div>
            </div>
        </div>
    </empty-layout>
</template>

<script>
import { mapMutations } from 'vuex';
import { SET_PAGE_TITLE } from '@/apps/admin/store/mutation-types';
import EmptyLayout from '@/components/layouts/EmptyLayout';

export default {
    name: 'XViewError',
    components: { EmptyLayout },
    props: {
        application: String,
        error: Object,
    },
    data() {
        return {
            isDev: process.env.NODE_ENV === 'development',
        };
    },
    created() {
        if (!this.isLogin) {
            window.switch_page_without_cache(`/${this.application}/login`);
        }
        this.SET_PAGE_TITLE(this.error.title || this.error.error);
    },
    computed: {
        isLogin() {
            return !!window.spl_config.spl_user_id;
        },
        errorTitle() {
            if (!this.error.error && !this.error.html?.error) {
                return this.$t('site', 'Oops! This page could not be found.');
            }
            return this.error.error || this.error.html?.error;
        },
        errorComment() {
            if (!this.error.comment && !this.error.html?.comment) {
                return this.$t('common', 'The page you are looking for might have been removed or is temporarily unavailable.');
            }
            return this.error.comment || this.error.html?.comment;
        },
    },
    methods: {
        goToDashboard() {
            window.switch_page_without_cache(`/${this.application}/dashboard`);
        },
        ...mapMutations([SET_PAGE_TITLE]),
    },
};
</script>

<style lang="scss" scoped>
@import "../../../design/bootstrap/splynx-bootstrap-variables";
@import "../../../design/pages/common/view-errors";
</style>
