// Romanian
jQuery.timeago.settings.strings = {
    prefixAgo: "acum",
    prefixFromNow: "in timp de",
    suffixAgo: "",
    suffixFromNow: "",
    seconds: "mai putin de un minut",
    minute: "un minut",
    minutes: "%d minute",
    hour: "o ora",
    hours: "%d ore",
    day: "o zi",
    days: "%d zile",
    month: "o luna",
    months: "%d luni",
    year: "un an",
    years: "%d ani"
};