$.widget('ui.dialog', $.ui.dialog, {
    _on(suppressDisabledCheck, element, handlers) {
        let delegateElement;
        let instance = this;
        // No suppressDisabledCheck flag, shuffle arguments
        if (typeof suppressDisabledCheck !== 'boolean') {
            handlers = element;
            element = suppressDisabledCheck;
            suppressDisabledCheck = false;
        }

        // No element argument, shuffle and use this.element
        if (!handlers) {
            handlers = element;
            element = this.element;
            delegateElement = this.widget();
        } else {
            element = $(element);
            delegateElement = element;
            this.bindings = this.bindings.add(element);
        }

        $.each(handlers, (event, handler) => {
            function handlerProxy() {
                // Fix bug with focus input in modals for date range picker
                if (handler.name === 'focusin' && ($(this.activeElement).attr('name') === 'daterangepicker_start'
                    || $(this.activeElement).attr('name') === 'daterangepicker_end')) {
                    return;
                }

                // Allow widgets to customize the disabled handling
                // - disabled as an array instead of boolean
                // - disabled class as method for disabling individual parts
                if (!suppressDisabledCheck
                    && (instance.options.disabled === true
                        || $(this).hasClass('ui-state-disabled'))) {
                    return;
                }
                return (typeof handler === 'string' ? instance[handler] : handler)
                    .apply(instance, arguments);
            }

            // Copy the guid so direct unbinding works
            if (typeof handler !== 'string') {
                handlerProxy.guid = handler.guid || handlerProxy.guid || $.guid++;
                handler.guid = handlerProxy.guid;
            }

            let match = event.match(/^([\w:-]*)\s*(.*)$/);
            let eventName = match[1] + instance.eventNamespace;
            let selector = match[2];

            if (selector) {
                delegateElement.on(eventName, selector, handlerProxy);
            } else {
                element.on(eventName, handlerProxy);
            }
        });
    },

    _createButtons() {
        let that = this;
        let { buttons } = this.options;

        // If we already have a button pane, remove it
        this.uiDialogButtonPane.remove();
        this.uiButtonSet.empty();

        if ($.isEmptyObject(buttons) || (Array.isArray(buttons) && !buttons.length)) {
            this._removeClass(this.uiDialog, 'ui-dialog-buttons');
            return;
        }

        $.each(buttons, (name, props) => {
            let click; let
                buttonOptions;
            props = typeof props === 'function'
                ? { click: props, text: name }
                : props;

            // Default to a non-submitting button
            props = $.extend({ type: 'button' }, props);

            // Change the context for the click callback to be the main element
            click = props.click;
            buttonOptions = {
                icon: props.icon,
                iconPosition: props.iconPosition,
                showLabel: props.showLabel,

                // Deprecated options
                icons: props.icons,
                text: props.text,
            };

            delete props.click;
            delete props.icon;
            delete props.iconPosition;
            delete props.showLabel;

            // Deprecated options
            delete props.icons;
            let className = 'btn btn-primary ms-4';
            let propString = props.text.toString().toLowerCase();
            if (propString === 'close' || propString === 'no' || propString === 'cancel') {
                className = 'btn btn-cancel ms-4';
            }
            if (typeof props.text === 'boolean') {
                delete props.text;
            }

            $('<button></button>', props)
                .button(buttonOptions).addClass(className)
                .appendTo(that.uiButtonSet)
                .on('click', function () {
                    click.apply(that.element[0], arguments);
                });
        });
        this._addClass(this.uiDialog, 'ui-dialog-buttons');
        this.uiDialogButtonPane.appendTo(this.uiDialog);
    },
});
