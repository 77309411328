import {
    PUSH_NOTIFICATION, REMOVE_INTERCEPTOR, REMOVE_NOTIFICATION, REMOVE_NOTIFICATIONS, SET_INTERCEPTOR,
} from './mutation-types';

export default {
    [PUSH_NOTIFICATION](state, item) {
        state.notifications.push(item);
    },
    [REMOVE_NOTIFICATIONS](state) {
        state.notifications = [];
    },
    [REMOVE_NOTIFICATION](state, id) {
        let index = state.notifications.findIndex(((element) => element.id === id));
        if (index === -1) {
            return;
        }
        state.notifications.splice(index, 1);
    },
    [SET_INTERCEPTOR](state, data) {
        state.interceptors[data.type] = data;
    },
    [REMOVE_INTERCEPTOR](state, type) {
        delete state.interceptors[type];
    },
};
