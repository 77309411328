import { SET_COLLAPSE_MENU_STATE, SET_MOBILE_MENU_STATE, SET_MOBILE_MENU_WIDTH } from './mutation-types';

export default {
    toggleMenu({ commit, state }, collapseMenu) {
        commit(SET_COLLAPSE_MENU_STATE, collapseMenu);

        if (collapseMenu) {
            localStorage.setItem(`${state.applicationPart}_menu_state`, 'small');
        } else {
            localStorage.setItem(`${state.applicationPart}_menu_state`, 'big');
        }
    },
    showMobileMenu({ commit }, mobileMenu) {
        commit(SET_MOBILE_MENU_STATE, mobileMenu);
    },
    handleResize({
        commit,
        dispatch,
        state,
    }) {
        if (window.innerWidth <= 1024) {
            dispatch('toggleMenu', false);
            commit(SET_MOBILE_MENU_WIDTH, true);
        } else {
            dispatch('toggleMenu', localStorage.getItem(`${state.applicationPart}_menu_state`) === 'small');
            commit(SET_MOBILE_MENU_WIDTH, false);
        }
    },
    isMobileWidth({
        state,
        dispatch,
    }) {
        if (window.innerWidth > 1024) {
            dispatch('toggleMenu', !state.collapseState);
        } else {
            dispatch('showMobileMenu', !state.mobileState);
        }
    },
};
