const getMenu = (state) => state.menu;

const customer = (state) => state.customer;

const logo = (state) => state.logo;

const footer = (state) => state.footer;

const serverSideConfig = (state) => state.serverSideConfig;

const onBoardingSteps = (state) => state.onBoardingSteps;

const pageTitle = (state) => state.pageTitle;

const refreshRouteKey = (state) => state.refreshRouteKey;

const referencesApp = (state) => state.referencesApp;

const companyInfo = (state) => state.companyInfo;

const showProfileLink = (state) => state.showProfileLink;

const ticketsEnabled = (state) => state.ticketsEnabled;

const showPageError = (state) => state.showPageError;

export default {
    getMenu,
    customer,
    logo,
    footer,
    serverSideConfig,
    pageTitle,
    refreshRouteKey,
    referencesApp,
    companyInfo,
    onBoardingSteps,
    showProfileLink,
    ticketsEnabled,
    showPageError,
};
