import {
    SIDEBAR_LOAD,
    SIDEBAR_SET_OPTIONS,
} from '@/apps/admin/store/mutation-types';

const state = {
    sidebarState: false,
    sidebarOptions: {
        title: '',
        component: '',
        props: '',
    },
};

const actions = {
    showSidebar({ commit }, sidebarData) {
        commit(SIDEBAR_LOAD, true);
        commit(SIDEBAR_SET_OPTIONS, sidebarData);
    },
    hideSidebar({ commit }) {
        commit(SIDEBAR_LOAD, false);
    },
    toggleSidebar({ commit }) {
        commit(SIDEBAR_LOAD, !this.sidebarState);
    },
};

const getters = {
    sidebarState(state) {
        return state.sidebarState;
    },
    sidebarOptions(state) {
        return state.sidebarOptions;
    },
};

const mutations = {
    [SIDEBAR_LOAD](state, sidebarState) {
        state.sidebarState = sidebarState;
    },
    [SIDEBAR_SET_OPTIONS](state, sidebarOptions) {
        state.sidebarOptions = sidebarOptions;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
