<template>
    <div
        v-if="html !== ''"
        :class="className"
        v-html="html"
    />
</template>

<script>
import loadAddonCode from '@/components/base/XAddonCode/load_addon_code';

export default {
    name: 'XAddonCode',
    props: {
        page: String,
        location: String,
        params: Object,
        className: String,
    },
    data() {
        return {
            html: '',
        };
    },
    mounted() {
        loadAddonCode(this.page, this.params)
            .then((code) => {
                let location = this.location === 'after_content' ? 'after' : 'before';
                this.setCode(code[location]);
            })
            .catch((error) => {
                console.error(error);
            });
    },
    methods: {
        parseCode(rawCode) {
            let root = $('<div>');
            root.append(rawCode);

            let scripts = root.find('script');
            root.find('script').remove();

            this.html = root.html();

            this.$nextTick(() => {
                scripts.each((index, script) => {
                    $('body').append(script);
                });
            });
        },
        setCode(rawCode) {
            this.parseCode(rawCode);
        },
    },
};
</script>
