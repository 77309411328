var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"toasters-wrap"},[_c('transition-group',{attrs:{"name":"fade"}},_vm._l((_vm.limitedNotifications),function(item,index){return _c('div',{key:("key-" + index),staticClass:"toaster",attrs:{"data-test-selector":"message"}},[_c('div',{staticClass:"toaster-content",on:{"click":function($event){return _vm.remove(item.id)}}},[(item.type !== 'text')?_c('span',{class:{
                        'icon-wrap': true,
                        'color-danger': item.type === 'error',
                        'color-warning': item.type === 'warning',
                        'color-success': item.type === 'success',
                        'color-info': item.type === 'info',
                    }},[_c('i',{class:{
                            'icon-ic_fluent_error_circle_24_regular': item.type === 'error',
                            'icon-ic_fluent_warning_24_regular': item.type === 'warning',
                            'icon-ic_fluent_checkmark_circle_24_regular': item.type === 'success',
                            'icon-ic_fluent_info_12_regular': item.type === 'info',
                        }})]):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(item.message)}})]),(item.action_button)?_c('div',{staticClass:"toaster-buttons"},[_c('a',{staticClass:"toaster-button",attrs:{"href":"javascript:void(0)"},domProps:{"textContent":_vm._s(item.close_button ? item.close_button.name : _vm.t('common', 'Skip'))},on:{"click":function($event){return _vm.remove(item.id)}}}),_c('a',{staticClass:"toaster-button-primary",attrs:{"href":"javascript:void(0)"},domProps:{"textContent":_vm._s(item.action_button.name ? item.action_button.name : _vm.t('common', 'View'))},on:{"click":function($event){return _vm.runAction(item)}}})]):_c('div',{staticClass:"toaster-buttons"},[_c('a',{staticClass:"toaster-button-icon",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.remove(item.id)}}},[_c('span',{staticClass:"icon-wrap"},[_c('i',{staticClass:"icon-ic_fluent_dismiss_24_regular"})])])])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }