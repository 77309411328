export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_LOGO = 'SET_LOGO';
export const SET_FOOTER = 'SET_FOOTER';
export const SET_COMPANY_INFO = 'SET_COMPANY_INFO';
export const SET_SHOW_PROFILE_LINK = 'SET_SHOW_PROFILE_LINK';
export const SET_TICKETS_ENABLED = 'SET_TICKETS_ENABLED';

export const REFRESH_ROUTE = 'REFRESH_ROUTE';
export const SET_DOCUMENTATION_CONFIGS = 'SET_DOCUMENTATION_CONFIGS';
export const SET_SHOW_ERROR_PAGE = 'SET_SHOW_ERROR_PAGE';

export const SET_MENU = 'SET_MENU';

export const SET_ON_BOARDING_STEPS = 'SET_ON_BOARDING_STEPS';
export const ON_BOARDING_LOAD = 'ON_BOARDING_LOAD';
export const ON_BOARDING_MAIN_DASHBOARD_LOAD = 'ON_BOARDING_MAIN_DASHBOARD_LOAD';
export const ON_BOARDING_STEPS = 'ON_BOARDING_STEPS';
export const ON_BOARDING_CURRENT_STEP = 'ON_BOARDING_CURRENT_STEP';
export const ON_BOARDING_REFS = 'ON_BOARDING_REFS';
export const RELOAD_TOUR = 'RELOAD_TOUR';

export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const REMOVE_NOTIFICATIONS = 'REMOVE_NOTIFICATIONS';
export const SIDEBAR_LOAD = 'SIDEBAR_LOAD';
export const SIDEBAR_SET_OPTIONS = 'SIDEBAR_SET_OPTIONS';

export const SET_ENTRY_POINTS = 'SET_ENTRY_POINTS';
export const SET_ENTRY_POINTS_INFO = 'SET_ENTRY_POINTS_INFO';
export const DELETE_ENTRY_POINT = 'DELETE_ENTRY_POINT';
export const ADD_ENTRY_POINT = 'ADD_ENTRY_POINT';
export const SET_INSTALL_MODULE_INFO = 'SET_INSTALL_MODULE_INFO';

export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_NUMBER_CONFIG = 'SET_NUMBER_CONFIG';
export const SET_PARTNERS = 'SET_PARTNERS';

// Reference keepers
export const SET_APP_CHILD_REFERENCES = 'SET_APP_CHILD_REFERENCES';

// Tickets
export const SET_TICKETS = 'SET_TICKETS';
export const SET_TICKETS_PAGE_INFO = 'SET_TICKETS_PAGE_INFO';

// Services
export const SET_SERVICES = 'SET_SERVICES';
export const SET_SELECTED_SERVICES = 'SET_SELECTED_SERVICES';
export const SET_ALL_SERVICES = 'SET_ALL_SERVICES';
export const SET_LOADED_SERVICES = 'SET_LOADED_SERVICES';
export const CHANGE_SERVICE_SELECT = 'CHANGE_SERVICE_SELECT';
export const DELETE_SELECTED_SERVICE = 'DELETE_SELECTED_SERVICE';
export const PAUSE_ALL_SERVICES = 'PAUSE_ALL_SERVICES';
export const SET_PORTAL_TARIFFS_FOR_CHANGE_LIST = 'SET_PORTAL_TARIFFS_FOR_CHANGE_LIST';
export const SET_PORTAL_TARIFF_FOR_CHANGE = 'SET_PORTAL_TARIFF_FOR_CHANGE';
export const DELETE_SERVICE_SELECT = 'DELETE_SERVICE_SELECT';
export const SET_PAY_BY_OPTIONS = 'SET_PAY_BY_OPTIONS';

// Finance
export const SET_PAYMENT_VIEW_DATA = 'SET_PAYMENT_VIEW_DATA';
