window.csrf = (function ($) {
    let stat = {
        getCsrfParam() {
            return $('meta[name=csrf-param]').attr('content');
        },

        getCsrfToken() {
            return $('meta[name=csrf-token]').attr('content');
        },

        setCsrfToken(name, value) {
            $('meta[name=csrf-param]').attr('content', name);
            $('meta[name=csrf-token]').attr('content', value);
        },

        reloadCsrfToken() {
            let token = stat.getCsrfToken();
            if (token) {
                $(`form input[name="${stat.getCsrfParam()}"]`).val(token);
            }
        },

        init() {
            initCsrfHandler();
        },
    };

    function initCsrfHandler() {
        $.ajaxPrefilter((options, originalOptions, xhr) => {
            if (!options.crossDomain && stat.getCsrfParam()) {
                xhr.setRequestHeader('X-CSRF-Token', stat.getCsrfToken());
            }
        });
    }

    stat.init();

    $(document).ajaxComplete((event, xhr) => {
        let token = xhr.getResponseHeader('X-CSRF-Token');
        if (token) {
            stat.setCsrfToken('_csrf_token', token);
        }
    });

    return stat;
}(jQuery));

$(() => {
    window.csrf.reloadCsrfToken();
});
