<script>
import { XBootstrapSpinner } from '@/components/common/XBootstrapSpinner';

export default {
    name: 'XPreloaderBlock',
    functional: true,
    components: {
        XBootstrapSpinner,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'lg',
        },
    },
    render(h, {
        props,
        slots,
    }) {
        const children = slots();

        if (!props.loading) {
            if ('default' in children) {
                return children.default;
            }
            return children;
        }

        return h('div', {
            class: 'spinner-wrapper',
        }, [
            h(XBootstrapSpinner, {
                props: {
                    size: props.size,
                },
            }),
        ]);
    },
};
</script>
