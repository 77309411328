<template>
    <div
        v-if="empty(error)"
        class="input-group"
    >
        <input
            type="text"
            class="form-control input-sm"
            v-bind="options"
            :value="value"
            @input="handleInput"
        >
        <button
            class="btn btn-outline-secondary"
            type="button"
            @click="openModal"
        >
            {{ $t('common', 'View / Set') }}
        </button>
    </div>
    <span
        v-else
        class="label label-danger"
    >{{ error }}</span>
</template>

<script>
import { empty } from '@/utils/functions';

export default {
    name: 'XAddonTextAndButton',
    props: {
        attribute: String,
        options: {
            type: Object,
        },
        value: [String, Number],
        buttonUrl: String,
        error: String,
        is_af: Boolean,
    },
    mounted() {
    // Set value from addon modal
        splynx_event_bus.on(`set-af-from-addon-modal-${this.attribute}`, this.handleSetInputValue);
    },
    methods: {
        empty,
        openModal() {
            if (!empty(this.buttonUrl)) {
                window.open_addon_dialog(this.buttonUrl);
            }
        },
        handleInput(event) {
            this.$emit('input', event.target.value);
        },
        handleSetInputValue(data) {
            if (data.is_af == this.is_af) {
                this.$emit('input', data.value);
            }
        },
    },
};
</script>
