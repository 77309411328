<template>
    <button
        ref="popoverButton"
        v-click-outside="clearStyle"
        v-popover="{name: 'relation-' + _uid}"
        type="button"
        class="btn btn-outline-secondary btn-icon cursor-pointer button-fixed-popover sm-align-right"
        :style="style"
        @click.stop="showSelectedRelations"
    >
        <i class="icon-ic_fluent_link_square_24_regular" />
        <popover
            :name="'relation-' + _uid"
            :transition="'none'"
            :width="250"
        >
            <x-preloader-block
                :loading="loadingRelationsInfo"
                size="sm"
            >
                <h6 class="popover-header">
                    {{ relationsInfo.title }}
                </h6>
                <div class="popover-body">
                    <ul class="popover-dropdown">
                        <li
                            v-for="(link, index) in relationsInfo.links"
                            :key="index"
                            @click="listenEventInPopover"
                            v-html="link"
                        />
                    </ul>
                </div>
            </x-preloader-block>
        </popover>
    </button>
</template>

<script>
import Vue from 'vue';
import XPreloaderBlock from '@/components/common/XPreloaderBlock/XPreloaderBlock';

export default {
    name: 'XRelationInfo',
    components: { XPreloaderBlock },
    props: {
        module: String,
        category: String,
        name: String,
        value: [String, Number, Array, Object],
    },
    data() {
        return {
            nameModule: {},
            relationsInfo: {},
            loadingRelationsInfo: true,
            style: '',
        };
    },
    mounted() {
        this.processingNameModule();
    },
    beforeDestroy() {
        this.style = '';
    },
    methods: {
        processingNameModule() {
            this.nameModule = this.module;
            if (!empty(this.category) && this.category == 'lead') {
                this.nameModule = 'leads';
            }
        },
        showSelectedRelations() {
            if (Object.keys(this.relationsInfo).length === 0) {
                this.loadingRelationsInfo = true;
                $.ajax({
                    url: '/admin/relations--load-relation-info',
                    dataType: 'json',
                    data: {
                        module: this.nameModule,
                        attribute: this.name,
                        value: JSON.stringify(this.value),
                    },
                    success: (data) => {
                        this.relationsInfo = data;
                    },
                    error() {
                    },
                    complete: () => {
                        this.loadingRelationsInfo = false;
                        this.style = 'z-index:20;';
                    },
                });
            }
            this.loadingRelationsInfo = false;
        },
        listenEventInPopover() {
            this.clearStyle();
        },
        clearStyle() {
            this.style = '';
        },
    },
};
Vue.directive('click-outside', {
    bind(el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his childrens
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
});
</script>
