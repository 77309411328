import { SHOW_GDPR_NOTIFICATION, SHOW_GDPR_NOTIFICATION_PROFILE, GDPR_MESSAGE } from './mutation-types';

export default {
    toggleGdpr({ commit }, boolean) {
        commit(SHOW_GDPR_NOTIFICATION, boolean);
        commit(SHOW_GDPR_NOTIFICATION_PROFILE, boolean);
    },
    setGdprNotification({ commit }, boolean) {
        commit(SHOW_GDPR_NOTIFICATION, boolean);
    },
    setGdprNotificationProfile({ commit }, boolean) {
        commit(SHOW_GDPR_NOTIFICATION_PROFILE, boolean);
    },
    setGdprMessage({ commit }, message) {
        commit(GDPR_MESSAGE, message);
    },
};
