import {
    SET_COLLAPSE_MENU_STATE,
    SET_MOBILE_MENU_STATE,
    SET_MOBILE_MENU_WIDTH,
} from './mutation-types';

export default {
    [SET_COLLAPSE_MENU_STATE](state, collapseState) {
        state.collapseState = collapseState;
    },
    [SET_MOBILE_MENU_STATE](state, mobileState) {
        state.mobileState = mobileState;
    },
    [SET_MOBILE_MENU_WIDTH](state, mobileWidth) {
        state.mobileWidth = mobileWidth;
    },
};
