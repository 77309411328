<template>
    <button
        type="button"
        class="btn btn-outline-primary btn-password"
        @click="handleClick"
        v-html="t('common', 'Show')"
    />
</template>

<script>
export default {
    name: 'XPasswordWindow',
    props: {
        disabled: {
            type: Boolean,
            default: true,
        },
        path: {
            type: String,
            required: true,
        },
    },
    methods: {
        handleClick() {
            open_password_window(this.path);
        },
    },
};
</script>
