<template>
    <div :class="{'default-spaces table-on-bg bg-light p-16 pb-0': onBg}">
        <x-datatable-progress
            v-show="showMassProgress"
            :initial-progress="massActionInfo.progress"
            :initial-message="massActionInfo.message"
            @progress="showMassProgress = true"
            @finish="handleFinishMassAction"
        />

        <slot
            name="dt-under-header"
        />

        <table
            :id="id"
            ref="table"
            class="display supertable table table-striped"
            v-bind="localTableTagOptions"
        >
            <thead>
                <tr>
                    <th
                        v-for="columnTitle in headers"
                        :key="columnTitle"
                        v-html="columnTitle"
                    />
                    <th
                        v-if="actionColumn"
                        class="actions_column"
                    >
                        {{ titleOfActionColumn }}
                    </th>
                </tr>
            </thead>
        </table>
    </div>
</template>

<script>
import { isset } from '@/splang/utils';
import { rand } from '@/utils/functions';
import XDatatableCommonMixin from '@/components/common/XDatatable/mixins/XDatatableCommonMixin';
import WrapperSelectors from '@/components/common/XDatatable/mixins/WrapperSelectors';
import ColoredButton from '@/components/common/XDatatable/mixins/ColoredButton';
import ActionsAsObjectMixin from '@/components/common/XDatatable/mixins/ActionsAsObjectMixin';
import DTEvents from '@/components/common/XDatatable/mixins/DTEvents';
import XDatatableProgress from './XDatatableProgress';

const RELOAD_DATA_ATTRIBUTE = 'data-table-reload';

export default {
    name: 'XDatatable',
    mixins: [XDatatableCommonMixin, WrapperSelectors, ColoredButton, ActionsAsObjectMixin, DTEvents],
    props: {
        buttons: {
            type: Object,
        },
        buttonView: {
            type: String,
            default: 'dropdown',
        },
        onBg: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        splynx_event_bus.on('clear-selection-data-table', this.handleFinishMassAction);
    },
    data() {
        return {
            firstRun: true,
            showMassProgress: false,
            localStateColoredButton: true,
            localTableTagOptions: {},
        };
    },
    watch: {
        massAction: {
            deep: true,
            immediate: true,
            handler(val) {
                if (val !== false && val !== null && isset(val, 'percent') && parseInt(val.percent) > 0) {
                    this.showMassProgress = true;
                }
            },
        },
        title(newTitle) {
            $(`#${this.computedWrapperId} .table_title`).html(newTitle);
        },
        tableOptions(newOptions) {
            this.localTableTagOptions = { ...newOptions };
        },
    },
    methods: {
        _initMassActionsLabels() {
            $(this.wrapperIdSelector).on('show.bs.dropdown', '.dropdown', () => {
                let disableActions = {};
                let { buttonsBehavior } = this;
                let ids = $(`#${this.id}`).DataTable().columns().checkboxes.selected()[this.targetCheckbox];
                let idsLength = ids.length;
                let { buttons } = this;

                for (let action in this.buttons) {
                    disableActions[action] = idsLength;
                }

                ids.forEach((item) => {
                    let visibleActions = $(item).data('visible-actions');

                    for (let action in visibleActions) {
                        if (Object.prototype.hasOwnProperty.call(visibleActions, action) && Object.prototype.hasOwnProperty.call(buttons, action) && !visibleActions[action]) {
                            disableActions[action] -= 1;
                        }
                    }
                });

                for (let action in disableActions) {
                    let countLabel = '';
                    if (disableActions[action] === ids.length) {
                        countLabel = `<label class="badge bg-success">${disableActions[action]}</label>`;
                    } else {
                        countLabel = `<label class="badge bg-${disableActions[action] === 0 ? 'danger' : 'warning'}">${disableActions[action]}</label>`;
                    }

                    $(this.wrapperIdSelector).find(`#${buttons[action].id}`).find('.count').html(` ${countLabel}`);

                    if (Object.prototype.hasOwnProperty.call(disableActions, action) && Object.prototype.hasOwnProperty.call(buttons, action) && disableActions[action] == 0) {
                        $(this.wrapperIdSelector)
                            .find(`#${buttons[action].id}`).attr('disabled', 'disabled')
                            .closest('li')
                            .addClass('disabled');
                    }

                    // check if all buttons selected items are correct
                    if (buttonsBehavior[action] && disableActions[action] !== idsLength) {
                        $(this.wrapperIdSelector).find(`#${buttons[action].id}`).closest('li').hide();
                    }
                }
            });

            $(this.wrapperIdSelector).on('hide.bs.dropdown', '.dropdown', () => {
                $(this.wrapperIdSelector)
                    .find('.mass-action-buttons .dropdown-menu li').removeClass('disabled')
                    .find('a')
                    .removeAttr('disabled', 'disabled');
                $(this.wrapperIdSelector).find('.mass-action-buttons .dropdown-menu li').show();
            });

            $(window).resize(() => {
                setTimeout(() => {
                    $(this.wrapperIdSelector).find('.dt-checkboxes-cell').attr('colspan', '0');
                }, 300);
            });
        },
        _initHandlingMassButtonsEvents() {
            for (let name in this.buttons) {
                let button = this.buttons[name];

                if (!isset(button, 'id')) {
                    console.error(`DT mass buttons: Id is required parameter for button: ${name}`);
                    continue;
                }

                let self = this;
                $(this.wrapperIdSelector).on('click', `#${button.id}`, function () {
                    if (!$(this).attr('disabled')) {
                        let items = $(`#${self.id}`).DataTable().columns().checkboxes.selected()[self.targetCheckbox];
                        let ids = items.filter((item) => {
                            let visibleActions = $(item).data('visible-actions');
                            return visibleActions[name];
                        });
                        ids.forEach((item, i) => {
                            if ($(item).length > 0) {
                                ids[i] = $(item).text();
                            }
                        });
                        ids = JSON.stringify(window.numbersToRanges(ids));
                        if (isset(button, 'handler')) {
                            button.handler(ids);
                        } else {
                            open_dialog_new(button.url, { ids });
                        }
                    }
                });
            }
        },
        getColumnDefsOptions() {
            if (this.checkbox && Object.keys(this.buttons).length > 0) {
                let checkboxInputIdPrefix = `datatable_checkbox_input_${rand(1, 99999)}_`;
                let allInputId = `datatable_checkbox_all_input_${Date.now()}`;

                let renderSelectAll = `${''
                    + '<div class="form-check select-all-chk checkbox" id="'}${allInputId}">`
                    + `<input type="checkbox" class="dt-checkboxes dt-header form-check-input" title="${this.$t('support', 'Select all')}" id="select-all-chk">`
                    + `<label title="${this.$t('support', 'Select all')}" for="select-all-chk"></label>`
                    + '</div>';

                let defs = this.columnDefs;
                defs.push({
                    targets: this.targetCheckbox,
                    orderable: false,
                    render(data, type, row, meta) {
                        if (type === 'display') {
                            data = `${''
                                + '<div class="form-check select-all-chk checkbox">'
                                + '<input type="checkbox" class="dt-checkboxes form-check-input" id="'}${checkboxInputIdPrefix}_${meta.row}_${meta.col}">`
                                + `<label for="${checkboxInputIdPrefix}_${meta.row}_${meta.col}"></label>`
                                + '</div>';
                        }

                        return data;
                    },
                    checkboxes: {
                        stateSave: false,
                        selectRow: true,
                        orderable: false,
                        searchable: false,
                        selectAllRender: renderSelectAll,
                        selectCallback: (data) => {
                            let buttonsSelector = $(this.wrapperIdSelector).find('.mass-action-buttons').find('input, button');
                            let selectedCount = data.columns().checkboxes.selected()[this.targetCheckbox].length;
                            let totalCount = data.page.info().recordsTotal;
                            let checkboxesSelectAll = $('body').find(`#${allInputId}`).find('input');

                            if (selectedCount > 0) {
                                $('body').find(`#${allInputId}`).find('label, input').attr('title', this.$t('common', 'Unselect all'));
                                buttonsSelector.each(function () {
                                    $(this).removeAttr('disabled');
                                });

                                checkboxesSelectAll.prop('indeterminate', true);
                                if (checkboxesSelectAll.prop('indeterminate')) {
                                    checkboxesSelectAll.prop('checked', true);
                                }

                                if (this.checkboxAlert) {
                                    this.renderCheckboxAlert();
                                    let text = '';
                                    if (totalCount === selectedCount) {
                                        text = this.$t('common', 'All');
                                    } else {
                                        text = `${selectedCount} ${this.$t('common', 'out of')} ${totalCount}`;
                                    }
                                    this.getAlertElement().find('.number').html(text);
                                }
                            } else {
                                $('body').find(`#${allInputId}`).find('label, input').attr('title', this.$t('common', 'Select All'));
                                buttonsSelector.each(function () {
                                    $(this).attr('disabled', 'disabled');
                                });
                                if (this.checkboxAlert) {
                                    this.getAlertElement().find('.mass-actions-alert').remove();
                                }
                            }
                        },
                        ...this.checkboxOptions,
                    },
                });
            }

            return this.computedColumnDefs;
        },
        renderCheckboxAlert() {
            let button = `<button type="button" class="btn btn-outline-primary" onclick="window.activeDT.columns().checkboxes.deselectAll();">${this.$t('customers', 'Reset selection')}</button>`;
            let translate = this.$t('administration', '%s rows are selected. Actions will be applied to selected rows. {button}', { button });
            let html = this.renderAlert(translate.replace('%s', '<b class="number"></b>'));

            this.setAlertHtml(html);
        },
        renderAlert(innerHtml) {
            let span = document.createElement('span');
            span.innerHTML = innerHtml;

            let subDiv = document.createElement('div');

            subDiv.className = 'alert alert-info text-center mass-actions-alert';
            subDiv.setAttribute('role', 'alert');
            subDiv.appendChild(span);

            return subDiv.outerHTML;
        },
        getMassButtonsHtml() {
            let html = '';

            for (let name in this.buttons) {
                let button = this.buttons[name];

                if (!isset(button, 'text')) {
                    button.text = 'Unknown';
                    console.error('Text is required parameter of dt button');
                    continue;
                }
                if (!isset(button, 'id')) {
                    button.id = '';
                    console.error('ID is required parameter of dt button');
                    continue;
                }

                if (!isset(button, 'show')) {
                    button.show = true;
                }
                if (!button.show) {
                    continue;
                }

                let options = isset(button, 'options') ? button.options : {};
                options[RELOAD_DATA_ATTRIBUTE] = RELOAD_DATA_ATTRIBUTE;

                if (this.buttonView === 'dropdown') {
                    let li = document.createElement('li');
                    li.className = 'dropdown-item';

                    let span = document.createElement('span');
                    span.className = 'count';

                    let a = document.createElement('a');
                    a.href = '#';
                    a.id = button.id;
                    a.dataset.tableReload = '1';
                    let fragment = document.createDocumentFragment();
                    fragment.textContent = button.text;
                    fragment.appendChild(span);
                    a.appendChild(fragment);

                    li.appendChild(a);

                    html += li.outerHTML;
                } else {
                    let btn = document.createElement('button');
                    btn.id = button.id;
                    btn.className = 'btn btn-default btn-sm';
                    btn.style = 'margin-right: 5px;';
                    btn.dataset.tableReload = '1';
                    html += btn.outerHTML;
                }
            }

            if (!html || html === '') {
                return '';
            }

            let btnContent = '';
            if (this.buttonView === 'dropdown') {
                let btn = document.createElement('button');
                btn.innerHTML = `${this.$t('administration', 'Actions')}`;
                let span = document.createElement('span');
                span.className = 'caret';
                btn.appendChild(span);
                btn.className = 'btn btn-outline-dark dropdown-toggle';
                btn.setAttribute('data-bs-toggle', 'dropdown');
                btn.setAttribute('aria-expanded', '1');
                btn.setAttribute('aria-haspopup', '1');

                let dropdown = document.createElement('div');
                dropdown.className = 'dropdown';
                dropdown.style = 'display: inline-block; margin-right: 5px;';
                dropdown.appendChild(btn);

                let ul = document.createElement('ul');
                ul.className = 'dropdown-menu';
                ul.innerHTML = html;

                dropdown.appendChild(ul);
                btnContent = dropdown.outerHTML;
            } else {
                btnContent = html;
            }

            let wrapper = document.createElement('div');
            if (isset(this.buttonWrapperOptions, 'class')) {
                wrapper.className = this.buttonWrapperOptions.class;
            }
            if (isset(this.buttonWrapperOptions, 'style')) {
                wrapper.style = this.buttonWrapperOptions.style;
            }

            wrapper.innerHTML = btnContent;

            return wrapper.outerHTML;
        },
        handleFinishMassAction() {
            this.showMassProgress = false;
            $(this.$refs.table).DataTable().column(0).checkboxes.deselectAll();
            $(this.$refs.table).DataTable().ajax.reload();
        },
    },
    components: {
        'x-datatable-progress': XDatatableProgress,
    },
};
</script>
