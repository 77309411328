<template>
    <x-popover
        :name="name"
        :pointer="pointer"
        :event="event"
        :delay="delay"
        :width="getWidth"
        :not-hide-on-content="isHover"
        @show="value = $event.value"
    >
        <div
            v-if="typeof value === 'string'"
            v-html="value"
        />
        <component
            :is="value.component"
            v-bind="value.componentParams"
        />
    </x-popover>
</template>
<script>

import { directive } from 'vue-js-popover/src/directive';
import Vue from 'vue';
import { XPopover } from '@/components/base/XPopover';
import { isset } from '@/utils/functions';
import { subscription } from 'vue-js-popover/src/subscription';

export default {
    name: 'XTooltip',
    components: { XPopover },
    props: {
        name: {
            type: String,
            default: 'tooltip',
        },
        event: {
            type: String,
            default: 'hover',
        },
        pointer: {
            type: Boolean,
            default: false,
        },
        width: {
            type: Number,
            default: 160,
        },
        component: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            value: '',
            delay: 200,
        };
    },
    computed: {
        getWidth() {
            if (typeof this.value === 'object' && isset(this.value, ['width'])) {
                return this.value.width;
            }
            return this.width;
        },
        hideEventName() {
            return `hide:${this.event}`;
        },
        isHover() {
            return this.event === 'hover';
        },
    },
    mounted() {
        if (this.isHover) {
            $(document).off('mouseleave', 'div.x-tooltip-content', this.onMouseLeave);
            $(document).on('mouseleave', 'div.x-tooltip-content', this.onMouseLeave);
        }
        splynx_event_bus.on('before_switch_page', this.close);
    },
    methods: {
        close() {
            subscription.$emit(`${this.hideEventName}-no-delay`);
        },
        onMouseLeave() {
            subscription.$emit(this.hideEventName);
        },
    },
};

Vue.directive('x-tooltip', directive());
</script>
