<template>
    <div
        class="splynx-footer"
        v-html="footer"
    />
</template>

<script>

export default {
    name: 'XFooter',
    props: {
        footer: {
            type: String,
            default: 'Powered by Splynx',
        },
    },
};
</script>
