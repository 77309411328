import {
    ON_BOARDING_CURRENT_STEP,
    ON_BOARDING_LOAD,
    ON_BOARDING_MAIN_DASHBOARD_LOAD,
    ON_BOARDING_REFS,
    ON_BOARDING_STEPS,
    SET_APP_CHILD_REFERENCES,
    RELOAD_TOUR,
} from '@/apps/portal/store/mutation-types';
import stateToGetters from '@/apps/portal/store/helpers';
import { empty, promisifiedAjax } from '@/utils/functions';

const state = {
    modalShow: false,
    appRefs: {},
    onBoardingStepsToGo: [],
    pageMainDashboardLoad: false,
    // Form steps
    /**
     * steps=[
     *  {
     *   selectedElements : [
     *        {
     *             element: clonedElement,
     *              offset: {
     *                 left: 0,
     *                 right: 0,
     *                 width: 0,
     *                 height: 0
     *              },
     *          }
     *      ],
     *   message:{
     *            title: '',
     *             text: '',
     *             buttonAction: fn() - example for close modal
     *   },
     *  }
     * ]
     *
     */
    currentStep: {},
    currentOnBoarding: '',
    rememberedStep: '',
    stepCount: 3,
    reloadTour: false,
};

const actions = {
    modalShow({ commit }, modalShow) {
        commit(ON_BOARDING_LOAD, modalShow);
    },
    pageMainDashboardLoadChange({ commit }, pageMainDashboardLoad) {
        commit(ON_BOARDING_MAIN_DASHBOARD_LOAD, pageMainDashboardLoad);
    },
    addRefs({ commit, dispatch }, refs) {
        let clonedRefs = { ...refs };

        commit(ON_BOARDING_REFS, clonedRefs);
        commit(SET_APP_CHILD_REFERENCES, clonedRefs, { root: true });
        dispatch('addSteps');
        dispatch('checkWhatOnBoardingToLoad');
    },
    addSteps() {
        if (empty(state.onBoardingStepsToGo)) {
            state.onBoardingStepsToGo = this.getters.onBoardingSteps;
        }
    },
    checkWhatOnBoardingToLoad({ state, dispatch, commit }) {
        let count = 0;
        let timer = setInterval(() => {
            count++;
            if (count === 5) {
                clearInterval(timer);
                return;
            }
            if (state.pageMainDashboardLoad && state.onBoardingStepsToGo.indexOf('customerDashboard') > -1) {
                clearInterval(timer);
                commit('currentOnBoarding', 'customerDashboard');
                dispatch('initOnBoarding');
            }
        }, 500);
    },
    removeRefs({ state }) {
        state.appRefs = {};
    },
    popstateHandle({ dispatch }) {
        window.onpopstate = () => {
            dispatch('closeOnBoarding');
        };
    },
    closeOnBoarding({ dispatch }) {
        dispatch('modalShow', false);
        dispatch('bodyScroll');
        dispatch('removeRefs');
        dispatch('reloadTourChange', false);
    },
    initOnBoarding({ state, dispatch }) {
        if (window.innerWidth < 1024) {
            dispatch('closeOnBoarding');
            return;
        }
        if (state.currentOnBoarding !== '') {
            if (state.rememberedStep !== '') {
                dispatch(state.rememberedStep);
            } else {
                dispatch(state.currentOnBoarding);
            }

            dispatch('bodyScroll');

            window.addEventListener('resize', () => {
                if (state.currentOnBoarding !== '') {
                    if (window.innerWidth < 1024) {
                        dispatch('closeOnBoarding');
                        return;
                    }

                    if (state.rememberedStep !== '') {
                        dispatch(state.rememberedStep);
                    } else {
                        dispatch(state.currentOnBoarding);
                    }
                    dispatch('bodyScroll');
                }
            });

            dispatch('popstateHandle');
        }
    },
    checkIfPayButton({ state }) {
        if (typeof state.appRefs.payButton === 'undefined') {
            state.stepCount = 2;
            return false;
        }
        state.stepCount = 3;
    },
    customerDashboard({ state, dispatch }) {
        let { dashboardReff } = state.appRefs;

        dispatch('checkIfPayButton');
        if (typeof dashboardReff !== 'undefined') {
            dispatch('customerDashboardStepOne');
        }
    },
    customerDashboardStepOne({ state, dispatch, commit }) {
        let { dashboardReff } = state.appRefs;
        let elements = [dashboardReff];
        let message = {
            title: `${window.xApp.$splang.t('common', 'Product tour.')} <span>${window.xApp.$splang.t('common', 'Step')} 1/${state.stepCount}</span>`,
            subtitle: window.xApp.$splang.t('common', 'The dashboard.'),
            // eslint-disable-next-line
            text: window.xApp.$splang.t('common', 'We have summarised the most important information in one place. Account information, your products, financial info & tickets <br />- all on one convenient dashboard.'),
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'customerDashboard');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: window.xApp.$splang.t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('customerDashboardStepTwo');
                        commit('rememberedStep', 'customerDashboardStepTwo');
                    },
                },
            ],
            offset: {
                left: 0,
                top: 225,
                width: 376,
                height: 168,
            },
        };

        let step = {
            elements,
            message,
        };
        dispatch('generateStep', step);
    },
    customerDashboardStepTwo({ state, dispatch, commit }) {
        let { headerNavigationHolder } = state.appRefs;
        window.headerNavigationHolder = headerNavigationHolder;
        let elements = [headerNavigationHolder];
        let left = headerNavigationHolder.getBoundingClientRect().width < 392 ? window.innerWidth - 450 : headerNavigationHolder.getBoundingClientRect().left - 10;
        let buttons;

        if (state.stepCount === 3) {
            buttons = [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & finish the product tour'),
                    buttonClassNames: 'btn btn-cancel',
                    buttonAction() {
                        dispatch('completeTour', 'customerDashboard');
                        commit('rememberedStep', '');
                    },
                },
                {
                    buttonText: window.xApp.$splang.t('common', 'Next'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('customerDashboardStepThree');
                        commit('rememberedStep', 'customerDashboardStepThree');
                    },
                },
            ];
        } else {
            buttons = [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & discover the product'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('completeTour', 'customerDashboard');
                        commit('rememberedStep', '');
                    },
                },
            ];
        }
        let message = {
            title: `${window.xApp.$splang.t('common', 'Product tour.')} <span>${window.xApp.$splang.t('common', 'Step')} 2/${state.stepCount}</span>`,
            subtitle: window.xApp.$splang.t('common', 'Quick access.'),
            text: window.xApp.$splang.t('common', 'Access and update your profile, logout and most importantly, the ability to reach out to our support has been made easily accessible.'),
            buttons,
            offset: {
                left,
                top: 48,
                width: 392,
                height: 183,
            },
        };

        let step = {
            elements,
            message,
        };
        dispatch('generateStep', step);
    },
    customerDashboardStepThree({ state, dispatch, commit }) {
        let elements = [state.appRefs.payButton.$el];
        let top = parseInt(state.appRefs.payButton.$el.getBoundingClientRect().top) + parseInt(state.appRefs.payButton.$el.getBoundingClientRect().height) + 38;
        let left = parseInt(state.appRefs.payButton.$el.getBoundingClientRect().left);
        let message = {
            title: `${window.xApp.$splang.t('common', 'Product tour.')} <span>${window.xApp.$splang.t('common', 'Step')} 3/${state.stepCount}</span>`,
            subtitle: window.xApp.$splang.t('common', 'Payments made easy.'),
            text: 'You can settle your account balance with one convenient payment or choose to pay individual invoices.',
            buttons: [
                {
                    buttonText: window.xApp.$splang.t('common', 'Close & discover the product'),
                    buttonClassNames: 'btn btn-primary',
                    buttonAction() {
                        dispatch('completeTour', 'customerDashboard');
                        commit('rememberedStep', '');
                    },
                },
            ],
            offset: {
                left,
                top,
                width: 358,
                height: 188,
            },
        };

        let config = {
            height: 42,
        };

        let step = {
            elements,
            message,
            config,
        };
        dispatch('generateStep', step);
    },
    generateStep({ commit }, stepInfo) {
        let { elements, message, config } = stepInfo;
        let selectedElements = [];
        let scrollTopAnchor = 0;
        let clonedElement;
        let left;
        let top;
        let width;
        let height;
        let elementOffset;

        if (elements && elements.length > 0) {
            elements.forEach((element) => {
                if (Object.prototype.isPrototypeOf.call(NodeList.prototype, element)) {
                    let divWrap = document.createElement('table');
                    divWrap.classList = 'table table-striped table-without-vertical-margin';
                    element.forEach((item, index) => {
                        if (index === 0) {
                            elementOffset = item.getBoundingClientRect();
                        }
                        let row = document.createElement('tr');
                        row.appendChild(item.cloneNode(true));
                        divWrap.appendChild(row);
                    });
                    clonedElement = divWrap;
                } else {
                    elementOffset = element.getBoundingClientRect();
                    clonedElement = element.cloneNode(true);
                }

                left = parseInt(elementOffset.left) - 10;
                top = parseInt(elementOffset.top) - 8;
                width = parseInt(elementOffset.width) + 20;
                if (config && config.height) {
                    height = config.height;
                }

                if (scrollTopAnchor === 0) {
                    scrollTopAnchor = elementOffset.top;
                } else {
                    scrollTopAnchor = scrollTopAnchor < elementOffset.top ? scrollTopAnchor : elementOffset.top;
                }

                let formedElement = {
                    element: clonedElement,
                    offset: {
                        left, top, width, height,
                    },
                };

                selectedElements.push(formedElement);
            });
        }

        let step = {
            selectedElements,
            message,
        };
        commit(ON_BOARDING_CURRENT_STEP, step);

        commit(ON_BOARDING_LOAD, true);
    },
    enableOnBoarding({ state, commit, dispatch }, step) {
        if (state.onBoardingStepsToGo.indexOf('customerDashboard') === -1) {
            state.onBoardingStepsToGo.push(step);
        }
        commit('currentOnBoarding', 'customerDashboard');

        dispatch('initOnBoarding');
    },
    completeTour({ commit, dispatch }, tourName) {
        promisifiedAjax({
            url: '/portal/profile--make-tour-as-complete',
            data: {
                tour: tourName,
            },
            method: 'POST',
        });
        dispatch('closeOnBoarding');

        let index = state.onBoardingStepsToGo.indexOf(tourName);
        if (index > -1) {
            state.onBoardingStepsToGo.splice(index, 1);
        }
        commit('currentOnBoarding', '');
    },
    bodyScroll({ state }) {
        let body = document.querySelector('body');
        if (state.modalShow) {
            body.style.overflow = 'hidden';
        } else {
            body.removeAttribute('style');
        }
    },
    reloadTourChange({ commit }, value) {
        commit('RELOAD_TOUR', value);
    },
};

const getters = {
    ...stateToGetters(state),
};

const mutations = {
    [ON_BOARDING_LOAD](state, modalShow) {
        state.modalShow = modalShow;
    },
    [ON_BOARDING_MAIN_DASHBOARD_LOAD](state, pageMainDashboardLoad) {
        state.pageMainDashboardLoad = pageMainDashboardLoad;
    },
    [ON_BOARDING_REFS](state, appRefs) {
        if (typeof state.appRefs === 'undefined') {
            state.appRefs = appRefs;
        } else {
            state.appRefs = Object.assign(appRefs, state.appRefs);
        }
    },
    [ON_BOARDING_STEPS](state, steps) {
        state.steps = steps;
    },
    [ON_BOARDING_CURRENT_STEP](state, currentStep) {
        state.currentStep = currentStep;
    },
    [RELOAD_TOUR](state, reloadTour) {
        state.reloadTour = reloadTour;
    },
    rememberedStep(state, rememberedStep) {
        state.rememberedStep = rememberedStep;
    },
    currentOnBoarding(state, currentOnBoarding) {
        state.currentOnBoarding = currentOnBoarding;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
