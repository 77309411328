<template>
    <x-preloader-block :loading="loading">
        <div
            :class="classes"
            :style="style"
            v-html="html"
        />
    </x-preloader-block>
</template>

<script>
import XPreloaderBlock from '@/components/common/XPreloaderBlock/XPreloaderBlock';

export default {
    name: 'XTabAjax',
    components: { XPreloaderBlock },
    props: {
        url: String,
    },
    data() {
        return {
            loading: true,
            html: '',
        };
    },
    mounted() {
        this.loadContent();
    },
    computed: {
        classes() {
            const list = ['d-flex', 'flex-column'];
            if (this.$parent.cacheTab) {
                list.push(this.$parent.active ? 'x-tab-cache-visible' : 'x-tab-cache-hidden');
            }
            return list;
        },
        style() {
            return { flex: 1 };
        },
    },
    methods: {
        loadContent() {
            $.ajax({
                url: this.url,
                method: 'GET',
                success: (rawHtml) => {
                    this.parseHtml(rawHtml);
                },
                error: () => {
                    this.html = `<div class="main-tab-holder ps-20 pe-20">${this.$t('common', 'Couldn\'t load!')}</div>`;
                    this.loading = false;
                },
            });
        },
        parseHtml(html) {
            let root = $('<div>');
            root.append(html);
            let title = $(root).find('title');
            if (title.length > 0) {
                title.remove();
            }

            let scripts = root.find('script');
            root.find('script').remove();

            this.html = root.html();

            this.loading = false;

            this.$nextTick(() => {
                scripts.each((index, script) => {
                    $('body').append(script);
                });

                splynx_event_bus.emit('x-tab-ajax-loaded');
                this.initWidgets();
            });
        },
        initWidgets() {
            makeSelect2();
            makeMultileSelect();
            makeDatepicker();
            makeDecimalInput();
            processPasswords();
            processDT();
            processCollapsedPanels();
            processValidationgInputs();
            initReportValidityBeforeSubmitForm();
            addBeforeSubmitValidation();
            makePopover();
        },
    },
};
</script>
