<template>
    <div class="mass-action-progress-wrapper">
        <div
            class="alert alert-warning"
            style="text-align: center;"
        >
            <span
                class="mass-action-message"
                v-html="message"
            />
            <div
                class="progress mb-0"
            >
                <div
                    class="progress-bar progress-bar-striped progress-bar-success active"
                    role="progressbar"
                    :style="{width: progress + '%'}"
                >
                    {{ progress + '%' }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'XDatatableProgressOptimized',
    props: {
        initialMessage: {
            type: [String, Number],
            default: '',
        },
        initialProgress: {
            type: Number,
            default: 0,
        },
        typeMassActionEvent: {
            type: String,
            default: 'leads',
        },
    },
    data() {
        return {
            message: '',
            progress: 0,
        };
    },
    created() {
        remove_socket_listener(`${this.typeMassActionEvent}_mass_action_event`, this.progressHandler);
        add_socket_listener(`${this.typeMassActionEvent}_mass_action_event`, this.progressHandler);
    },
    destroyed() {
        remove_socket_listener(`${this.typeMassActionEvent}_mass_action_event`, this.progressHandler);
    },
    mounted() {
        this.progress = this.initialProgress;
        this.message = this.initialMessage;
    },
    methods: {
        progressHandler(data) {
            if (data.type === 'finish') {
                this.$emit('finish', data);
                if (data.result.warning.length > 0) {
                    show_warning(data.result.warning, 5);
                }
                if (data.result.errors.length > 0) {
                    show_error(data.result.errors, 5);
                }
            } else if (data.type === 'progress') {
                this.$emit('progress', data);
                this.progress = parseInt(data.progress);
                this.message = data.message;
            }
        },
    },
};
</script>
