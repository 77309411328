import Vue from 'vue';
import Splang from './splang';

Vue.use(Splang);

class Locale {
    constructor(lang, listTranslateCategories = []) {
        this.language = lang;
        this.splang = new Splang({
            locale: this.language || 'en',
            loadMessages: Locale.loadMessages,
            listTranslateCategories,
        });
    }

    static loadMessages(lang) {
        if (typeof window.spl_localization_messages === 'undefined') {
            console.error(`Failed loading localization messages, language: ${lang}`);
            return null;
        }

        return window.spl_localization_messages;
    }
}

export default Locale;
