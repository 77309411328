export default class ThrottleHandler {
    constructor() {
        this.resolveData = [];
        this.handlersList = [];
        this.currentPromise = null;
    }

    addToHandlers(callback) {
        return new Promise((resolve) => {
            this.handlersList.push({ callback, resolve });
            this.processHandlersList();
        });
    }

    processHandlersList() {
        if (this.currentPromise || this.handlersList.length === 0) {
            return;
        }

        const { callback, resolve } = this.handlersList.shift();
        this.currentPromise = Promise.resolve(callback()).then((data) => {
            if (data) {
                this.resolveData.push(data);
            }
            this.currentPromise = null;
            resolve();
            this.processHandlersList();
        });
    }
}
